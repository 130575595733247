import { useEffect, useState } from 'react';

import { Box, FormHelperText, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';

import { Information } from '../..';
import { HeartlandErrorText } from '../../../../../themes/overrides';
import { FormFieldTypes } from '../../../../../types/enums/FormFieldTypes';
import { QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import './index.css';
import { formHelperService } from '../../../services';

type RangeState = {
  range1: string;
  range2: string;
};

const Range: React.FC<QuestionProps> = ({
  fieldDefinitionJson,
  label,
  onChange,
  value,
  helperText,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const [range, setRange] = useState<RangeState>({
    range1: value.value.range1 ?? '',
    range2: value.value.range2 ?? '',
  });
  const [focused, setFocused] = useState(false);
  const [timer, setTimer] = useState<boolean>(false);

  useEffect(() => {
    if (!focused && timer) {
      onChange(value.questionId, { range1: range.range1, range2: range.range2 }, true);
      setTimer(false);
    }
  }, [focused, onChange, range.range1, range.range2, timer, value.questionId]);

  return (
    <Box className="range" id={label} onBlur={() => {}}>
      {!hideLabel && (
        <InputLabel
          shrink
          htmlFor={fieldDefinitionJson?.Properties[0].PropertyKey}
          required={value.isRequired}
          sx={{ marginLeft: '.2rem' }}>
          {label}
          {infoHtml && <Information infoHtml={infoHtml} />}
        </InputLabel>
      )}
      <Box className="range-options">
        <TextField
          variant="filled"
          id={fieldDefinitionJson?.Properties[0].PropertyKey}
          style={{ width: '50%', marginRight: '.3rem' }}
          error={value.validationError}
          inputProps={{
            style: { textIndent: '4rem' },
            'data-testid': fieldDefinitionJson?.Properties[0].PropertyKey,
            ...formHelperService.getCustomInputProps(fieldType, fieldDefinitionJson.Properties[0].PropertyKey, label),
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {!range.range1 && <Typography className="range-text-mobile">Between</Typography>}
                <Typography className="range-text">Between</Typography>
              </InputAdornment>
            ),
          }}
          type={value.fieldType === FormFieldTypes.DATE_RANGE ? 'date' : 'time'}
          onFocus={() => setFocused(true)}
          onChange={(e) => {
            onChange(value.questionId, { range1: e.target.value, range2: range.range2 }, false);
            setRange((prevState) => {
              return { ...prevState, range1: e.target.value };
            });
          }}
          onBlur={() => {
            setTimeout(() => setTimer(true), 50);
            setFocused(false);
          }}
          value={range.range1}
          autoComplete={value.autoComplete}
        />
        <TextField
          id={fieldDefinitionJson?.Properties[1].PropertyKey}
          style={{ width: '50%' }}
          variant="filled"
          inputProps={{
            style: { textIndent: '4rem' },
            'data-testid': fieldDefinitionJson?.Properties[1].PropertyKey,
            ...formHelperService.getCustomInputProps(fieldType, fieldDefinitionJson.Properties[1].PropertyKey, label),
          }}
          error={value.validationError}
          type={value.fieldType === FormFieldTypes.DATE_RANGE ? 'date' : 'time'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {!range.range2 && <Typography className="range-text-mobile">and</Typography>}
                <Typography className="range-text">and</Typography>
              </InputAdornment>
            ),
          }}
          onFocus={() => setFocused(true)}
          onChange={(e) => {
            onChange(value.questionId, { range2: e.target.value, range1: range.range1 }, false);
            setRange((prevState) => {
              return { ...prevState, range2: e.target.value };
            });
          }}
          onBlur={() => {
            setTimeout(() => setTimer(true), 50);
            setFocused(false);
          }}
          value={range.range2}
          autoComplete={value.autoComplete}
        />
      </Box>
      {value.validationErrorText && <HeartlandErrorText>{value.validationErrorText}</HeartlandErrorText>}
      {!isEmpty(helperText) && (
        <FormHelperText
          style={{ marginLeft: '12px' }}
          dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>
      )}
    </Box>
  );
};

export { Range };
