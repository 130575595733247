
import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';

import Modal from '../../utility/modal';

interface Props {
  htmlOverlayMessage: string;
}

const NotificationOverlay: React.FC<Props> = ({ htmlOverlayMessage }) => {
  const [open, setOpen] = useState<boolean>(htmlOverlayMessage !== '');

  return (
    <>
      <Modal
        closeModal={(e, reason) => {
          // eslint-disable-next-line eqeqeq
          if (reason && reason == 'backdropClick') return;
          setOpen(false);
        }}
        show={open}
        size="md"
        onlyCloseButton={false}
        hideTitle={true}
        title={''}>
        <Box data-testid="notification-modal">
          <Typography
            color="text.primary"
            className="fade-in"
            dangerouslySetInnerHTML={{ __html: htmlOverlayMessage }}
          />
          <Button
            sx={{ marginTop: '50px' }}
            variant="contained"
            color="primary"
            data-testid="overlayBtnContinue"
            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}
            onClick={() => {
              setOpen(false);
            }}>
            {'Continue'}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationOverlay;
