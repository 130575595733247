import { useEffect, useState } from 'react';

import { FormControl, FormHelperText, TextField } from '@mui/material';

interface Props {
  updateAnswer: (text: string, shouldValidate: boolean) => void;
  validationError: boolean;
  autoComplete: string;
  errorText?: string;
  value: string;
}

const Other: React.FC<Props> = ({ autoComplete, updateAnswer, validationError, errorText, value }) => {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    let subscribed = true;
    if (subscribed) {
      setText(value);
    }
    return () => {
      subscribed = false;
    };
  }, [value]);

  return (
    <FormControl fullWidth>
      <FormHelperText />
      <TextField
        variant="filled"
        label="Other Business Type"
        InputLabelProps={{ shrink: true }}
        fullWidth
        id="OtherBusinessType"
        error={validationError}
        helperText={validationError ? errorText : ''}
        autoComplete={autoComplete}
        value={text ? text : ''}
        onChange={(e) => {
          setText(e.target.value);
          updateAnswer(e.target.value.trim(), false);
        }}
        onBlur={() => {
          updateAnswer(text ? text.trim() : '', true);
          setText(text ? text.trim() : '');
        }}
      />
    </FormControl>
  );
};

export { Other };
