export enum FormFieldTypes {
  DATE = 'Date',
  KVP = 'KVP',
  MCC = 'MCC',
  NUMBER = 'Number',
  PROPORTION = 'Proportion',
  TEXT = 'Text',
  DATE_RANGE = 'DateRange',
  TIME_RANGE = 'TimeRange',
  SMART_LOCATION = 'SmartLocation',
  NAME = 'Name',
  URL = 'Url',
  ADDRESS = 'Address',
  AUTO_CLOSE = 'AutoClose',
  STATE = 'State',
  EQUIPMENT = 'Equipment',
  MATRIX = 'Matrix',
  SLIDER = 'Slider',
  EMAIL_VALIDATOR = 'EmailValidator',
  BANK_ROUTING_VALIDATOR = 'BankRoutingValidator',
  HIDDEN = 'Hidden',
  PARTNER_ECOMM_SOFTWARE = 'PartnerEcommSoftware',
  PARTNER_GPI_SOFTWARE = 'PartnerGpiSoftware',
  ALPHA_NUMERIC = 'AlphaNumeric',
}

export enum KVPTypes {
  DROPDOWN = 'Dropdown',
  RADIO = 'Radio',
  BUTTON = 'Button',
  CHECKBOX = 'Checkbox',
  CHECKBOXTOGGLE = 'CheckboxToggle',
}

export type FormFieldSubTypes = KVPTypes;

export enum TextMaskTypes {
  EMAIL = 'Email',
  PHONE = 'Phone',
  ZIPCODE = 'ZipCode',
  PERCENT = 'Percent',
  TAX_ID = 'TaxId',
  EMAIL_CODE = 'EmailCode',
  SSN = 'Ssn',
}

export enum ContactFormFieldTypes {
  FIRST_NAME = 'FirstName',
  LAST_NAME = 'LastName',
  EMAIL_ADDRESS = 'EmailAddress',
  PHONE_NUMBER = 'PhoneNumber',
  COMMENT = 'Comment',
}

export const CHARACTER_LIMIT: number = 500;

export const OTHER_MCC: string = 'Other/Not in List';
