import { baseUrl } from '../../../services/ApiService';
import { GLOBAL_ERROR_MESSAGE } from '../../../types/enums';
import { userClientContextService } from '../../../utility/userClientContext';
import { RefreshClientTokenError, UserClient } from '../types';

interface IAuthorizationService {
  refreshToken(): Promise<UserClient | undefined>;
  handleAuthorizationIntercept(response: Response, originalRequestConfig: RequestInit): Promise<Response>;
  get(url: string, headers?: Record<string, string>): Promise<Response>;
  post<TRequest>(url: string, data: TRequest, headers: Record<string, string>): Promise<Response>;
}

class AuthorizationService implements IAuthorizationService {
  private pathLoadUserClient = `UserClient/LoadUserClient`;
  private failedToFetchErrorText = 'Failed to fetch';

  async refreshToken(): Promise<UserClient | undefined> {
    let userClient = userClientContextService.currentUserClient;

    try {
      const body = JSON.stringify(userClient);
      const newClientTokenResponse = await fetch(`${baseUrl}/${this.pathLoadUserClient}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });

      if (newClientTokenResponse.ok) {
        const data = await newClientTokenResponse.json().catch(() => userClient ?? {});

        userClientContextService.pushUserAttributions(data.attributions);

        userClientContextService.currentUserClient = data;

        userClient = userClientContextService.currentUserClient;
      } else {
        const nonOkText = await newClientTokenResponse.text();
        const statusCode = newClientTokenResponse.status;

        throw new RefreshClientTokenError(GLOBAL_ERROR_MESSAGE.UNAUTHORIZED, nonOkText, statusCode);
      }
    } catch (error) {
      if (error instanceof RefreshClientTokenError) {
        throw error;
      } else {
        console.error(error);
        let err = error as Error;

        if (err.message == this.failedToFetchErrorText) {
          throw new RefreshClientTokenError(GLOBAL_ERROR_MESSAGE.CLIENT_API_CONNECTION_FAILURE);
        } else {
          throw new RefreshClientTokenError(GLOBAL_ERROR_MESSAGE.ERROR);
        }
      }
    }

    return userClient;
  }

  async handleAuthorizationIntercept(response: Response, originalRequestConfig: RequestInit): Promise<Response> {
    if (response.status === 401) {
      await this.refreshToken();

      const retryResponse = await fetch(response.url, {
        ...originalRequestConfig,
        headers: { ...originalRequestConfig.headers, ...this.getDefaultHeaders() },
      });

      return retryResponse;
    }

    return response;
  }

  async get(url: string, headers?: Record<string, string>): Promise<Response> {
    const config = {
      method: 'GET',
      headers: this.getDefaultHeaders(headers),
    };
    const response = await fetch(url, config);

    return this.handleAuthorizationIntercept(response, config);
  }

  async post<T>(url: string, data: T, headers: Record<string, string> = {}): Promise<Response> {
    const config = {
      method: 'POST',
      headers: this.getDefaultHeaders(headers),
      body: JSON.stringify(data),
    } as RequestInit;

    const response = await fetch(url, config);

    return this.handleAuthorizationIntercept(response, config);
  }

  private getDefaultHeaders(headers: Record<string, string> = {}): Record<string, string> {
    if (Object.keys(headers).length === 0) {
      headers['Content-Type'] = 'application/json';
    }

    const userClientStore = userClientContextService.currentUserClient;

    if (userClientStore?.clientToken) {
      headers['X-Client-Token'] = userClientStore?.clientToken;
    }

    return headers;
  }
}

const authorizationService = new AuthorizationService();

export type { IAuthorizationService };
export { AuthorizationService, authorizationService };
