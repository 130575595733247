import { Box, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';

import CustomDivider from '../../../../themes/customDivider';
import { formHelperService } from '../../services';
import { FormFieldTypes, KVPTypes } from '../../../../types/enums/FormFieldTypes';

interface Props {
  customText: string;
  checked: boolean;
  onClick: (checkedState: any) => void;
}

const PrivacyPolicy = ({ onClick, customText, checked }: Props) => {
  return (
    <>
      <CustomDivider sx={{ marginTop: '2.5rem' }} />

      <Box className="privacy-policy">
        <Typography color="textPrimary" className="inline-text" dangerouslySetInnerHTML={{ __html: customText }} />

        <Box />

        <FormControlLabel
          label="Yes"
          id="chkAgreement"
          labelPlacement="end"
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={(e: any) => {
                onClick(e.target.checked);
              }}
              inputProps={{
                ...formHelperService.getCustomInputProps(
                  FormFieldTypes.TEXT,
                  'chkAgreement',
                  'Agreement',
                  KVPTypes.CHECKBOX,
                ),
              }}
            />
          }
        />
      </Box>
    </>
  );
};

export { PrivacyPolicy };
