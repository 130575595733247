import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';

import Modal from '../../utility/modal';
import { screenUtils } from '../../utility/screenUtils';

import styles from './index.module.css';

type Props = {
  message?: string;
  isOpen: boolean;
  onRefresh: () => void;
  onClose: () => void;
};

const RefreshModal = ({ message, isOpen, onRefresh, onClose }: Props) => {
  const isMobile = screenUtils.DeviceIsMobile();
  const defaultMessage =
    'We encountered an issue processing your request and need to reload the current page of your application. Please click Continue and try again.';

  return (
    <Modal show={isOpen} fullScreen={isMobile} size="md" closeModal={onClose} hideTitle onlyCloseButton>
      <Box className={styles.container} data-testid="refresh-modal">
        <h1>{message ?? defaultMessage}</h1>
        <Box className={styles.buttonContainer}>
          <LoadingButton variant="contained" color="primary" data-testid="btn-refresh-page" onClick={onRefresh}>
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export { RefreshModal };
