import { useState } from 'react';

import { faCircleXmark, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ClickAwayListener, useTheme } from '@mui/material';

import { StyledPopper } from '../../../../themes/overrides';
import { OneXThemePalette } from '../../../../types/enums/theme';

import './index.css';

interface Props {
  infoHtml: string;
}
const Information: React.FC<Props> = ({ infoHtml }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [arrowRef, setArrowRef] = useState(null);

  const handleClick = (event: any) => {
    if (anchorEl) handleClose();
    else setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowRef(null);
  };

  const open = Boolean(anchorEl);

  const theme = useTheme<OneXThemePalette>();

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle as any}
        size="lg"
        data-testid="info-icon"
        className="information-popper-icon"
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
      />
      <StyledPopper
        id={open ? 'info-popper' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box className="information-pane fade-in">
            <Box
              component="span"
              ref={setArrowRef}
              className="arrow"
              sx={{
                position: 'absolute',
                fontSize: 7,
                width: '1em',
                height: '1em',
                '&::before': {
                  content: '""',
                  margin: 'auto',
                  display: 'block',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                },
              }}
            />
            <Box
              className="information-pane"
              sx={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginTop: 0,
                border: `1px solid ${theme.palette.primary.paperHover}`,
                backgroundColor: theme.palette.primary.bgPrimary,
                color: theme.palette.text.primary,
                maxHeight: '25rem',
                overflowY: 'auto',
              }}>
              <Box sx={{ marginRight: '1rem' }} dangerouslySetInnerHTML={{ __html: infoHtml }} />
              <FontAwesomeIcon
                size="lg"
                icon={faCircleXmark as any}
                className="information-close-icon"
                onClick={handleClose}
              />
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export { Information };
