import React from 'react';

import { ContentCopy as CopyIcon, Error as ErrorIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Link, OutlinedInput, Tooltip, Typography } from '@mui/material';

import LayoutCard from './layout/card';
import { GLOBAL_ERROR_MESSAGE, SESSION_ERROR_MESSAGE } from './types/enums';
import { APP_STATE } from './types/enums/appState';
import { PhaseEvent } from './types/phase';
import { trackEvent } from './utility/eventTracking';
import ScrollToTop from './utility/scrollToTop';
import './App.css';
import { currentSessionContextService } from './utility/sessionContext';
import { userClientContextService } from './utility/userClientContext';

const GLOBAL_ERROR_MESSAGES = {
  [GLOBAL_ERROR_MESSAGE.CHANNEL]: <Typography color="textPrimary">Channel not found!</Typography>,
  [GLOBAL_ERROR_MESSAGE.LEAD]: (
    <Typography color="textPrimary">
      We're sorry for the interruption. <br />
      We&apos;ve logged this error, someone will be in touch shortly to help you complete your order.
    </Typography>
  ),
  [GLOBAL_ERROR_MESSAGE.ERROR]: (
    <Typography color="textPrimary">
      We're sorry for the interruption. <br />
      We&apos;ve logged this error, please try again later.
    </Typography>
  ),
  [GLOBAL_ERROR_MESSAGE.UNAUTHORIZED]: (
    <Typography color="textPrimary">
      An error occurred when trying to authenticate your current session with the server.
    </Typography>
  ),
  [SESSION_ERROR_MESSAGE.CAPTCHA_FAILED]: (
    <Typography color="textPrimary">
      Captcha has failed for this request. <br />
      We&apos;ve logged this error, please try again later.
    </Typography>
  ),
  [GLOBAL_ERROR_MESSAGE.START_SESSION_FAILURE]: (
    <Box>
      <Typography color="textPrimary">Start Session Failure</Typography>
    </Box>
  ),
  [GLOBAL_ERROR_MESSAGE.CONTINUE_SESSION_FAILURE]: (
    <Typography color="textPrimary">Continue Session Failure</Typography>
  ),
  [GLOBAL_ERROR_MESSAGE.RESUME_CLIENT_SESSION_FAILURE]: (
    <Typography color="textPrimary">Resume Client Session Failure</Typography>
  ),
  [GLOBAL_ERROR_MESSAGE.CLIENT_API_CONNECTION_FAILURE]: (
    <Typography color="textPrimary">
      Sorry, we are unable to establish a connection to our servers at this time.
      <br />
      <br />
      If you believe there may be content filtering or other security software blocking this connection, try accessing
      this page from another network, browser, or device.
      <br />
      <br />
      If you continue to experience this problem, please contact a support representative.
      <br />
    </Typography>
  ),
};

const COPY_CONTENT_TEXT = 'Copy content';
const CONTENT_COPIED_TEXT = 'Content copied';

type Props = {
  isError?: any;
  isIFrame?: boolean;
  message?: GLOBAL_ERROR_MESSAGE;
  children: any;
  onRetry?: () => void;
};

type State = {
  hasError: boolean;
  copyContentTooltipText: string;
};

//ErrorBoundary will not show if development version of react and is a runtime error
//ErrorBoundary doesn't catch errors that are in handlers.
class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    copyContentTooltipText: COPY_CONTENT_TEXT,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    ScrollToTop();
  }

  componentDidCatch(error: any, info: any) {
    trackEvent(this.props.message, 'ErrorBoundary', PhaseEvent.ERROR, { errorInfo: info, error: error });
    return this.setState({ hasError: true });
  }
  handleErrorMessage = () => {
    let error: GLOBAL_ERROR_MESSAGE = this.props.message ?? GLOBAL_ERROR_MESSAGE.ERROR;
    return GLOBAL_ERROR_MESSAGES[error];
  };

  copyToClipboard = (content: string) => {
    navigator?.clipboard
      ?.writeText(content)
      .then(() => this.setState((prev) => ({ ...prev, copyContentTooltipText: CONTENT_COPIED_TEXT })));
  };

  render() {
    if (this.state.hasError || this.props.isError === APP_STATE.ERROR) {
      const troubleshootingDetails = currentSessionContextService.getCurrentTroubleshootingInfo();
      const hasAuthenticateUserClient = Boolean(userClientContextService.currentUserClient?.clientToken);
      const hasSessionCreation = Boolean(userClientContextService.getLastSessionCreationContext());

      return (
        <LayoutCard style={{ justifyContent: 'center' }}>
          <Box className="error-card">
            <ErrorIcon color="primary" fontSize="large" />
            <Box className="error-message-wrap">{this.handleErrorMessage()}</Box>
            <Box className="error-message-wrap">
              <Typography color="textSecondary" fontSize={12}>
                {window.location.toString()}
              </Typography>
            </Box>
            {!this.props.isIFrame && hasAuthenticateUserClient && hasSessionCreation && (
              <Box
                className="retryButton"
                onClick={() => {
                  this.setState({ hasError: false });

                  if (this.props.onRetry) {
                    this.props.onRetry();
                  } else {
                    window.location.reload();
                  }

                  trackEvent(this.props.message, 'ErrorBoundary', PhaseEvent.RETRY, {});
                }}>
                <Link style={{ cursor: 'pointer', fontSize: '1rem' }}>RETRY</Link>
              </Box>
            )}
            <Box className="error-info">
              <OutlinedInput
                fullWidth
                disabled
                multiline
                rows={4}
                sx={{ height: 'unset' }}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      arrow
                      placement="top"
                      leaveDelay={300}
                      title={this.state.copyContentTooltipText}
                      onClose={() =>
                        setTimeout(
                          () => this.setState((prev) => ({ ...prev, copyContentTooltipText: COPY_CONTENT_TEXT })),
                          300,
                        )
                      }>
                      <IconButton
                        aria-label="Copy troubleshooting information"
                        onClick={() => this.copyToClipboard(troubleshootingDetails)}
                        edge="end">
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
                value={troubleshootingDetails}
              />
            </Box>
          </Box>
        </LayoutCard>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
