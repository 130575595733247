import { useState } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { InputLabel, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Autocomplete } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { Information } from '../..';
import { FormFieldSubTypes, FormFieldTypes } from '../../../../../types/enums/FormFieldTypes';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { MCC } from '../../../../../types/form';
import NativeDropDown from '../../../../../utility/nativeSelect';
import { GetMobileInputLabelProps } from '../../../../../utility/showOnDevice';
import { formHelperService } from '../../../services';

interface Props {
  helperText: string;
  icon: string;
  id: string;
  label: string;
  list: Array<MCC>;
  setSelection: (value: any) => void;
  value: MCC;
  error: boolean;
  infoHtml: string | null;
  fieldType: FormFieldTypes;
  fieldSubType?: FormFieldSubTypes;
  propertyKey: string;
  hideLabel?: boolean;
}

const MCCDropdown = ({
  id,
  list,
  label,
  setSelection,
  error,
  value,
  icon,
  infoHtml,
  fieldType,
  propertyKey,
  fieldSubType,
  hideLabel = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme<OneXThemePalette>();

  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return isMobile ? (
    <div style={{ flexDirection: 'column', width: '100%' }}>
      {!hideLabel && (
        <InputLabel
          {...GetMobileInputLabelProps(label ?? '')}
          htmlFor={id}
          required={true}
          sx={{ marginLeft: '.2rem' }}>
          {label}
          {infoHtml && <Information infoHtml={infoHtml} />}
        </InputLabel>
      )}
      <NativeDropDown
        id={id}
        overrideClass={isMobile ? 'mobile-dropdown' : ''}
        value={value.Code}
        label={label}
        showDefaultPlaceholder
        options={
          list?.map((item) => {
            return { label: item.Name, value: item.Code };
          }) ?? []
        }
        required={true}
        showError={error}
        helperText={''}
        onChange={(value: string) => {
          if (value != null) {
            let target = list?.find((item) => item.Code === value);
            setIsOpen(false);
            setSelection(target);
          }
        }}
        fullWidth
        propertyKey={propertyKey}
        fieldType={fieldType}
      />
    </div>
  ) : (
    <div>
      <InputLabel htmlFor={id} required={true} sx={{ marginLeft: '.2rem', marginBottom: '5px' }}>
        {label}
        {infoHtml && <Information infoHtml={infoHtml} />}
      </InputLabel>
      <Autocomplete
        options={list ?? []}
        id={id}
        fullWidth
        autoSelect
        autoHighlight
        blurOnSelect
        handleHomeEndKeys
        value={value?.Name ? value : null}
        open={isOpen}
        popupIcon={<ExpandMore />}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            variant="filled"
            InputLabelProps={{ shrink: true }}
            autoComplete={'new-password'}
            inputProps={{
              ...params.inputProps,
              ...formHelperService.getCustomInputProps(fieldType, propertyKey, label, fieldSubType),
            }}
            onClick={() => setIsOpen(!isOpen)}
            onBlur={() => {
              setIsOpen(false);
            }}
            onKeyDown={(e: any) => {
              //Backspace doesn't save the value
              if (e.keyCode === 8 && (e.target.value === '' || e.target.value.length === 1)) {
                setIsOpen(false);
              }
              //Enter and Escape close the menu
              else if (e.keyCode === 27 || e.keyCode === 13) {
                setIsOpen(false);
              }
              //Shift and Tab do not open the menu
              else if (e.keyCode !== 9 && e.keyCode !== 16) {
                setIsOpen(true);
              }
            }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: params.InputProps.endAdornment,
            }}
            error={error}
            helperText={error ? label + ' is required.' : ''}
            placeholder={label}
          />
        )}
        // getOptionSelected={(option, value) => {
        //   return option.Code === value.Code;
        // }}
        getOptionLabel={(option) => option.Name}
        onChange={(event, newValue) => {
          if (newValue != null) {
            setIsOpen(false);
            setSelection(newValue);
          }
        }}
        renderOption={(props, option, { inputValue }) => {
          const newProps = {
            ...props,
            ...formHelperService.getCustomInputProps(fieldType, propertyKey, option?.Name, fieldSubType),
          } as any;
          const matches = match(option?.Name ?? '', inputValue);
          const parts = parse(option?.Name ?? '', matches);

          return (
            <li {...newProps}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </div>
  );
};

export { MCCDropdown };
