import { useEffect, useMemo } from 'react';

import { BroadcastChannel } from 'broadcast-channel';

const broadcastSenderIdKey = 'senderId';
const broadcastSenderIdActiveKey = 'senderIdActive';

const getUniqueId = () => {
  let shouldUseCurrentId = false;
  const navigation = window.performance.getEntriesByType('navigation');

  if (navigation?.length > 0) {
    shouldUseCurrentId = (navigation[0] as any)?.type !== 'back_forward';
  }

  const currentId = sessionStorage.getItem(broadcastSenderIdKey);
  const broadcastSenderId = currentId && shouldUseCurrentId ? currentId : new Date().toISOString();

  sessionStorage.setItem(broadcastSenderIdKey, broadcastSenderId);

  localStorage.setItem(broadcastSenderIdActiveKey, broadcastSenderId);

  return broadcastSenderId;
};

const useChannel = <TInput>({
  channelName,
  messageHandler,
}: {
  channelName: string;
  messageHandler: (message: TInput) => void;
}) => {
  const broadcastSenderId = getUniqueId();
  const channel = useMemo(() => new BroadcastChannel(channelName), [channelName]);
  const receiveChannel = useMemo(() => new BroadcastChannel(channelName), [channelName]);

  const broadcast = (message?: TInput) => {
    channel.postMessage({ ...message, broadcastSenderId });
  };

  useEffect(() => {
    channel.addEventListener('message', messageHandler);
    receiveChannel.addEventListener('message', messageHandler);

    return () => {
      channel.removeEventListener('message', messageHandler);
      receiveChannel.removeEventListener('message', messageHandler);
    };
  }, [channel, messageHandler, receiveChannel]);

  return {
    broadcastSenderId,
    broadcast,
  };
};

export default useChannel;
