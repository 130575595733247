import CloseIcon from '@mui/icons-material/Close';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Information } from '../..';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { FieldValue, FormFieldOption, QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import { GetMobileInputLabelProps } from '../../../../../utility/showOnDevice';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  label?: string;
  placeholder: string | null;
  options: Array<FormFieldOption>;
  value: FieldValue;
  renderAsColumn?: boolean;
}

const KVPDropdown: React.FC<Props> = ({
  label,
  placeholder,
  onChange,
  options,
  value,
  fieldDefinitionJson,
  infoHtml,
  fieldType,
  fieldSubType,
  hideLabel = false,
  renderAsColumn = false,
}) => {
  const theme = useTheme<OneXThemePalette>();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const multiSelect = fieldDefinitionJson?.MultiSelect;
  let defaultValue = value.value.value ?? value?.value ?? '';

  const getOptions = () => {
    if (multiSelect) {
      let baseOptions = options
        .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
        .map((x, i) => {
          return (
            <MenuItem key={x.value + i} value={x.value}>
              <Checkbox
                checked={value.value.includes(x.value)}
                inputProps={{
                  ...formHelperService.getCustomInputProps(
                    fieldType,
                    fieldDefinitionJson?.Properties[0].PropertyKey,
                    x.label,
                    fieldSubType,
                  ),
                }}
              />
              <ListItemText primary={x.label} />
            </MenuItem>
          );
        });

      return [...baseOptions];
    } else if (!isMobile) {
      let baseOption = (
        <MenuItem disabled value="" key={'defaultOption'}>
          {renderAsColumn ? '' : `Select ${label}`}
        </MenuItem>
      );

      let baseOptions = options
        .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
        .map((x, i) => {
          return (
            <MenuItem key={x.value + i} value={x.value}>
              {x.label}
            </MenuItem>
          );
        });

      return [baseOption, ...baseOptions];
    } else {
      let defaultOption = (
        <option value="" disabled hidden key="defaultOption">
          {label}
        </option>
      );

      let baseOptions = options
        .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
        .map((option, i) => (
          <option key={option.value + i} value={option.value}>
            {option.label}
          </option>
        ));

      return [defaultOption, ...baseOptions];
    }
  };

  return (
    <>
      {multiSelect && (
        <FormControl fullWidth>
          <TextField
            select
            label={
              hideLabel ? null : (
                <>
                  {label}
                  {infoHtml && <Information infoHtml={infoHtml} />}
                </>
              )
            }
            required={value.isRequired}
            SelectProps={{
              fullWidth: true,
              native: false,
              IconComponent: ExpandMore,
              multiple: multiSelect,
              displayEmpty: true,
              renderValue: (selected: any) => {
                if (options.filter((s) => selected.includes(s.value)).length === 0) {
                  return <Box sx={{ color: theme.palette.text.placeholder }}>{placeholder}</Box>;
                }
                let optionsForDisplay = options
                  .filter((s) => selected.includes(s.value))
                  .map((s) => s.label)
                  .join(', ');
                return (
                  <Box sx={{ fontWeight: 400 }}>
                    {!renderAsColumn
                      ? optionsForDisplay
                      : optionsForDisplay.substring(0, 25) + (optionsForDisplay.length > 25 ? '...' : '')}
                  </Box>
                );
              },
            }}
            value={value.value ?? ''}
            id={fieldDefinitionJson?.Properties[0].PropertyKey}
            type="uncontrolled"
            error={value.validationError}
            variant="filled"
            onChange={(e: any) => {
              let val: any = e.target.value;

              for (let i = 0; i < val.length; i++) {
                if (val[i] === '') val.splice(i, 1);
              }
              if (val.length === 0) val = [''];

              onChange(value.questionId, val, true, '');
            }}
            helperText={value.validationError ? value.validationErrorText : ''}>
            {getOptions()}
          </TextField>
        </FormControl>
      )}
      {!multiSelect && (
        <FormControl fullWidth>
          <TextField
            select
            label={
              hideLabel ? null : (
                <>
                  {label}
                  {infoHtml && <Information infoHtml={infoHtml} />}
                </>
              )
            }
            required={value.isRequired}
            InputLabelProps={isMobile ? { ...GetMobileInputLabelProps(label ?? '') } : { shrink: true }}
            InputProps={{
              endAdornment: !value?.value?.value ? null : (
                <IconButton
                  onClick={() => {
                    onChange(value.questionId, '', true);
                  }}
                  size="small"
                  data-testid="kvp-clear-button"
                  sx={{ marginRight: 2 }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            }}
            SelectProps={{
              fullWidth: true,
              native: isMobile,
              IconComponent: ExpandMore,
              multiple: false,
              displayEmpty: true,
              renderValue: isMobile
                ? undefined
                : (selected: any) => {
                    if (isEmpty(selected))
                      return <Box sx={{ color: theme.palette.text.placeholder }}>{placeholder ?? label}</Box>;
                    return (
                      <Box sx={{ fontWeight: 400 }}>
                        {options.find((s) => s.value === selected)?.label ??
                          options.find((s) => s.value === selected.value)?.label ??
                          'not found'}
                      </Box>
                    );
                  },
              autoComplete: value.autoComplete,
            }}
            value={defaultValue}
            id={fieldDefinitionJson?.Properties[0].PropertyKey}
            type="uncontrolled"
            error={value.validationError}
            variant="filled"
            onChange={(e: any) => {
              let val: any = e.target.value;

              onChange(
                value.questionId,
                options.find((x) => x.value === val),
                true,
                '',
              );
            }}
            autoComplete={value.autoComplete}
            helperText={value.validationError ? value.validationErrorText : ''}>
            {getOptions()}
          </TextField>
        </FormControl>
      )}
    </>
  );
};

export { KVPDropdown };
