export const EmailSentMessage = 'We will send an email to the requested address if we found a match in our system';

export enum EmailValidationStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
  RISKY = 'Risky',
}

export type EmailValidationResult = {
  validationStatus: EmailValidationStatus;
  suggestedDomain: string;
  emailAddress: string;
  isDisposableAddress: boolean;
  isRoleAddress: boolean;
  err?: any;
  validationMessage?: string;
};

export enum InputValidationType {
  EMAIL_ADDRESS = 'EmailAddress',
  BANK_ACCOUNT_ROUTING_NUMBER = 'BankAccountRoutingNumber'
}

export type InputValidationParam = {
  inputValue: string;
  inputValidationType: InputValidationType;
}

export enum InputValidationStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
  WARNING = 'Warning',
}

export type InputValidationResult = {
  inputValue: string;
  validationStatus: InputValidationStatus;
  validationMessage: string;
  validationProperties: any;
  err?: any;
}