import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { Information } from '../..';
import { HeartlandErrorText, HeartlandRadioLabel } from '../../../../../themes/overrides';
import { FieldValue, FormFieldOption, QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  label: string;
  value: FieldValue;
  options: Array<FormFieldOption>;
}

const KVPCheckbox: React.FC<Props> = ({
  fieldDefinitionJson,
  helperText,
  label,
  onChange,
  options,
  value,
  infoHtml,
  fieldType,
  fieldSubType,
  hideLabel = false,
}) => {
  const multiSelect = fieldDefinitionJson?.MultiSelect;

  const handleButtonClick = (option: FormFieldOption) => {
    if (multiSelect) {
      let existingValues: string[] = value.value;
      if (isEmpty(existingValues[0])) existingValues = [];

      let newSelections: string[] = [...existingValues, option.value];

      if (value.value.find((o: string) => o === option.value)) {
        newSelections = newSelections.filter((selection: string) => selection !== option.value);
      }

      if (fieldDefinitionJson?.Link && newSelections.includes(option.value)) {
        const linkValue = fieldDefinitionJson?.Link.Value;
        if (option.value === linkValue) {
          newSelections = [...newSelections, ...fieldDefinitionJson?.Link.Values];
        }
      }

      onChange(value.questionId, newSelections, true, '');
    }

    if (!multiSelect) onChange(value.questionId, option.value, true, '');
  };

  return (
    <FormControl fullWidth>
      {!hideLabel && (
        <HeartlandRadioLabel
          shrink
          htmlFor={fieldDefinitionJson?.Properties[0].PropertyKey}
          required={value.isRequired}>
          {label} {infoHtml && <Information infoHtml={infoHtml} />}
        </HeartlandRadioLabel>
      )}
      {options
        .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
        .map((option: FormFieldOption, i) => {
          let checked = value.value.includes(option.value);
          return (
            <FormControlLabel
              classes={{ label: 'radio-label' }}
              color="text.secondary"
              key={option.id}
              id={fieldDefinitionJson?.Properties[0].PropertyKey + '_' + option.value}
              value={option.value}
              label={
                <>
                  {option.label}
                  {option.infoHtml && <Information infoHtml={option.infoHtml} />}
                </>
              }
              control={
                <Checkbox
                  color="primary"
                  onChange={() => handleButtonClick(option)}
                  checked={checked}
                  inputProps={
                    {
                      'data-testid': `${option.id}-checkbox`,
                      ...formHelperService.getCustomInputProps(
                        fieldType,
                        fieldDefinitionJson?.Properties[0].PropertyKey,
                        option.label,
                        fieldSubType,
                      ),
                    } as any
                  }
                />
              }
            />
          );
        })}
      {value.validationErrorText && <HeartlandErrorText>{value.validationErrorText}</HeartlandErrorText>}
      {!isEmpty(helperText) && <FormHelperText dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>}
    </FormControl>
  );
};

export { KVPCheckbox };
