import { useState } from 'react';

import { AppBar, Box, ClickAwayListener, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HamburgerMenu from 'react-hamburger-menu';

import ManualOptOut from './manualOptOut';
import Resume from '../../session/resumeSession/button';
import { ThemeSwitch } from '../../themes/overrides';
import { VegaMuiThemeTypes } from '../../themes/theme';
import { DeviceTypes } from '../../types';
import { Business, HEARTLAND_US } from '../../types/enums/appState';
import { OneXThemePalette } from '../../types/enums/theme';
import { PhaseResponse, PhaseType, PhaseTypes } from '../../types/phase';
import { Session } from '../../types/session';
import { getQueryParams, isImageUrl } from '../../utility';
import { useOnDemandTheme } from '../../utility/hooks';
import ShowOnDevice from '../../utility/showOnDevice';

import styles from './index.module.css';
import { getOnDemandCustomElementProps } from '../../utility/screenUtils';

interface Props {
  phase: PhaseTypes | undefined;
  session: Session | undefined;
  onSessionChange: (res: PhaseResponse) => void;
}
const DefaultHeader: React.FC<Props> = ({ phase, session, onSessionChange }) => {
  const { theme: onDemandTheme, forcedTheme, handleThemeChange } = useOnDemandTheme();
  const activeTheme = forcedTheme ?? onDemandTheme;

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const theme = useTheme<OneXThemePalette>();
  const params: any = getQueryParams();
  const imgHost = import.meta.env.VITE_IMAGE_CDN;

  const baseHeaderStyle = {
    backgroundColor: theme.palette.primary.light,
    flexDirection: 'row',
    borderBottom: '1px solid ' + theme.palette.dark?.customBorder,
  };

  const logoUrl = session?.onDemandConfig.logoUrl;
  const headerTitle = session?.onDemandConfig.headerTitle ?? 'On Demand';
  const partnerLogo = session?.sessionInfo.partnerLogo ?? null;
  const showThemeSwitch = false; //isEmpty(footerSource) && isEmpty(partnerLogo);

  const gpBusiness = session?.onDemandConfig.gpBusiness ?? Business.HEARTLAND;

  const getHeaderTrayContent = () => {
    let headerSubtitleComponent = (
      <>
        <Box className={styles.verticalLine} />
        <Box className={styles.headerLogoText} color="text.primary">
          {headerTitle}
        </Box>
      </>
    );

    let headerImage = (
      <img
        alt="heartland-logo"
        data-testid="heartland-logo-img"
        className={
          (gpBusiness === Business.HEARTLAND ? styles.heartlandLogo : styles.gpiLogo) +
          (activeTheme === VegaMuiThemeTypes.Dark ? ' ' + styles.inverseLogo : '')
        }
        src={`${imgHost}/${gpBusiness === Business.HEARTLAND ? 'heartlandLogoGray' : 'gpi-logo'}.svg`}
      />
    );

    if (partnerLogo != null) {
      if (isImageUrl(partnerLogo)) {
        headerImage = (
          <img alt="partner-logo" data-testid="partner-logo-img" className={styles.partnerLogo} src={partnerLogo} />
        );
      } else {
        let partnerText = partnerLogo;
        //trim partnerLogo if it is over 30 characters
        if (partnerText.length > 30) {
          partnerText = partnerText.substring(0, 30) + '...';
        }
        headerImage = (
          <Box color="text.primary" data-testid="partner-logo-text" className={styles.partnerLogoTextOnly}>
            {partnerText}
          </Box>
        );
      }

      headerSubtitleComponent = (
        <>
          <Box className={styles.verticalLine} />
          <Box className={styles.headerLogoText} color="text.primary">
            Powered by {gpBusiness}
          </Box>
        </>
      );
    }

    if (logoUrl != null) {
      headerImage = <img alt="custom-logo" data-testid="custom-logo-img" className={styles.customLogo} src={logoUrl} />;
    }

    return (
      <>
        {headerImage}
        {headerSubtitleComponent}
      </>
    );
  };

  return (
    <>
      <AppBar position="fixed" className={styles.header + ' fade-in'} sx={baseHeaderStyle}>
        <Box className={styles.headerInnerContent}>
          <Box className={styles.headerTrayLeft}>{getHeaderTrayContent()}</Box>
          <Box className={styles.headerTray}>
            {showThemeSwitch && (
              <ThemeSwitch
                id="changeThemeSwitch"
                disableRipple
                inputProps={{
                  'aria-label': 'Change theme to ' + (activeTheme === VegaMuiThemeTypes.Dark ? 'light.' : 'dark.'),
                }}
                title={'Change theme to ' + (activeTheme === VegaMuiThemeTypes.Dark ? 'light.' : 'dark.')}
                checked={activeTheme === VegaMuiThemeTypes.Dark}
                onChange={() =>
                  handleThemeChange(
                    activeTheme === VegaMuiThemeTypes.Dark ? VegaMuiThemeTypes.Light : VegaMuiThemeTypes.Dark,
                  )
                }
              />
            )}
            <ClickAwayListener
              mouseEvent="onMouseDown"
              onClickAway={() => {
                setMobileMenuOpen(false);
              }}>
              <Box>
                <ShowOnDevice showOn={DeviceTypes.Mobile}>
                  <HamburgerMenu
                    width={18}
                    height={15}
                    animationDuration={0.5}
                    isOpen={mobileMenuOpen}
                    menuClicked={() => setMobileMenuOpen(!mobileMenuOpen)}
                    color={theme.palette.text.primary}
                  />
                </ShowOnDevice>

                <Box
                  bgcolor={'primary.light'}
                  sx={mobileMenuOpen ? { borderBottom: '1px solid ' + theme.palette.dark?.customBorder } : {}}
                  className={mobileMenuOpen ? styles.mobileOpen : styles.mobileClosed}>
                  <>
                    <Box className={styles.headerActionButtons}>
                      {params?.resume && (
                        <Resume
                          resumeParam={params?.resume}
                          onSuccess={onSessionChange}
                          onMobileClick={() => setMobileMenuOpen(false)}
                        />
                      )}
                      {phase && (
                        <ManualOptOut
                          endResult={phase?.phaseType === PhaseType.STATUS}
                          onOptOut={onSessionChange}
                          onMobileClick={() => setMobileMenuOpen(false)}
                        />
                      )}
                      <ShowOnDevice showOn={DeviceTypes.Mobile}>
                        <Link
                          href={HEARTLAND_US}
                          onClick={() => setMobileMenuOpen(false)}
                          target="_blank"
                          rel="noreferrer"
                          {...getOnDemandCustomElementProps('btnGoToMainWebSite', 'Go to main website')}>
                          Go to main website
                        </Link>
                      </ShowOnDevice>
                    </Box>
                  </>
                </Box>
              </Box>
            </ClickAwayListener>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
export default DefaultHeader;
