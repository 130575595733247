import { useState } from 'react';

import { Box, Card, CardContent, Link } from '@mui/material';

import { ConfirmModal, Header, UserSessionCard } from './components';
import DefaultHeader from '../../layout/defaultHeader';

import style from './index.module.css';
import { getOnDemandCustomElementProps } from '../../utility/screenUtils';

export interface UserClientSession {
  sessionUid: string;
  email?: string;
  companyName?: string;
  productsOfInterest?: string;
  workingWithName?: string;
  partnerName?: string;
  sessionExpirationUTC: string;
  sessionExpirationDescription: string;
  lastUpdatedUTC: string;
  lastUpdatedDescription: string;
  sessionDescription: string;
  statusDescription: UserClientSessionStatus;
  sessionCreatedByPartner: boolean;
}

export enum UserClientSessionStatus {
  ABOUT_TO_EXPIRE = 'About to expire',
  IN_PROGRESS = 'In progress',
  EXPIRED = 'Expired',
  COMPLETED = 'Completed',
}

type Props = {
  userClientSessions: Array<UserClientSession>;
  onNewApplicationContinue: () => void;
  onUserClientSessionSelected: (sessionUid: string) => void;
};

const SwapSession = ({ userClientSessions, onNewApplicationContinue, onUserClientSessionSelected }: Props) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  const handleOnNewApplicationContinue = () => {
    if (userClientSessions.some((session) => session.sessionCreatedByPartner)) {
      setConfirmationModalOpen(true);
    } else {
      onNewApplicationContinue();
    }
  };

  return (
    <>
      <DefaultHeader onSessionChange={(_) => {}} phase={undefined} session={undefined} />

      <Box className={style.container}>
        <Card className={style.card}>
          <CardContent className={style.cardContent}>
            <Header
              onNewApplicationContinue={handleOnNewApplicationContinue}
              userClientSessionsStatuses={userClientSessions.map((session) => session.statusDescription)}
            />

            {userClientSessions.map((userClientSession) => (
              <Box
                key={userClientSession.sessionUid}
                onClick={() => onUserClientSessionSelected(userClientSession.sessionUid)}
                data-testid={`user-client-session-id-${userClientSession.sessionUid}`}
                className={style.sessionEntry}
                {...getOnDemandCustomElementProps(
                  `btnUserClientSession-${userClientSession.sessionUid}`,
                  userClientSession.statusDescription,
                )}>
                <UserSessionCard userClientSession={userClientSession} />
              </Box>
            ))}

            <Box className={style.cardContentFooter}>
              <Link
                onClick={handleOnNewApplicationContinue}
                underline="always"
                color="primary.main"
                {...getOnDemandCustomElementProps(`btnStartNewApplication`, 'Start a new application')}>
                Start a new application
              </Link>
            </Box>
          </CardContent>
        </Card>

        <ConfirmModal
          isOpen={isConfirmationModalOpen}
          onContinue={onNewApplicationContinue}
          onClose={() => setConfirmationModalOpen(false)}
        />
      </Box>
    </>
  );
};

export default SwapSession;
