export const InsertToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const RetrieveFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  const cleanData = data ? JSON.parse(data) : null;
  return cleanData;
};

export const DeleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export interface IStorageService<T> {
  key: string;
  isPresent: () => boolean;
  get: () => T;
  set: (data: T) => void;
  clear: () => void;
  remove: () => void;
}

export interface IArrayStorageService<T> {
  key: string;
  isPresent: () => boolean;
  get: () => Array<T>;
  set: (data: Array<T>) => void;
  clear: () => void;
  remove: () => void;
}

export class StorageService<T> implements IStorageService<T> {
  private backingStoreAccessor: () => Storage;
  public key: string;

  constructor(key: string, backingStoreAccessor: () => Storage) {
    this.key = key;
    this.backingStoreAccessor = backingStoreAccessor;
  }

  isPresent = (): boolean =>
    this.backingStoreAccessor().getItem(this.key) !== null &&
    this.backingStoreAccessor().getItem(this.key) !== 'undefined' &&
    this.backingStoreAccessor().getItem(this.key) !== 'null' &&
    this.backingStoreAccessor().getItem(this.key) !== '{}';

  get = (): T => {
    let result = {} as T;

    try {
      const storedData = this.backingStoreAccessor().getItem(this.key);

      result = storedData ? (JSON.parse(storedData) as T) : ({} as T);
    } catch (error) {
      console.error(`${this.key} - Invalid object, unable to parse.`);
    }

    return result;
  };

  set = (data: T) => this.backingStoreAccessor().setItem(this.key, JSON.stringify(data));

  clear = () => this.backingStoreAccessor().setItem(this.key, JSON.stringify({}));

  remove = () => this.backingStoreAccessor().removeItem(this.key);
}

export class ArrayStorageService<T> implements IArrayStorageService<T> {
  private backingStoreAccessor: () => Storage;
  public key: string;

  constructor(key: string, backingStoreAccessor: () => Storage) {
    this.key = key;
    this.backingStoreAccessor = backingStoreAccessor;
  }

  isPresent = (): boolean =>
    this.backingStoreAccessor().getItem(this.key) !== null &&
    this.backingStoreAccessor().getItem(this.key) !== 'undefined' &&
    this.backingStoreAccessor().getItem(this.key) !== 'null' &&
    this.backingStoreAccessor().getItem(this.key) !== '[]';

  get = (): Array<T> => {
    let result = new Array<T>();

    try {
      const storedData = this.backingStoreAccessor().getItem(this.key);

      result = storedData ? (JSON.parse(storedData) as Array<T>) : new Array<T>();
    } catch (error) {
      console.error(`${this.key} - Invalid array, unable to parse.`);
    }

    return result;
  };

  set = (data: Array<T>) => this.backingStoreAccessor().setItem(this.key, JSON.stringify(data));

  clear = () => this.backingStoreAccessor().setItem(this.key, JSON.stringify(new Array<T>()));

  remove = () => this.backingStoreAccessor().removeItem(this.key);
}

export const createLocalStorageService = <T>(key: string): IStorageService<T> =>
  new StorageService<T>(key, () => window.localStorage);

export const createSessionStorageService = <T>(key: string): IStorageService<T> =>
  new StorageService<T>(key, () => window.sessionStorage);

export const createLocalArrayStorageService = <T>(key: string): IArrayStorageService<T> =>
  new ArrayStorageService<T>(key, () => window.localStorage);

export const createSessionArrayStorageService = <T>(key: string): IArrayStorageService<T> =>
  new ArrayStorageService<T>(key, () => window.sessionStorage);
