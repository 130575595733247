import React from 'react';

import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';

import { Information } from '../../';
import CustomDivider from '../../../../../themes/customDivider';
import { EquipmentItem, FieldValue, QuestionProps } from '../../../../../types/form';
import NumberStepper from '../../../../../utility/numberStepper';

import styles from './index.module.css';

interface Props extends QuestionProps {
  value: FieldValue;
}

const defaultMaximum = 99;

const Equipment: React.FC<Props> = ({
  label,
  fieldDefinitionJson,
  onChange,
  value,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const equipment: Array<EquipmentItem> = fieldDefinitionJson.Properties ?? [];

  const handleChange = (index: number, quantity: number) => {
    const newEquipment = [...value.value];
    const maximum = equipment[index]?.Maximum ?? defaultMaximum;
    const newValue = quantity;

    if (newValue > 0 && newValue <= parseInt(maximum)) {
      newEquipment[index] = newValue;
      onChange(value.questionId, newEquipment, true, null);
      return;
    }

    if (newValue <= 0) {
      newEquipment[index] = value.isRequired ? 1 : 0;
      onChange(value.questionId, newEquipment, true, null);
      return;
    }
  };

  return (
    <Box>
      {!isEmpty(label) && !hideLabel && (
        <Box className={styles.label} color="text.primary">
          {label} {infoHtml && <Information infoHtml={infoHtml} />}
        </Box>
      )}

      {equipment.map((item: EquipmentItem, i) => {
        const isSingle = equipment.length === 1;

        return (
          <React.Fragment key={item.PropertyKey + i}>
            <Box className={styles.equipmentItem} sx={{ flexDirection: isSingle ? 'column' : 'row' }}>
              <img
                className={isSingle ? styles.imageLarge : styles.imageSmall}
                alt={item.Label}
                data-testid={item.PropertyKey + 'Image'}
                src={import.meta.env.VITE_IMAGE_CDN + item.ImageUrl}
              />

              {!isEmpty(item.Label) && (
                <Box className={styles.itemLabel} color="text.primary">
                  {item.Label}
                </Box>
              )}

              <Box className={styles.quantityButtons}>
                <NumberStepper
                  value={value.value[i] ? Number(value.value[i]) : value.isRequired ? 1 : 0}
                  onChange={(q) => {
                    handleChange(i, q);
                  }}
                  label={item.PropertyKey}
                  min={value.isRequired ? 1 : 0}
                  max={item?.Maximum ? Number(item.Maximum) : Number(defaultMaximum)}
                  propertyKey={item.PropertyKey}
                  fieldType={fieldType}
                />
              </Box>
            </Box>

            {i !== equipment.length - 1 && equipment.length > 1 && <CustomDivider sx={{ width: '100%' }} />}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export { Equipment };
