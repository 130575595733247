import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { getIcon } from '../../../../utility/icon';
import Modal from '../../../../utility/modal';
import { getOnDemandCustomElementProps, screenUtils } from '../../../../utility/screenUtils';

import styles from './index.module.css';

interface Props {
  isFetching?: boolean;
  isOpen: boolean;
  showViewAllApplications?: boolean;
  onContinue: () => void;
  onClose: () => void;
  onViewAllApplications?: () => void;
}

export function ApplicationOpenedModal({
  isFetching = false,
  isOpen,
  showViewAllApplications = false,
  onContinue,
  onClose,
  onViewAllApplications,
}: Props) {
  const isMobile = screenUtils.DeviceIsMobile();

  return (
    <Modal
      show={isOpen}
      fullScreen={isMobile}
      size="md"
      closeModal={onClose}
      hideTitle
      title=""
      onlyCloseButton={!isFetching}>
      <Box className={styles.container} data-testid="application-opened-modal">
        <h1>
          We have detected that you have an application open in another tab. Only one application can be active at a
          time.{' '}
        </h1>
        <Box className={styles.buttonContainer}>
          {showViewAllApplications && onViewAllApplications && (
            <Button
              variant="outlined"
              color="primary"
              data-testid="btn-view-all-applications"
              onClick={onViewAllApplications}
              disabled={isFetching}
              {...getOnDemandCustomElementProps('btnViewAllApplications', 'View all applications')}>
              View all applications
            </Button>
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            data-testid="btn-continue-on-this-tab"
            endIcon={
              !isFetching && (
                <FontAwesomeIcon
                  className="button-icon"
                  icon={getIcon('faChevronRight')}
                  data-testid="btn-continue-on-this-tab-icon"
                />
              )
            }
            disabled={isFetching}
            loading={isFetching}
            onClick={onContinue}
            {...getOnDemandCustomElementProps('btnContinueOnThisTab', 'Continue on this tab')}>
            {!isFetching && 'Continue on this tab'}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
