import { QueryClient } from 'react-query';

const OnDemandQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60000,
    },
  },
});

export { OnDemandQueryClient };
