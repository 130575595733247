import { useEffect, useState } from 'react';

import { Button, Link, Typography } from '@mui/material';

import { DeviceTypes } from '../../../types';
import { PhaseResponse } from '../../../types/phase';
import { ResumeSessionScope } from '../../../types/session';
import { parseResumeSessionScope } from '../../../utility';
import { currentSessionContextService } from '../../../utility/sessionContext';
import ShowOnDevice from '../../../utility/showOnDevice';
import { useSession } from '../../hooks/useSession';
import VerificationCodeModal from '../modal';

import './index.css';

interface Props {
  resumeParam: string | undefined;
  onSuccess: Function;
  onMobileClick: () => void;
}

const Resume: React.FC<Props> = ({ resumeParam, onSuccess, onMobileClick }) => {
  const { session } = useSession();
  const [submitTimeout, setSubmitTimeout] = useState<boolean>(false);
  const [timeoutCounter, setTimeoutCounter] = useState(+(import.meta.env.VITE_VERIFICATION_REQUEST_DELAY || 0));
  const [attempts, setAttempts] = useState<number>(0);
  const [preventAttempt, setPreventAttempt] = useState<boolean>(false);
  const [getAttention, setGetAttention] = useState<boolean>(false);
  const fromUrl = Boolean(resumeParam?.length); //scope will not be empty if resuming from a url
  const [showModal, setShowModal] = useState<boolean>(fromUrl);

  useEffect(() => {
    if (attempts === +(import.meta.env.VITE_MAX_VERIFICATION_CODE_ATTEMPT || 0)) {
      setPreventAttempt(true);
    }
  }, [attempts]);

  useEffect(() => {
    if (submitTimeout) {
      let timeout = setTimeout(() => {
        setTimeoutCounter(timeoutCounter - 1);
        if (timeoutCounter === 0) {
          setSubmitTimeout(false);
        }
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setTimeoutCounter(+(import.meta.env.VITE_VERIFICATION_REQUEST_DELAY || 0));
    }
  }, [submitTimeout, timeoutCounter]);

  //checking if we need pulse the Resume button, but not if we are in the same session.
  useEffect(() => {
    const isEmailVerified = currentSessionContextService.isCurrentEmailVerified;
    if (isEmailVerified) {
      setGetAttention(true);
    }
  }, [session?.sessionUid]);

  if (!session || (!session.onDemandConfig.allowResume && !fromUrl)) {
    return null;
  } else if (
    session.sessionInfo?.email &&
    session.sessionInfo?.isEmailVerified &&
    !session?.onDemandConfig?.customHeaderUrl
  ) {
    return (
      <Typography style={{ justifySelf: 'flex-start', marginLeft: '1vh' }} color="textPrimary">
        {session.sessionInfo?.email}
      </Typography>
    );
  } else if (
    session.sessionInfo?.aaid &&
    session.onDemandConfig.allowResume &&
    !session?.onDemandConfig?.customHeaderUrl
  ) {
    return (
      <>
        <ShowOnDevice showOn={DeviceTypes.Desktop}>
          <Button
            id="resumeButton"
            variant="outlined"
            color="primary"
            style={{ justifySelf: 'flex-start', marginLeft: '1vh' }}
            className={getAttention ? 'pulse' : ''}
            onClick={() => setShowModal(true)}>
            Resume
          </Button>
        </ShowOnDevice>
        <ShowOnDevice showOn={DeviceTypes.Mobile}>
          <Link
            color="primary"
            role="button"
            id="resumeButton"
            onClick={() => {
              setShowModal(true);
              onMobileClick();
            }}>
            Resume previous session
          </Link>
        </ShowOnDevice>
        <VerificationCodeModal
          resumeScope={ResumeSessionScope.AAID}
          userSession={session}
          show={showModal}
          setSubmitTimeout={setSubmitTimeout}
          submitTimeout={submitTimeout}
          timeoutCounter={timeoutCounter}
          setAttempts={setAttempts}
          attempts={attempts}
          preventAttempt={preventAttempt}
          closeModal={() => setShowModal(false)}
          onSuccess={(response: PhaseResponse) => {
            onSuccess(response);
          }}
        />
      </>
    );
  } else if (fromUrl && session.onDemandConfig.allowResume) {
    return (
      <VerificationCodeModal
        resumeScope={parseResumeSessionScope(resumeParam)}
        userSession={session}
        show={showModal}
        setSubmitTimeout={setSubmitTimeout}
        submitTimeout={submitTimeout}
        timeoutCounter={timeoutCounter}
        setAttempts={setAttempts}
        attempts={attempts}
        preventAttempt={preventAttempt}
        closeModal={() => setShowModal(false)}
        onSuccess={(response: PhaseResponse) => {
          setShowModal(false);
          onSuccess(response);
        }}
      />
    );
  } else {
    return null;
  }
};

export default Resume;
