import { Divider, useTheme } from '@mui/material';

import { OneXThemePalette } from '../types/enums/theme';

const CustomDivider = (props: { sx?: any }) => {
  const theme = useTheme<OneXThemePalette>();

  return <Divider sx={{ ...props.sx, borderColor: theme.palette.text.secondary, opacity: 0.2 }} data-testid="customDivider" />;
};

export default CustomDivider;
