import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import { useIsFrame } from '../screenUtils';

interface Props {
  showChatBot?: Boolean;
  children: any;
}

type CookieConfigDefinition = {
  src: string;
  data: string;
  content: string;
  domainFilter: string | undefined;
};

const HeaderLoader: React.FC<Props> = ({ showChatBot, children }) => {
  const [isFrame] = useIsFrame();
  const { onDemandCookieScript, onDemandZendeskChatbot, onDemandDriftChatbot } = useFlags();

  let cookieConfigDefinition: CookieConfigDefinition | undefined;

  if (import.meta.env.VITE_COOKIES_CONFIG) {
    const cookieConfigDefinitions = JSON.parse(import.meta.env.VITE_COOKIES_CONFIG) as Array<CookieConfigDefinition>;

    if (cookieConfigDefinitions) {
      const filteredDefinitions = _.filter(cookieConfigDefinitions, (x) => x.domainFilter != null);
      if (filteredDefinitions.length > 0) {
        for (let filteredDefinition of filteredDefinitions) {
          if (window.location.host.toLowerCase() === filteredDefinition.domainFilter?.toLowerCase()) {
            cookieConfigDefinition = filteredDefinition;
            break;
          }
        }
      }

      //fall back to the default value
      if (cookieConfigDefinition == null) {
        cookieConfigDefinition = _.find(cookieConfigDefinitions, (x) => x.domainFilter == null);
      }
    }
  }

  //You can have multiple Helmets as long as they don't have the EXACT same tag.
  return (
    <>
      {/* Cookie Scripts */}
      {onDemandCookieScript && cookieConfigDefinition && isFrame === false && (
        <Helmet>
          <script
            src={cookieConfigDefinition.src}
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={cookieConfigDefinition.data}></script>
          <script type="text/javascript">{cookieConfigDefinition.content}</script>
        </Helmet>
      )}
      {/* End Cookie Scripts */}

      {/* ZenDesk ChatBot */}
      {onDemandZendeskChatbot && showChatBot && isFrame === false && (
        <Helmet>
          <script id="ze-snippet" src={import.meta.env.VITE_ZENDESK_CHATBOT_SCRIPT_SRC}></script>
        </Helmet>
      )}
      {/* ZenDesk ChatBot */}

      {/* Drift ChatBot */}
      {onDemandDriftChatbot && showChatBot && isFrame === false && (
        <Helmet>
          <script>{import.meta.env.VITE_CHATBOT_SCRIPT}</script>
        </Helmet>
      )}
      {/* Drift ChatBot */}
      {children}
    </>
  );
};
export default HeaderLoader;
