// const errorMessages = {
//   Unauthorized: (message: string) => /^Unauthorized to refresh Client Token for UserClientUid '[^']*'$/.test(message),
//   SessionNotFound: (message: string) =>
//     /^UserSession with SessionUID '[^']*' not found for current UserClient '[^']*'$/.test(message),
// };

export enum ApiResponseError {
  Unknown,
  BadRequest,
  Unauthorized,
  NotFound,
}

function getCauseForStatusCode(statusCode: number | undefined): ApiResponseError {
  if (statusCode === 400) return ApiResponseError.BadRequest;
  else if (statusCode === 401) return ApiResponseError.Unauthorized;
  else if (statusCode === 404) return ApiResponseError.NotFound;

  return ApiResponseError.Unknown;
}

export class RefreshClientTokenError extends Error {
  apiMessage?: string;

  constructor(message: string, apiMessage?: string | undefined, statusCode?: number) {
    super(message);
    this.name = 'RefreshClientTokenError';
    this.apiMessage = apiMessage;
    this.cause = getCauseForStatusCode(statusCode);
  }
}

export class StartupSessionError extends Error {
  apiMessage?: string;

  constructor(message: string, apiMessage?: string | undefined, statusCode?: number) {
    super(message);
    this.apiMessage = apiMessage;
    this.name = 'StartupSessionError';
    this.cause = getCauseForStatusCode(statusCode);
  }
}
