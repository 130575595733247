import { startTransition, useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from 'react-query';

import { Information } from '../../';
import { emailValidation } from '../../../../../api/inputValidation';
import { queryClient } from '../../../../../App';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { ValidationResults } from '../../../../../types/enums/ValidationResults';
import { EmailValidatorId, QuestionProps } from '../../../../../types/form';
import { PhaseQueries } from '../../../../../types/queries';
import { EmailValidationResult, EmailValidationStatus } from '../../../../../types/verification';
import { isEmpty, validateEmailString } from '../../../../../utility';
import MaskedTextInput from '../../../../../utility/maskedTextInput';
import { formHelperService } from '../../../services';

import styles from './index.module.css';

export const iconTypes = {
  error: DangerousIcon,
  warning: WarningIcon,
  success: CheckCircleIcon,
};

export const invalidMessage = 'Your email address appears to be invalid. Please correct the issue and try again.';
export const riskyRoleMessage =
  'Please consider using an individual email address (john.doe@example.com) versus a group email (admin@example.com).';
export const riskyDisposableMessage =
  'Please consider using an individual, non-disposable email address (john.doe@example.com).';
export const riskyDefaultMessage = 'Your email address might not be correct, please check the address and try again.';

const EmailValidator: React.FC<QuestionProps> = ({
  fieldDefinitionJson,
  label,
  onChange,
  value,
  placeholder,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const isMobile = useMediaQuery('(max-width:992px)');
  const theme = useTheme<OneXThemePalette>();
  const inputProps = {
    maskType: fieldDefinitionJson?.MaskType,
    type: 'email',
    'data-testid': EmailValidatorId,
    ...formHelperService.getCustomInputProps(fieldType, fieldDefinitionJson.Properties[0].PropertyKey, label),
  };
  const [internalValue, setInternalValue] = useState(value.value);
  const [hasChanged, setHasChanged] = useState(false);
  const { data, refetch, isFetching } = useQuery<EmailValidationResult>(
    PhaseQueries.EMAIL_VALIDATION,
    async () => await emailValidation(internalValue),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      enabled: false,
    },
  );

  const getIconDisplay = () => {
    if (isFetching && validateEmailString(internalValue) === ValidationResults.VALID)
      return <CircularProgress size={15} color="primary" />;
    if (data?.emailAddress === internalValue) {
      if (data?.validationStatus === EmailValidationStatus.VALID)
        return <CheckCircleIcon data-testid="valid-icon" fontSize="medium" color="success" />;
      if (data?.validationStatus === EmailValidationStatus.INVALID)
        return <DangerousIcon data-testid="invalid-icon" fontSize="medium" color="error" />;
      if (data?.validationStatus === EmailValidationStatus.RISKY)
        return (
          <WarningIcon
            data-testid="warning-icon"
            fontSize="medium"
            sx={{ color: theme.palette.primary.accentPrimary10 }}
          />
        );
    }
    return null;
  };

  const getErrorText = () => {
    let text = value.validationError ? value.validationErrorText : '';

    if (!isEmpty(data?.suggestedDomain) && data?.validationStatus === EmailValidationStatus.RISKY) {
      const suggestedEmail = `${data?.emailAddress.split('@')[0]}@${data?.suggestedDomain}`;

      return (text = (
        <span>
          Your email address might not be correct. Did you mean&nbsp;
          <span className={styles.suggestion} onClick={() => setInternalValue(suggestedEmail)}>
            {suggestedEmail}
          </span>
          ?
        </span>
      ));
    }

    if (!isEmpty(data?.suggestedDomain) && data?.validationStatus === EmailValidationStatus.INVALID) {
      const suggestedEmail = `${data?.emailAddress.split('@')[0]}@${data?.suggestedDomain}`;
      return (text = (
        <span>
          Your email address appears to be invalid. Did you mean&nbsp;
          <span className={styles.suggestion} onClick={() => setInternalValue(suggestedEmail)}>
            {suggestedEmail}
          </span>
          ?
        </span>
      ));
    }

    if (data?.validationStatus === EmailValidationStatus.INVALID) {
      return (text = invalidMessage);
    }

    if (!isEmpty(data?.err)) {
      return (text = data?.validationMessage);
    } else if (data?.validationStatus === EmailValidationStatus.RISKY && data?.isRoleAddress) {
      return (text = riskyRoleMessage);
    } else if (data?.validationStatus === EmailValidationStatus.RISKY && data?.isDisposableAddress) {
      return (text = riskyDisposableMessage);
    } else if (data?.validationStatus === EmailValidationStatus.RISKY) {
      return (text = riskyDefaultMessage);
    }

    return text;
  };

  useEffect(() => {
    let timer: any = null;
    let subscribed = true;
    if (subscribed) {
      if (
        validateEmailString(internalValue) === ValidationResults.VALID &&
        internalValue.trim() !== data?.emailAddress?.trim() &&
        isEmpty(data?.err)
      ) {
        timer = setTimeout(() => {
          refetch();
          if (subscribed) onChange(value.questionId, internalValue.trim(), true);
        }, 800);
      }
    }
    return () => {
      subscribed = false;
      clearTimeout(timer);
    };
  }, [data, internalValue, onChange, refetch, value.questionId]);

  const isError =
    value.validationError ||
    data?.validationStatus === EmailValidationStatus.INVALID ||
    data?.validationStatus === EmailValidationStatus.RISKY;

  const isRisky = data?.validationStatus === EmailValidationStatus.RISKY;

  return (
    <FormControl fullWidth>
      <TextField
        variant="filled"
        id={EmailValidatorId}
        label={
          hideLabel ? null : (
            <Box className={styles.labelContainer}>
              <span>{label}</span>

              {infoHtml && <Information infoHtml={infoHtml} />}
            </Box>
          )
        }
        fullWidth
        required={value.isRequired}
        placeholder={placeholder ?? ''}
        error={isError}
        sx={{
          '& .MuiFormHelperText-root.Mui-error': { color: isRisky ? theme.palette.warning.text : '' },
          '& .MuiFilledInput-root.Mui-error': {
            border: isRisky ? `1px solid ${theme.palette.warning.border}` : '',
          },
        }}
        helperText={getErrorText()}
        InputLabelProps={{
          shrink: true,
          style: (label ?? '').length > 43 && isMobile ? { whiteSpace: 'normal', fontSize: '.8rem' } : {},
        }}
        InputProps={{
          type: 'email',
          inputComponent: MaskedTextInput as any,
          inputProps: inputProps,
          endAdornment: getIconDisplay(),
        }}
        onChange={(e) => {
          setInternalValue(e.target.value);
          queryClient.removeQueries(PhaseQueries.EMAIL_VALIDATION);
          startTransition(() => onChange(value.questionId, e.target.value, false));
          setHasChanged(true);
        }}
        onBlur={() => {
          setInternalValue(internalValue.trim());
          onChange(value.questionId, internalValue.trim(), hasChanged);
        }}
        value={internalValue}
        autoComplete={'on'}
      />
    </FormControl>
  );
};

export { EmailValidator };
