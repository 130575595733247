import { useEffect, useState, useTransition } from 'react';

import { FormHelperText, Slider as MuiSlider, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';

import { Information } from '../..';
import { QuestionProps } from '../../../../../types/form';
import { getNumberToCommaString, isEmpty } from '../../../../../utility';
import NumberInput from '../../../../../utility/numberInput';
import { getOnDemandCustomElementProps } from '../../../../../utility/screenUtils';
import { formHelperService } from '../../../services';

const Slider: React.FC<QuestionProps> = ({
  label,
  onChange,
  value,
  required,
  fieldDefinitionJson,
  helperText,
  placeholder,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const [multiplier, setMultiplier] = useState<number>(
    fieldDefinitionJson?.Multiplier && fieldDefinitionJson?.Multiplier !== 0 ? fieldDefinitionJson.Multiplier : 1,
  );

  // Slider max value represents the MAX value defined on the field definition (e.g. Monthly Max).
  const [sliderMax, setSliderMax] = useState<number>(100);

  // Text Box max value represents the MAX value multiplied by the Multiplier defined on the field definition (e.g. Monthly Max multiplied by the multiplier (12) turns into Yearly Max).
  const [textBoxMax, setTextBoxMax] = useState<number>(100);

  //on initial load, this is a number, but after a change or on rewind it is a string.
  const [textValue, setTextValue] = useState<number | string>(
    fieldDefinitionJson?.DefaultAnnualVolume && value.value === 0
      ? fieldDefinitionJson?.DefaultAnnualVolume
      : value.value,
  );
  const [sliderValue, setSliderValue] = useState<number | string>(
    (fieldDefinitionJson?.DefaultAnnualVolume && value.value === 0
      ? fieldDefinitionJson?.DefaultAnnualVolume
      : value.value) / multiplier,
  );

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (value.value === 0 && fieldDefinitionJson?.DefaultAnnualVolume) {
      onChange(value.questionId, fieldDefinitionJson?.DefaultAnnualVolume.toString(), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldDefinitionJson?.DefaultAnnualVolume, value.questionId]);

  useEffect(() => {
    setMultiplier(
      fieldDefinitionJson?.Multiplier && fieldDefinitionJson?.Multiplier !== 0 ? fieldDefinitionJson.Multiplier : 1,
    );

    setSliderMax(fieldDefinitionJson?.Max ? fieldDefinitionJson.Max : 100);
    setTextBoxMax(sliderMax * multiplier);

    if (fieldDefinitionJson?.CanNotExceedValue && isNaN(fieldDefinitionJson?.CanNotExceedValue) === false) {
      setTextBoxMax(fieldDefinitionJson?.CanNotExceedValue);
      const possibleMaxSliderValue = fieldDefinitionJson?.CanNotExceedValue / multiplier;
      if (possibleMaxSliderValue < sliderMax) {
        setSliderMax(possibleMaxSliderValue);
      }
    }
    //Used for First Load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [, startTransition] = useTransition();
  const theme: any = useTheme();
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const updateValue = typeof newValue === 'number' ? newValue : 100;
    setSliderValue(Math.round(updateValue));

    startTransition(() => setTextValue(updateValue * multiplier));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const newValue = Number(event.target.value);
      setTextValue(newValue);
      setSliderValue(Math.round(newValue / multiplier));
      onChange(value.questionId, newValue.toString(), false);
      setHasChanged(true);
    }
  };

  const sliderCommitChange = (e: any) => {
    if (!fieldDefinitionJson?.ShowTextBox) {
      const newValue =
        typeof sliderValue === 'number' ? sliderValue * multiplier : fieldDefinitionJson?.Min * multiplier;
      //use transition in case user uses keyboard arrows.
      //This is an event that is continuously trigged with key presses, or mouse up.
      startTransition(() => onChange(value.questionId, newValue.toString(), false));
    }
  };

  const handleBlur = () => {
    const newValue = textValue === '' ? fieldDefinitionJson?.Min : Number(textValue);
    setTextValue(newValue);
    startTransition(() => onChange(value.questionId, newValue.toString(), hasChanged));
  };
  return (
    <FormControl fullWidth>
      {label && !hideLabel && (
        <label
          htmlFor={fieldDefinitionJson?.Properties?.PropertyKey ?? label}
          style={{ marginBottom: '2rem', fontWeight: 400 }}>
          {label}
          {required && <span style={{ color: theme?.palette?.warning?.main }}> *</span>}
          {infoHtml && <Information infoHtml={infoHtml} />}
        </label>
      )}
      <MuiSlider
        id={fieldDefinitionJson?.Properties?.PropertyKey ?? label}
        aria-label={label !== '' ? label : 'Numeric Slider'}
        onChange={handleSliderChange}
        onChangeCommitted={sliderCommitChange}
        valueLabelDisplay="on"
        valueLabelFormat={() => {
          let roundedValue = sliderValue;
          if (typeof sliderValue === 'number') {
            roundedValue = Math.round(sliderValue);
          }

          return fieldDefinitionJson?.Prefix
            ? `${fieldDefinitionJson.Prefix}${getNumberToCommaString(roundedValue)}`
            : `${getNumberToCommaString(roundedValue)}`;
        }}
        value={typeof sliderValue === 'number' ? sliderValue : 0}
        min={fieldDefinitionJson?.Min ? fieldDefinitionJson.Min : 0}
        max={sliderMax}
        step={fieldDefinitionJson?.Step ? fieldDefinitionJson?.Step : undefined}
        sx={{ width: '80%', marginBottom: '.75rem', marginTop: '1.5rem', alignSelf: 'center' }}
        {...getOnDemandCustomElementProps(fieldDefinitionJson?.Properties?.PropertyKey, 'Numeric Slider')}
      />
      {fieldDefinitionJson?.ShowTextBox && (
        <TextField
          id={`${fieldDefinitionJson?.Properties?.PropertyKey ?? label}-textbox`}
          label={fieldDefinitionJson?.TextBoxLabel}
          required={required}
          variant="filled"
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={textValue}
          error={value.validationError}
          inputProps={{
            max: fieldDefinitionJson?.CanNotExceedValue ? textBoxMax : undefined,
            decimalPlaces: 0,
            prefix: fieldDefinitionJson?.Prefix ? fieldDefinitionJson.Prefix : undefined,
            ...formHelperService.getCustomInputProps(fieldType, fieldDefinitionJson.Properties[0].PropertyKey, label),
          }}
          InputProps={{
            inputComponent: NumberInput as any,
          }}
          placeholder={placeholder ?? undefined}
        />
      )}
      {!isEmpty(helperText) && (
        <FormHelperText component={'div'} dangerouslySetInnerHTML={{ __html: helperText ?? '' }}></FormHelperText>
      )}
    </FormControl>
  );
};

export { Slider };
