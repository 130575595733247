/* eslint-disable no-unused-vars */

import { useCallback, useMemo, useState } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  AutocompleteInputChangeReason,
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Autocomplete, createFilterOptions } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { isEmpty } from 'lodash';

import { Information } from '../..';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { FieldValue, FormFieldOption, QuestionProps } from '../../../../../types/form';
import NativeDropDown from '../../../../../utility/nativeSelect';
import { GetMobileInputLabelProps } from '../../../../../utility/showOnDevice';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  label: string;
  options: Array<FormFieldOption>;
  value: FieldValue;
  hideLabel?: boolean;
}

const KVPAutocomplete: React.FC<Props> = ({
  label,
  onChange,
  options,
  value,
  fieldDefinitionJson,
  helperText,
  infoHtml,
  fieldType,
  fieldSubType,
  hideLabel = false,
}) => {
  const theme = useTheme<OneXThemePalette>();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [hasChanged, setHasChanged] = useState(false);

  const filterOptionsValue = createFilterOptions({
    stringify: (option: any) => option.label + ' ' + option.value,
    trim: true,
    matchFrom: fieldDefinitionJson?.DisableContainSearch ? 'start' : 'any',
  });

  const filterOptionsLabel = createFilterOptions({
    stringify: (option: any) => option.label,
    trim: true,
    matchFrom: fieldDefinitionJson?.DisableContainSearch ? 'start' : 'any',
  });
  const nativeVal = isMobile && (value.value ?? value ?? '');
  const nativeDefault = nativeVal.value ?? nativeVal;

  const onChangeCallback = useCallback(
    (event: React.SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (['change', 'click', 'keydown'].includes(event?.type)) {
        if (reason === 'clear') {
          onChange(value.questionId, '', hasChanged || !isEmpty(value.value), '');
        } else if (!isEmpty(newInputValue)) {
          var selectedValue = options.find((item) => item.label === newInputValue);
          onChange(value.questionId, selectedValue ?? '', true, '');
          setHasChanged(true);
        }
      }
    },
    [hasChanged, onChange, options, value],
  );

  const autoCompleteOptions = useMemo(() => {
    if (isMobile) {
      return options
        .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
        .map((item) => {
          return { label: item.label, value: item.value };
        });
    }

    return value?.value === ''
      ? [value?.value, ...options.sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))]
      : options.sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0));
  }, [isMobile, options, value?.value]);

  const maxLength = useMemo(
    () =>
      autoCompleteOptions.reduce(
        (previousValue, currentValue) =>
          currentValue?.value?.length > previousValue ? currentValue.value.length : previousValue,
        0,
      ),
    [autoCompleteOptions],
  );

  return isMobile ? (
    <Box sx={{ width: '100%', paddingTop: '.5rem', paddingBottom: 0 }}>
      {!hideLabel && (
        <InputLabel
          {...GetMobileInputLabelProps(label ?? '')}
          htmlFor={fieldDefinitionJson?.Properties[0].PropertyKey}
          required={value.isRequired}
          sx={{ marginLeft: '.2rem', marginBottom: '.8rem' }}>
          {label}
          {infoHtml && <Information infoHtml={infoHtml} />}
        </InputLabel>
      )}
      <NativeDropDown
        id={fieldDefinitionJson?.Properties[0].PropertyKey}
        overrideClass={'mobile-dropdown'}
        value={nativeDefault}
        label={label}
        options={autoCompleteOptions}
        showDefaultPlaceholder
        required={value.isRequired}
        showError={value.validationError}
        customErrorText={value.validationErrorText}
        helperText={helperText ?? ''}
        fieldType={fieldType}
        fieldSubType={fieldSubType}
        propertyKey={fieldDefinitionJson?.Properties[0].PropertyKey}
        onChange={(val: string) => {
          if (val != null) {
            const target = options.find((item) => item.value === (val === 'nativeDefault' ? '' : val));
            onChange(value.questionId, target, true, '');
          }
        }}
        fullWidth
      />
    </Box>
  ) : (
    <Autocomplete
      options={autoCompleteOptions}
      filterSelectedOptions={value.value === ''}
      id={fieldDefinitionJson?.Properties[0].PropertyKey}
      fullWidth
      blurOnSelect
      value={value.value ?? ''}
      autoHighlight
      autoSelect
      handleHomeEndKeys
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={fieldDefinitionJson?.AllowValueFilter ? filterOptionsValue : filterOptionsLabel}
      clearOnEscape
      popupIcon={<ExpandMore />}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            required={value.isRequired}
            title={label.length < 80 ? '' : label}
            variant="filled"
            label={
              hideLabel ? null : (
                <>
                  {label}
                  {infoHtml && <Information infoHtml={infoHtml} />}
                </>
              )
            }
            autoComplete={value.autoComplete}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              ...params.inputProps,
              autoComplete: value.autoComplete,
              maxLength,
              ...formHelperService.getCustomInputProps(
                fieldType,
                fieldDefinitionJson?.Properties[0].PropertyKey,
                label,
                fieldSubType,
              ),
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment,
            }}
            fullWidth
            error={value.validationError}
            helperText={value.validationError ? value.validationErrorText : ''}
          />
          {!isEmpty(helperText) && (
            <FormHelperText
              style={{ marginLeft: '14px' }}
              dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>
          )}
        </>
      )}
      getOptionLabel={(option) => option.label ?? option.value ?? ''}
      onInputChange={onChangeCallback}
      renderOption={(props, option, { inputValue }) => {
        const newProps = {
          ...props,
          ...formHelperService.getCustomInputProps(
            fieldType,
            fieldDefinitionJson?.Properties[0].PropertyKey,
            option?.label,
            fieldSubType,
          ),
        } as any;
        const matches = match(option?.label ?? '', inputValue);
        const parts = parse(option?.label ?? '', matches);

        return (
          <li {...newProps}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}>
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};

export { KVPAutocomplete };
