import React from 'react';

import { NumericFormat } from 'react-number-format';

interface Props {
  inputRef: () => void;
  onChange: (obj: any) => void;
  allowLeadingZeros: boolean;
  allowOverflow: boolean;
  decimalPlaces: number;
  max: number;
  maxLength: number;
  min: number;
  prefix: string;
  suffix: string;
  thousandSeparator: boolean;
  style: any;
  validateMin?: boolean;
}

const NumberInput = React.forwardRef(function numberInput(props: Props, ref) {
  const {
    allowLeadingZeros = false,
    allowOverflow = false,
    inputRef,
    onChange,
    decimalPlaces,
    max,
    maxLength = 500,
    min,
    prefix,
    suffix,
    thousandSeparator = true,
    validateMin = false,
    ...other
  } = props;
  const allowInput = (value: any) => {
    let allowed = true;

    if ((value.floatValue > 2147483647 && !allowOverflow) || (value.floatValue < -2147483648 && !allowOverflow)) {
      allowed = false;
    }

    if (value.value.toString().length > maxLength) {
      allowed = false;
    }

    if (max !== undefined && value.floatValue > max) {
      allowed = false;
    }

    if (validateMin && min !== undefined && (value.floatValue < min || !value.floatValue)) {
      allowed = false;
    }

    return allowed;
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      isAllowed={allowInput}
      decimalScale={decimalPlaces}
      prefix={prefix}
      suffix={suffix}
      onValueChange={(value) => {
        onChange({
          target: {
            value: value.value,
          },
        });
      }}
      inputMode={decimalPlaces ? 'decimal' : 'numeric'}
      thousandSeparator={thousandSeparator}
      allowLeadingZeros={allowLeadingZeros}
      allowNegative={min < 0}
    />
  );
});

export default NumberInput;
