import { Fragment, useEffect, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { FormQuestionGroup } from '..';
import { LayoutCardHeader } from '../../../../layout/card';
import CustomDivider from '../../../../themes/customDivider';
import { FormDivider } from '../../../../themes/overrides';
import { QuestionGroup } from '../../../../types/form';

import './index.css';

interface Props {
  fieldValues: Array<any>;
  formQuestionGroups: Array<QuestionGroup>;
  onFieldChange: any;
  headerHtml: string;
  infoHtml: string;
  name: string;
  cachedGroups: Array<number>;
  setCachedGroups: Function;
  onSensitiveUnlock: (questionId: number) => void;
  setHeight: (height: number) => void;
  showRequiredText: boolean;
}

const Layout: React.FC<Props> = ({
  fieldValues,
  formQuestionGroups,
  onFieldChange,
  headerHtml,
  infoHtml,
  cachedGroups,
  setCachedGroups,
  onSensitiveUnlock,
  setHeight,
  showRequiredText,
}) => {
  const ref = useRef(null as null | HTMLDivElement);
  const height = ref.current?.clientHeight ?? 0;

  useEffect(() => {
    setHeight(height);
  }, [height, setHeight]);

  const showHeaderHtml = !isEmpty(headerHtml);
  const showHeader = showHeaderHtml || showRequiredText;

  return (
    <Box className="form-questions fade-in" ref={ref}>
      {showHeader && (
        <>
          <LayoutCardHeader showHeaderHtml={showHeaderHtml} headerHtml={headerHtml} infoHtml={infoHtml} />

          {showRequiredText && (
            <Box className="required-text-container">
              <Typography color="text.primary" className="required-text">
                All fields marked with an asterisk (
              </Typography>
              <Typography className="inline-text" color="text.secondary">
                *
              </Typography>
              <Typography color="text.primary" className="required-text">
                ) are required.
              </Typography>
            </Box>
          )}

          <CustomDivider sx={{ width: '100%' }} />
        </>
      )}

      {formQuestionGroups.map((x, i) => {
        const filteredFieldValues = fieldValues.filter((v) => v.questionGroupId === x.id && v.visible);

        return filteredFieldValues.length > 0 ? (
          <Fragment key={x.id}>
            <FormQuestionGroup
              formQuestions={x.questions}
              id={x.id}
              cachedGroup={cachedGroups.find((c) => c === x.id) != null}
              fieldValues={filteredFieldValues}
              setCachedGroups={setCachedGroups}
              onSensitiveUnlock={onSensitiveUnlock}
              onFieldChange={onFieldChange}
              headerHtml={x.headerHtml}
              allowLocalCache={x.allowLocalCache}
              infoHtml={x.infoHtml}
            />

            {i !== formQuestionGroups.length - 1 && <FormDivider data-testid="form-divider" />}
          </Fragment>
        ) : null;
      })}
    </Box>
  );
};

export { Layout };
