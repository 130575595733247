import { downloadSessionDocument } from '../../api/document';

const apiUrl = import.meta.env.VITE_API_URL;

export const downloadDocumentByType = async (documentType: string, documentName: string | null = null, locationUid?: string) => {
  await downloadSessionDocument(documentType, locationUid)
    .then((fileResult: any) => {
      const fileURL = URL.createObjectURL(fileResult.blob);
      let link = document.createElement('a');
      link.href = fileURL;
      link.download = documentName ?? fileResult.fileName;
      link.click();
    })
    .catch((exc: any) => {
      throw new Error(exc);
    });
};

export const useDownloadDocument = () => {
  //Redirect to Document Download Endpoint if using link code
  var uri = window.location.toString();
  if (uri.indexOf('Download') > 0) {
    var linkCode = uri.substring(uri.lastIndexOf('/') + 1, uri.length);
    window.location.href = `${apiUrl}/Document/${linkCode}`;
  }
};

export const downloadFunctions = {
  downloadDocumentByType,
  useDownloadDocument,
};
