export enum SESSION_ERROR_MESSAGE {
  ERROR = 'ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CAPTCHA_FAILED = 'Captcha Failed',
  LOAD_FORM_ENTRY_PHASE = 'Load Form Entry Phase',
  EQUIPMENT_MODIFY_CART = 'Equipment Modify Cart',
  EQUIPMENT_GET_SUMMARY = 'Equipment Get Summary',
  EQUIPMENT_GET_LANDING_PAGE = 'Equipment Get Landing Page',
  EQUIPMENT_GET_CART = 'Equipment Get Cart',
}
