import './index.css';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { App } from './App';
import { OnDemandProvider } from './providers';

const container = document.getElementById('root');

const root = createRoot(container!);

const AppContainer = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={String(import.meta.env.VITE_CAPTCHA_SILENT_KEY)}>
      <OnDemandProvider>
        <App />
      </OnDemandProvider>
    </GoogleReCaptchaProvider>
  );
};

const LaunchDarklyProvider = withLDProvider({ clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID?.toString() ?? '' })(
  AppContainer,
);

root.render(<LaunchDarklyProvider />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals();
