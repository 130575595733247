import Edit from '@mui/icons-material/Edit';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';

import { FormFieldTypes } from '../../../../../types/enums/FormFieldTypes';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { formHelperService } from '../../../services';

interface Props {
  value: any;
  setUnlocked: () => void;
  label: string;

  fieldType: FormFieldTypes;
  propertyKey: string;
  hideLabel?: boolean;
}

const SensitiveField: React.FC<Props> = ({ value, setUnlocked, label, fieldType, propertyKey, hideLabel = false }) => {
  const theme = useTheme<OneXThemePalette>();
  return (
    <TextField
      variant="filled"
      label={hideLabel ? null : label}
      fullWidth
      disabled
      sx={{
        '& .Mui-disabled': {
          WebkitTextFillColor: theme.palette.text.secondary,
          cursor: 'not-allowed',
        },
        '& .Mui-disabled:before': {
          borderBottomStyle: 'none !important',
        },

        '& .MuiFilledInput-root:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          border: `1px solid ${theme.palette.primary.inputBorder}`,
        },
      }}
      title="This field contains sensitive information."
      onChange={() => {}}
      InputLabelProps={{ shrink: true, sx: { color: theme.palette.text.primary + ' !important' } }}
      helperText="This field has been masked because it contains sensitive information."
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="edit" onClick={setUnlocked} edge="end">
              <Edit />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      inputProps={{ ...formHelperService.getCustomInputProps(fieldType, propertyKey, label) }}
    />
  );
};

export { SensitiveField };
