import React, { ReactNode, useMemo } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQueryClient } from 'react-query';

import LayoutCard from '../../layout/card';
import { PhaseEvent } from '../../types/phase';
import { trackEvent } from '../eventTracking';

import styles from './index.module.css';
interface Props {
  children: ReactNode;
}

const MaintenanceMode: React.FC<Props> = ({ children }) => {
  const { onDemandMaintenanceMode, onDemandMaintenanceModeText } = useFlags();
  const queryClient = useQueryClient();

  useMemo(() => {
    if (onDemandMaintenanceMode) {
      trackEvent(onDemandMaintenanceModeText, 'MaintenancePage', PhaseEvent.MAINTENANCE_MODE);
    } else {
      //invalidate all queries when maintenance mode is off to ensure reload of data.
      queryClient.invalidateQueries();
    }
  }, [onDemandMaintenanceMode, onDemandMaintenanceModeText, queryClient]);

  if (onDemandMaintenanceMode) {
    return (
      <LayoutCard>
        <Box className={styles.maintenanceHeader} color="text.primary">
          System is temporarily unavailable
        </Box>
        <Box className={styles.maintenanceBody}>
          {onDemandMaintenanceModeText && (
            <Alert
              data-testid="maintenance-alert"
              sx={{ fontFamily: 'inherit', justifyContent: 'flex-start' }}
              severity="warning">
              {onDemandMaintenanceModeText}
            </Alert>
          )}
          <Box className={styles.maintenanceBodyItems}>
            <Box color="text.secondary">
              We apologize for the inconvenience, but our system is temporarily unavailable. We are working on resolving
              the issue as soon as possible. Please try again later.
            </Box>
            <Box color="text.secondary">Thank you for your patience.</Box>
          </Box>
          <Box className={styles.maintenanceBoxImage}>
            <img
              src={import.meta.env.VITE_IMAGE_CDN + '/onDemand/MaintenanceMode.png'}
              alt="Maintenance Mode"
              height={285}
              width={338}
              className={styles.maintenanceImage}
            />
          </Box>
        </Box>
      </LayoutCard>
    );
  } else {
    return <>{children}</>;
  }
};

export default React.memo(MaintenanceMode);
