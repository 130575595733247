/* eslint-disable no-self-compare */
import React from 'react';

import { Box } from '@mui/material';

import BubbleTrackerSegment from './bubbleTrackerSegment';
import PillTrackerSegment from './pillTrackerSegment';
import { useSession } from '../../session/hooks/useSession';
import { PhaseType, TrackerType } from '../../types/phase';
import { screenUtils } from '../../utility/screenUtils';

import styles from './index.module.css';

interface Props {
  showTracker?: boolean;
  type?: TrackerType;
  onlyShow: TrackerType;
  phaseType?: PhaseType;
}

export const bubbleId = 'bubble-tracker-steps';
export const progressTrackerPillId = 'progress-tracker-pill';

const ProgressTracker: React.FC<Props> = ({
  showTracker = false,
  type = TrackerType.PILL,
  onlyShow,
  phaseType = PhaseType.FORM,
}) => {
  const { session } = useSession();

  const [isFrame] = screenUtils.useIsFrame();

  const progressLength = session?.sessionInfo.sessionProgress.length ?? 0;
  const showPill = onlyShow === TrackerType.PILL && onlyShow === type;
  const showBubble = onlyShow === TrackerType.BUBBLE && onlyShow === type;

  let bubbleClass = isFrame
    ? styles.bubbleTrackerFrame
    : (progressLength > 6 ? styles.bubbleTrackerWide : styles.bubbleTracker);

  return (
    <>
      {showTracker && progressLength > 0 && showPill && (
        <Box className={styles.progressTracker} data-testid={progressTrackerPillId}>
          {session!.sessionInfo.sessionProgress.map((x, i) => {
            return <PillTrackerSegment key={x.name} segment={x}></PillTrackerSegment>;
          })}
        </Box>
      )}
      {showTracker && progressLength > 0 && showBubble && (
        <Box className={bubbleClass + ' fade-in'} data-testid="progress-tracker-bubble" id={bubbleId}>
          {session!.sessionInfo.sessionProgress.map((x, i) => {
            return <BubbleTrackerSegment key={x.name} number={i + 1} segment={x} max={progressLength} />;
          })}
        </Box>
      )}
    </>
  );
};

export default ProgressTracker;
