import { ReactNode } from 'react';

import { OnDemandQueryClientProvider } from '../lib/react-query';
import { SessionProvider } from '../session/hooks/useSession';
import { ContentInjectorDebugger } from '../utility/contentInjectorDebugger';
import { AuthUserClientProvider, OnDemandThemeProvider } from '../utility/hooks';
import MaintenanceMode from '../utility/maintenanceMode';

type Props = {
  children: ReactNode;
};

const OnDemandProvider = ({ children }: Props) => {
  return (
    <OnDemandQueryClientProvider>
      <OnDemandThemeProvider>
        <MaintenanceMode>
          <AuthUserClientProvider>
            <SessionProvider>
              <ContentInjectorDebugger>{children}</ContentInjectorDebugger>
            </SessionProvider>
          </AuthUserClientProvider>
        </MaintenanceMode>
      </OnDemandThemeProvider>
    </OnDemandQueryClientProvider>
  );
};

export { OnDemandProvider };
