import { apiFunctions } from '..';
import {
  EmailValidationResult,
  EmailValidationStatus,
  InputValidationParam,
  InputValidationResult,
  InputValidationStatus,
} from '../../types/verification';
const apiUrl = '/InputValidation';

export const validationErrorMessage = 'We can not validate the information.';

export async function inputValidation(params: InputValidationParam): Promise<InputValidationResult> {
  return await apiFunctions.apiPost(apiUrl + '/ValidateInput', params).then((res) => {
    if (res?.err) {
      res = {
        ...res,
        ...params,
        validationStatus: InputValidationStatus.WARNING,
        validationMessage: validationErrorMessage,
      };
    }

    return res;
  });
}

export async function emailValidation(email: string): Promise<EmailValidationResult> {
  return await apiFunctions.apiPost(apiUrl + '/EmailValidation', { email: email }).then((res) => {
    if (res?.err) {
      res = {
        ...res,
        emailAddress: email,
        validationStatus: EmailValidationStatus.RISKY,
        validationMessage: validationErrorMessage,
      };
    }

    return res;
  });
}
