import { apiService, FATAL_STATUS_ERROR, PostResponse } from '../../../services/ApiService';
import { SubmitPhaseRequest, UserProperties, UserPropertiesFormEntry } from '../../../types';
import { SESSION_ERROR_MESSAGE } from '../../../types/enums';
import { FieldValue } from '../../../types/form';
import { FormEntryPhase, PhaseResponse, PhaseType } from '../../../types/phase';
import { currentSessionContextService } from '../../../utility/sessionContext';
import { formHelperService } from '../../form/services';
import { ValidationError } from '../../form/types/ValidationResponse';

interface ILocationService {
  createNewLocation(
    newValues: Array<FieldValue>,
    type: PhaseType,
    captchaToken: string,
  ): Promise<PostResponse<PhaseResponse> | undefined>;
}

class LocationService implements ILocationService {
  private pathToCreateNewLocation = 'Location/CreateNewLocation';
  private pathToGetNewLocationForm = 'Location/GetNewLocationForm';
  private pathToValidateNewLocation = 'Location/ValidateNewLocation';

  async getNewLocationForm(): Promise<PostResponse<FormEntryPhase> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      const body = {
        sessionToken,
      } as SubmitPhaseRequest;

      const response = await apiService.post<SubmitPhaseRequest, FormEntryPhase>(this.pathToGetNewLocationForm, body);

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  async validateNewLocation(
    newValues: Array<FieldValue> | UserProperties,
    type: PhaseType,
    captchaToken: string,
  ): Promise<PostResponse<Array<ValidationError>> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      let userProperties = { ...newValues } as UserProperties;

      if (type === PhaseType.FORM) {
        userProperties = formHelperService.createPropertiesPayload(
          newValues as Array<FieldValue>,
          PhaseType.FORM,
        ) as UserPropertiesFormEntry;
      }

      const body = {
        captchaToken,
        userProperties,
        sessionToken,
      } as SubmitPhaseRequest;

      const response = await apiService.post<SubmitPhaseRequest, Array<ValidationError>>(
        this.pathToValidateNewLocation,
        body,
      );

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  async createNewLocation(
    newValues: Array<FieldValue> | UserProperties,
    type: PhaseType,
    captchaToken: string,
  ): Promise<PostResponse<PhaseResponse> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      let userProperties = { ...newValues } as UserProperties;

      if (type === PhaseType.FORM) {
        userProperties = formHelperService.createPropertiesPayload(
          newValues as Array<FieldValue>,
          PhaseType.FORM,
        ) as UserPropertiesFormEntry;
      }

      const body = {
        captchaToken,
        userProperties,
        sessionToken,
      } as SubmitPhaseRequest;

      const response = await apiService.post<SubmitPhaseRequest, PhaseResponse>(this.pathToCreateNewLocation, body);

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }
}

const locationService = new LocationService();

export type { ILocationService };
export { LocationService, locationService };
