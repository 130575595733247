import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import format from 'date-fns/format';

import CustomDivider from '../../themes/customDivider';
import { Business } from '../../types/enums/appState';
import { OneXThemePalette } from '../../types/enums/theme';

import styles from './index.module.css';


const year = format(new Date(), 'yyyy');

export const footerInformation = {
  [Business.GLOBAL]: {
    terms: 'https://www.globalpayments.com/terms-of-use',
    privacyPolicy: 'https://www.globalpayments.com/privacy-statement',
    statement: `©${year} Global Payments Inc. Global Payments Integrated is a service mark of Global Payments Inc.® All rights reserved.`,
  },
  [Business.HEARTLAND]: {
    terms: 'https://www.heartland.us/terms',
    privacyPolicy: 'https://www.heartland.us/privacy-policy',
    statement: `©${year} Heartland Payment Systems, LLC - a Global Payments company. All rights reserved.`,
  },
};

interface Props {
  business: Business;
}

const Footer: React.FC<Props> = ({ business }) => {
  const theme = useTheme<OneXThemePalette>();
  return (
    <Box
      className={styles.footerContainer}
      sx={{ backgroundColor: theme.palette.primary.bgPage + '!important' }}
      data-testid="defaultFooter"
      color="text.primary">
      {footerInformation[business].statement}
      <Box className={styles.links}>
        <Link href={footerInformation[business].terms} target={'_blank'} rel="noreferrer">
          Terms of Use
        </Link>{' '}
        |{' '}
        <Link href={footerInformation[business].privacyPolicy} target="_blank" rel="noreferrer">
          Privacy Policy
        </Link>
      </Box>
      <CustomDivider sx={{ width: '60%' }} />
      <Box className={styles.captchaContainer}>
        This site is protected by reCAPTCHA and the&nbsp;
        <Link target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">
          Google Privacy Policy
        </Link>
        &nbsp;and&nbsp;
        <Link target="_blank" rel="noreferrer" href="https://policies.google.com/terms">
          Google Terms of Service
        </Link>
        &nbsp;apply.
      </Box>
    </Box>
  );
};

export default Footer;
