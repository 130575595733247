/* eslint-disable no-undef */
import { datadogLogs } from '@datadog/browser-logs';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

import { currentClientRoutingModel } from '../api/index';
import { queryClient } from '../App';
import { productInterestSequence } from '../types/form';
import { PhaseEvent } from '../types/phase';
import { currentSessionContextService } from '../utility/sessionContext';

const history = createBrowserHistory();
const reactPlugin = new ReactPlugin();
export const notDefined = 'not defined';
const clientDDLoggerName = 'onDemandClientDDLogger';

export const aiConfig = new ApplicationInsights({
  config: {
    instrumentationKey: import.meta.env.VITE_APPINSIGHTS_ID ?? '',
    extensions: [reactPlugin],
    enableAutoRouteTracking: false,
    sessionRenewalMs: 3200000,
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
    autoTrackPageVisitTime: false,
  },
});

if (aiConfig.config.instrumentationKey.length) {
  aiConfig.loadAppInsights();

  //This disables tracking redundant page views - we are already tracking formPageLoads that execute the same
  aiConfig.addTelemetryInitializer((t) => {
    if (t.baseType === 'PageviewData') return false;
    else return true;
  });
}

if (import.meta.env.VITE_DD_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: import.meta.env.VITE_DD_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: import.meta.env.VITE_ENV,
    service: window.location.hostname,
  });

  datadogLogs.createLogger(clientDDLoggerName, {
    level: 'info',
    handler: 'http',
    context: { env: import.meta.env.VITE_ENV },
  });
}

export function trackEvent(phaseType, phase, event, customValues = {}, fieldValues = []) {
  try {
    const sessionData = queryClient.getQueryData(currentClientRoutingModel.clientRoutingAction);

    if (!phase && sessionData?.payload) {
      phase = sessionData.payload;
    }

    if (aiConfig.context) {
      aiConfig.context.user.id = sessionData?.sessionUid?.replaceAll('-', '').toUpperCase() ?? '';
    }

    let eventName = event;
    let products = null;

    const formPage = phase?.formPages?.[0];

    const htmlRegex = /<[^>]+>/g;
    const headerText = phase?.headerHtml
      ? phase?.headerHtml.replace(htmlRegex, '')
      : formPage?.questionGroups?.[0]?.headerHtml.replace(htmlRegex, '');

    const formPageName = headerText ?? notDefined;

    if (phase?.name != null) {
      if (phase?.name.indexOf(productInterestSequence) !== -1) {
        try {
          if (fieldValues.length > 0) products = fieldValues[0].value.join('|');
        } catch (e) {
          console.log(e);
        }
      }
    }

    let knownValues = createEventPayload(
      phase,
      eventName,
      formPageName,
      customValues,
      sessionData,
      products,
      currentSessionContextService.theme,
    );

    if (import.meta.env.VITE_TRACKING === 'TRUE') {
      try {
        if (import.meta.env.VITE_GTM) {
          dataLayer.push({ ...knownValues });
        }
        if (import.meta.env.VITE_GTM_LOG_DEBUG_EVENTS === 'TRUE') {
          console.log('DEBUG GTM EVENT', knownValues);
        }
      } catch {
        console.log('Could not post event to GTM');
      }
    }
    try {
      aiConfig.trackEvent({ name: eventName, properties: knownValues });
    } catch {
      console.log('Could not post event to appInsights');
    }
    try {
      if (import.meta.env.VITE_DD_CLIENT_TOKEN) {
        const clientDDLogger = datadogLogs.getLogger(clientDDLoggerName);
        if (eventName === PhaseEvent.ERROR) {
          clientDDLogger.error({ name: eventName, properties: knownValues });
        } else {
          clientDDLogger.info({ name: eventName, properties: knownValues });
        }
      }
    } catch {
      console.log('Could not post event to DataDog');
    }
  } catch (e) {
    console.log('Could not track event', e);
  }
}

export const createEventPayload = (phase, eventName, formPageName, customValues = {}, sessionData, products, theme) => {
  let result = {};
  if (eventName === PhaseEvent.SIGNATURE_AGREEMENT) {
    result = {
      type: customValues.type ?? notDefined,
      event: eventName,
    };
  } else if (eventName === PhaseEvent.FINISH) {
    result = {
      event: eventName,
      channel: sessionData?.sessionInfo?.channel ?? notDefined,
      subChannel: sessionData?.sessionInfo?.subChannel ?? notDefined,
      leadSource: sessionData?.sessionInfo?.leadSource ?? notDefined,
      sequence: phase?.statusPageType ?? notDefined,
      productInterest: products ?? sessionData?.sessionInfo?.productInterest ?? notDefined,
      previousChannel: sessionData?.sessionInfo?.previousChannel ?? notDefined,
      previousSubChannel: sessionData?.sessionInfo?.previousSubChannel ?? notDefined,
    };
  } else if (eventName === PhaseEvent.CONVERSION) {
    result = {
      event: eventName,
      channel: sessionData?.sessionInfo?.channel ?? notDefined,
      subChannel: sessionData?.sessionInfo?.subChannel ?? notDefined,
      leadSource: sessionData?.sessionInfo?.leadSource ?? notDefined,
      sequence: phase?.statusPageType ?? notDefined,
      productInterest: products ?? sessionData?.sessionInfo?.productInterest ?? notDefined,
      targetChannel: customValues.targetChannel ?? notDefined,
      targetSubchannel: customValues.targetSubChannel ?? notDefined,
    };
  } else if (eventName === PhaseEvent.EMAIL) {
    result = {
      event: eventName,
      email_address: customValues.email ?? notDefined,
    };
  } else if (eventName === PhaseEvent.EQUIPMENT_CHECKOUT || eventName === PhaseEvent.EQUIPMENT_VIEW_CART) {
    result = {
      event: eventName,
      equipment: customValues.cart ?? notDefined,
    };
  } else {
    result = {
      channel: sessionData?.sessionInfo?.channel ?? notDefined,
      event: eventName,
      formPageName: formPageName,
      leadSource: sessionData?.sessionInfo?.leadSource ?? notDefined,
      partner:
        sessionData?.sessionInfo?.partnerName != null
          ? sessionData?.sessionInfo?.partnerName
          : customValues.partnerName ?? notDefined,
      productInterest: products ?? sessionData?.sessionInfo?.productInterest ?? notDefined,
      sequence: phase?.name ?? notDefined,
      subChannel: sessionData?.sessionInfo?.subChannel ?? notDefined,
      theme: theme ?? notDefined,
    };
  }
  if (customValues.apiUrl) {
    result.apiUrl = customValues.apiUrl;
  }
  if (customValues.error) {
    result.error = customValues.error;
  }
  if (customValues.errorInfo) {
    result.errorInfo = customValues.errorInfo;
  }
  return result;
};

export function initializeUserTracking(sessionUid, affiliateId) {
  if (import.meta.env.VITE_TRACKING === 'TRUE') {
    try {
      if (sessionUid && affiliateId) {
        window.pendo.initialize({
          visitor: {
            id: sessionUid,
          },
          account: {
            id: affiliateId,
          },
        });
      } else {
        window.pendo.initialize({});
      }
    } catch {
      console.log('Could not initialize tracking in Pendo.');
    }
  }
}

const tracker = {
  trackEvent,
  initializeUserTracking,
};

export default tracker;
