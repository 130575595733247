import { useState } from 'react';

import { useMutation } from 'react-query';

import { phaseService } from '../services';
import { useSession } from '../session/hooks/useSession';
import { NextPhaseType } from '../types';
import { SESSION_ERROR_MESSAGE } from '../types/enums';
import { FieldValue } from '../types/form';
import { PhaseResponse, PhaseType } from '../types/phase';
import { CaptchaFunctions } from '../utility/captcha';
import ScrollToTop from '../utility/scrollToTop';

type UsePhaseProps = {
  isSubmittingPhase: boolean;
  isRewindingPhase: boolean;
  captchaToken: string;
  sessionErrorMessage?: SESSION_ERROR_MESSAGE;
  allowDelay: boolean;
  submitPhaseValuesWithErrorHandling: (newValues: any, type: PhaseType, error?: SESSION_ERROR_MESSAGE) => Promise<void>;
  handleRewind: () => Promise<void>;
  handleSessionChange: (response: PhaseResponse) => void;
  setCaptchaToken: (captchaToken: string) => void;
};

type SubmitVariables = {
  newValues: Array<FieldValue>;
  type: PhaseType;
};

function usePhase(): UsePhaseProps {
  const { session, phaseHistory, handleSessionAndPhase, setPhaseHistory } = useSession();
  const [nextPhaseType, setNextPhaseType] = useState<NextPhaseType | undefined>();

  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [sessionErrorMessage, setSessionErrorMessage] = useState<SESSION_ERROR_MESSAGE | undefined>();

  const handlePhaseSubmitMutation = (submitVariables: SubmitVariables) =>
    phaseService.submit(submitVariables.newValues, submitVariables.type, captchaToken);

  const phaseSubmitMutation = useMutation(handlePhaseSubmitMutation, {
    retry: 5,
  });

  const handlePhaseRewindMutation = () => phaseService.rewind();

  const phaseRewindMutation = useMutation(handlePhaseRewindMutation);

  const submitPhaseValuesWithErrorHandling = async (
    newValues: Array<FieldValue>,
    phaseType: PhaseType,
    error?: SESSION_ERROR_MESSAGE,
  ) => {
    if (error) {
      handleError(error);
    } else {
      await handleSubmit(newValues, phaseType);
    }
  };

  const handleSubmit = async (newValues: Array<FieldValue>, type: PhaseType) => {
    try {
      const nextPhaseTypeResponse = await phaseService.getNextPhaseType();

      setNextPhaseType(nextPhaseTypeResponse?.data);

      const newPhaseHistory = [...phaseHistory, nextPhaseTypeResponse?.data?.phaseType ?? PhaseType.INFO];

      const submitResponse = await phaseSubmitMutation.mutateAsync({ newValues, type });

      if (submitResponse?.error) {
        handleError(submitResponse.error as SESSION_ERROR_MESSAGE);
      } else if (submitResponse?.data) {
        handleSessionAndPhase(submitResponse.data, newPhaseHistory.length);

        setPhaseHistory(newPhaseHistory);
      }
    } catch (error) {
      console.error(error);

      handleError(error as SESSION_ERROR_MESSAGE);
    } finally {
      setCaptchaToken('');

      CaptchaFunctions.ResetCaptcha(session?.payload?.captchaType);

      ScrollToTop();
    }
  };

  const handleRewind = async () => {
    try {
      if (session?.payload?.allowRewind) {
        const rewindResponse = await phaseRewindMutation.mutateAsync();

        if (rewindResponse?.error) {
          handleError(rewindResponse.error as SESSION_ERROR_MESSAGE);
        } else if (rewindResponse?.data) {
          handleSessionAndPhase(rewindResponse.data, phaseHistory.length);
        }

        const history = [...phaseHistory];

        history.pop();

        setPhaseHistory(history);
      }
    } catch (error) {
      console.error(error);

      handleError(error as SESSION_ERROR_MESSAGE);
    } finally {
      setCaptchaToken('');

      CaptchaFunctions.ResetCaptcha(session?.payload?.captchaType);

      ScrollToTop();
    }
  };

  const handleError = (error: SESSION_ERROR_MESSAGE) => {
    setSessionErrorMessage(error);

    ScrollToTop();
  };

  const handleSessionChange = (response: PhaseResponse) => {
    if (response.err) {
      handleError(response.err as SESSION_ERROR_MESSAGE);
    } else {
      handleSessionAndPhase(response, phaseHistory.length);
    }
  };

  return {
    isSubmittingPhase: phaseSubmitMutation.isLoading,
    isRewindingPhase: phaseRewindMutation.isLoading,
    captchaToken,
    sessionErrorMessage,
    allowDelay: session?.payload?.phaseType !== nextPhaseType?.phaseType,
    submitPhaseValuesWithErrorHandling,
    handleRewind,
    handleSessionChange,
    setCaptchaToken,
  };
}

export { usePhase };
