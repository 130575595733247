export enum ValidationResults {
  EMAIL_FORMAT = 'invalid email format',
  MAX_LIMIT = 'longer than 500 characters',
  NO_OPTION_SELECTED = 'no option selected',
  ONLY_SPACE = 'contains only space',
  PHONE_NUMBER_FORMAT = 'invalid phone number',
  VALID = 'valid',
  ZIP_CODE_FORMAT = 'invalid zip code',
  NAME_FORMAT = 'invalid name format',
  ALPHA_NUMERIC_FORMAT = 'invalid character present',
  URL_FORMAT = 'invalid url',
  PO_BOX = 'P.O. Box Addresses are not allowed',
  DATE_FORMAT = 'invalid date',
  DATE_IN_FUTURE = 'date cannot be in the future',
  DATE_TOO_OLD = "date can't be before 01/01/1900",
  SSN_INVALID_LENGTH = 'should have 9 digits',
  SSN_INVALID_FIRST_SECTION = 'first 3 digits should not be 000, 666, or between 900 and 999',
  SSN_INVALID_SECOND_SECTION = 'middle 2 digits should be from 01 to 99',
  SSN_INVALID_THIRD_SECTION = 'last 4 digits and it should be from 0001 to 9999',
  NUMBER_TOO_LOW = 'value is too low',
}
