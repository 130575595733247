export function IconProgress() {
  return (
    <img
      src={`${import.meta.env.VITE_IMAGE_CDN}/status-page/icon-progress.svg`}
      alt="icon-progress"
      data-testid="icon-progress"
    />
  );
}

export function IconComplete() {
  return (
    <img
      src={`${import.meta.env.VITE_IMAGE_CDN}/status-page/icon-complete.svg`}
      alt="icon-complete"
      data-testid="icon-complete"
    />
  );
}

export function IconNext() {
  return (
    <img src={`${import.meta.env.VITE_IMAGE_CDN}/status-page/icon-next.svg`} alt="icon-next" data-testid="icon-next" />
  );
}
