import React from 'react';

import { Box, ClickAwayListener, useMediaQuery, useTheme } from '@mui/material';

import { StyledPopper } from '../../../themes/overrides';
import { OneXThemePalette } from '../../../types/enums/theme';
import { ProgressSegmentStatus, SegmentProps } from '../../../types/session';

import './index.css';

const PillTrackerSegment: React.FC<SegmentProps> = ({ segment }) => {
  const theme = useTheme<OneXThemePalette>();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [segmentColor, setSegmentColor] = React.useState('');

  const closeTimer: React.MutableRefObject<NodeJS.Timeout | undefined> = React.useRef(undefined);
  const closeTargetTime: React.MutableRefObject<Date | null> = React.useRef(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    cancelCloseTimer();
  };

  const handleClose = () => {
    setAnchorEl(null);
    cancelCloseTimer();
  };

  const handleClick = (event: any) => {
    if (anchorEl) handleClose();
    else handleOpen(event);
  };

  const handleMouseEnter = (event: any) => {
    if (!isMobile) handleOpen(event);
  };

  const handleMouseLeave = () => {
    if (!isMobile) startCloseTimer(250);
  };

  const cancelCloseTimer = () => {
    closeTargetTime.current = null;
    clearTimeout(closeTimer.current);
    closeTimer.current = undefined;
  };

  const startCloseTimer = (timeoutMS: number) => {
    var targetDate = new Date();
    targetDate.setMilliseconds(new Date().getMilliseconds() + timeoutMS);
    closeTargetTime.current = targetDate;

    if (!closeTimer.current) {
      closeTimer.current = setInterval(tryClose, 250);
    }
  };

  const tryClose = () => {
    //target time has elapsed
    if (closeTargetTime.current === null || closeTargetTime.current < new Date()) {
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  React.useMemo(() => {
    switch (segment.status) {
      case ProgressSegmentStatus.COMPLETED:
        /* Marketing wants completed to match in progress? */
        setSegmentColor(theme.palette.primary.accentPrimary7);
        break;
      case ProgressSegmentStatus.IN_PROGRESS:
        setSegmentColor(theme.palette.primary.accentPrimary7);
        break;
      default:
        setSegmentColor('rgba(128,128,128,0.15)');
        break;
    }
  }, [segment, theme]);

  return (
    <>
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement="top"
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box className="information-pane fade-in">
            <Box
              component="span"
              ref={setArrowRef}
              className="arrow"
              sx={{
                position: 'absolute',
                fontSize: 7,
                width: '1em',
                height: '1em',
                '&::before': {
                  content: '""',
                  margin: 'auto',
                  display: 'block',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                },
              }}
            />
            <Box
              sx={{
                padding: '0.5rem 0.5rem',
                border: `1px solid ${theme.palette.primary.paperHover}`,
                borderRadius: '5px',
                fontSize: '14px',
                backgroundColor: theme.palette.primary.bgPrimary,
                color: theme.palette.text.primary,
              }}>
              {segment.name}
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
      {/* Span to ward off div child selector */}
      <Box
        component="span"
        className="progress-segment"
        onClick={(e: any) => handleClick(e)}
        onMouseEnter={(e: any) => handleMouseEnter(e)}
        onMouseLeave={(e: any) => handleMouseLeave()}
        sx={{
          backgroundColor: segmentColor,
        }}></Box>
    </>
  );
};

export default PillTrackerSegment;
