import { useLayoutEffect, useTransition } from 'react';

import { Layout, PrivacyPolicy } from './components';
import { ValidationErrorList } from './components/ValidationErrorList';
import { useForm } from '../../hooks';
import LayoutCard from '../../layout/card';
import ProgressTracker from '../../layout/progressTracker';
import ButtonFooter from '../../layout/sharedButtonFooter';
import { useSession } from '../../session/hooks/useSession';
import { LOADING_MESSAGE, MODAL_PHASE_NAME } from '../../types/enums/appState';
import { BackButtonText, FormEntryPhase, PhaseProps, TrackerType } from '../../types/phase';
import BackButtonHandler from '../../utility/backButtonHandler';
import Loading from '../../utility/loading';
import './index.css';

const privacyHeight = 170;
const containerId = 'main';

const Form = ({ submit, rewind, isRewind }: PhaseProps) => {
  const { session, showApplicationOpenAnotherTab, setLoadedPhase } = useSession();
  const phase = session?.payload as FormEntryPhase;

  const {
    state,
    showPrivacy,
    showDataLossWarning,
    isLoading,
    isSubmitting,
    allowBack,
    showTracker,
    captchaType,
    trackerType,
    submitButtonText,
    cachedGroups,
    onFieldChange,
    handleBackClick,
    handleContinueClick,
    errorListMessages,
    handleLocationModalContinueClick,
    handleClearElement,
    onSensitiveUnlock,
    setPrivacyAgreement,
    setCachedGroups,
  } = useForm({
    submit,
    rewind,
    isRewind,
    phase,
    showApplicationOpenAnotherTab,
    setLoadedPhase,
    onDemandConfig: session?.onDemandConfig,
  });

  const [, startFormTransition] = useTransition();

  useLayoutEffect(() => {
    if (state.element) {
      const rect = state.element.getBoundingClientRect();
      const scrollPos = window.scrollY ?? 0;
      //Top position of the rectangle (negative) plus the current scroll position, minus 30% of the static window header height.
      //This is to take into account the heights of any of the focused form elements regardless of type.
      window.scrollTo({
        top: rect.top + scrollPos - window.innerHeight * 0.3,
        left: 0,
        behavior: 'smooth',
      });

      handleClearElement();
    }
  }, [handleClearElement, state.element]);

  const getLoadingComponent = () => {
    if (state.loading) return <Loading message={LOADING_MESSAGE.LOADING} />;
    return null;
  };

  const handleFieldChange = (questionId: number, newValue: any, shouldValidate: boolean, confirmationValue?: any) => {
    startFormTransition(() => onFieldChange(questionId, newValue, shouldValidate, confirmationValue));
  };

  return (
    <>
      <BackButtonHandler
        location={0}
        setLocation={() => handleBackClick()}
        allowRewind={allowBack}
        unloadWarning={showDataLossWarning && !showApplicationOpenAnotherTab}
      />

      {getLoadingComponent()}
      {!isLoading && state.phase != null && state.phase.name !== MODAL_PHASE_NAME.GPI_MULTILOCATION && (
        <LayoutCard>
          <Layout
            formQuestionGroups={(state.phase as FormEntryPhase).questionGroups}
            cachedGroups={cachedGroups}
            setHeight={() => {}}
            setCachedGroups={setCachedGroups}
            name={state.phase.name}
            fieldValues={state.fieldValues}
            onFieldChange={handleFieldChange}
            onSensitiveUnlock={onSensitiveUnlock}
            headerHtml={state.phase.headerHtml ?? ''}
            infoHtml={state.phase.infoHtml ?? ''}
            //Add this back in when needed
            //footerHtml={state.page.footerHtml}
            showRequiredText={state.showRequiredText}
          />

          {showPrivacy && (
            <PrivacyPolicy
              customText={state.privacyText ?? ''}
              checked={state.privacyAgreement}
              onClick={setPrivacyAgreement}
            />
          )}

          <ButtonFooter
            showBack={allowBack ?? false}
            showContinue={true}
            continueButtonText={submitButtonText}
            backButtonText={BackButtonText}
            captchaType={captchaType}
            isDisabledContinue={!state.privacyAgreement}
            onBack={handleBackClick}
            onContinue={handleContinueClick}
            isLoadingBack={state.rewinding}
            isLoadingContinue={isSubmitting}>
            <ProgressTracker showTracker={showTracker} type={trackerType} onlyShow={TrackerType.PILL} />
          </ButtonFooter>
        </LayoutCard>
      )}

      {!isLoading && state.phase != null && state.phase.name === MODAL_PHASE_NAME.GPI_MULTILOCATION && (
        <>
          <Layout
            formQuestionGroups={(state.phase as FormEntryPhase).questionGroups}
            cachedGroups={cachedGroups}
            setHeight={() => {}}
            setCachedGroups={setCachedGroups}
            name={state.phase.name}
            fieldValues={state.fieldValues}
            onFieldChange={handleFieldChange}
            onSensitiveUnlock={onSensitiveUnlock}
            headerHtml={state.phase.headerHtml ?? ''}
            infoHtml={state.phase.infoHtml ?? ''}
            //Add this back in when needed
            //footerHtml={state.page.footerHtml}
            showRequiredText={state.showRequiredText}
          />

          {showPrivacy && (
            <PrivacyPolicy
              customText={state.privacyText ?? ''}
              checked={state.privacyAgreement}
              onClick={setPrivacyAgreement}
            />
          )}

          {errorListMessages.length > 0 && <ValidationErrorList errorMessage={errorListMessages} />}

          <ButtonFooter
            showBack={true}
            showContinue={true}
            continueButtonText={'Save and Continue'}
            backButtonText={'Cancel'}
            captchaType={captchaType}
            isDisabledContinue={false}
            onBack={rewind}
            onContinue={handleLocationModalContinueClick}
            isLoadingBack={state.rewinding}
            isLoadingContinue={isSubmitting}>
            <ProgressTracker showTracker={showTracker} type={trackerType} onlyShow={TrackerType.PILL} />
          </ButtonFooter>
        </>
      )}
    </>
  );
};

export default Form;
export { privacyHeight, containerId };
