import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { HeartlandErrorText } from '../../themes/overrides';
import { CaptchaType } from '../../types/phase';

import styles from './index.module.css';

export const CaptchaCheckboxId = 'checkboxCaptcha';
const ErrorMessage = 'Please fill out reCaptcha to continue.';

interface CaptchaVerifyProps {
  captchaType: CaptchaType;
  errorMessage?: string;
}
export const CaptchaVerify: React.FC<CaptchaVerifyProps> = ({ captchaType, errorMessage = '' }) => {
  const captchaContext = useContext(CaptchaContext);
  if (captchaType === CaptchaType.CHECKBOX) {
    return (
      <Box className={styles.checkbox}>
        <ReCaptchaCheckbox
          onVerifyCallback={async (token: string) => {
            captchaContext.setToken(token);
          }}
          onExpiredCallback={async () => {
            captchaContext.setToken('');
          }}
        />
        {errorMessage && <HeartlandErrorText>{errorMessage}</HeartlandErrorText>}
      </Box>
    );
  }

  return <ReCaptchaSilent />;
};

const useCaptcha = (captchaType: CaptchaType, onContinue: () => void) => {
  const [error, setError] = useState<string>('');
  const captchaContext = useContext(CaptchaContext);

  const handleContinue = async () => {
    setError('');

    if (captchaType === CaptchaType.CHECKBOX) {
      if (!isEmpty(captchaContext.token)) {
        onContinue();
      } else {
        setError(ErrorMessage);
      }
    } else {
      await CaptchaFunctions.GetReCaptchaSilentToken().then((token) => {
        captchaContext.setToken(token);
        if (!isEmpty(token)) onContinue();
      });
    }
  };

  return { error, handleContinue };
};

export const ReCaptchaSilent = () => {
  return (
    <>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${String(
            import.meta.env.VITE_CAPTCHA_SILENT_KEY,
          )}`}
        />
      </Helmet>
    </>
  );
};

declare global {
  interface Window {
    grecaptcha: any;
    onCheckboxLoadCallback: any;
  }
}

interface CheckboxProps {
  onVerifyCallback: (token: string) => void;
  onExpiredCallback: () => void;
}

export const ReCaptchaCheckbox: React.FC<CheckboxProps> = ({ onVerifyCallback, onExpiredCallback }) => {
  useEffect(() => {
    window.onCheckboxLoadCallback = function () {
      window.grecaptcha.enterprise.render(CaptchaCheckboxId, {
        sitekey: String(import.meta.env.VITE_CAPTCHA_CHECKBOX_KEY),
        callback: onVerifyCallback,
        'expired-callback': onExpiredCallback,
        theme: 'light',
      });
    };

    return () => {
      window.onCheckboxLoadCallback = undefined;
    };
  }, [onVerifyCallback, onExpiredCallback]);

  return (
    <>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/enterprise.js?&onload=onCheckboxLoadCallback&render=explicit"
          async
          defer
        />
      </Helmet>
      <div id={CaptchaCheckboxId} data-testid={CaptchaCheckboxId} />
    </>
  );
};

const GetReCaptchaSilentToken = async (): Promise<string> => {
  if (window.grecaptcha?.enterprise?.execute) {
    const token: string = await window.grecaptcha.enterprise.execute(String(import.meta.env.VITE_CAPTCHA_SILENT_KEY), {
      action: 'SUBMIT_PHASE',
    });
    return token;
  }
  return '';
};

const ResetCaptcha = (type: CaptchaType | undefined) => {
  if (type === CaptchaType.CHECKBOX) {
    window.grecaptcha.enterprise.reset(0);
  }
};

export const CaptchaFunctions = {
  GetReCaptchaSilentToken,
  ResetCaptcha,
};

export default useCaptcha;

export interface CaptchaContextInterface {
  token: string;
  setToken: (token: string) => void;
}

export const CaptchaContext = React.createContext<CaptchaContextInterface>({
  token: '',
  setToken: (token: string) => {},
});
