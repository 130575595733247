import { Box, useTheme } from '@mui/material';

import { UserClientSessionStatus } from '../..';
import { OneXThemePalette } from '../../../../types/enums/theme';
import { IconComplete, IconNext, IconProgress } from '../../../../utility/statusPageIcons';

import style from './index.module.css';

type Props = {
  status: UserClientSessionStatus;
};

export function UserSessionCardStatus({ status }: Props) {
  const theme = useTheme<OneXThemePalette>();
  const { textNext, textProgress, textComplete } = theme.palette.primary;

  let icon = <IconNext />;
  let color = textNext;

  if ([UserClientSessionStatus.IN_PROGRESS, UserClientSessionStatus.ABOUT_TO_EXPIRE].includes(status)) {
    color = textProgress;
    icon = <IconProgress />;
  } else if (status === UserClientSessionStatus.COMPLETED) {
    color = textComplete;
    icon = <IconComplete />;
  }

  return (
    <Box className={style.container} color={color}>
      <Box className={style.iconWrapper}>{icon}</Box> {status}
    </Box>
  );
}
