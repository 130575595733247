import { RefreshClientTokenError } from './errors';
import { Attribution } from '../types/attribution';
import { ERROR_MESSAGE } from '../types/enums/appState';
import { userClientContextService } from '../utility/userClientContext';

import { endpoints } from '.';

export interface UserClient {
  userClientUid: string;
  clientToken: string;
  refreshToken: string;
  clientSessionCount: number;
  attributions?: Array<Attribution>;
}

const getDefaultHeaders = (headers: any = {}): any => {
  if (!headers) {
    headers = {};
  }

  if (Object.keys(headers).length === 0) {
    headers['Content-Type'] = 'application/json';
  }

  const userClientStore = userClientContextService.currentUserClient;

  if (userClientStore?.clientToken) {
    headers['X-Client-Token'] = userClientStore?.clientToken;
  }

  return headers;
};

const refreshToken = async (): Promise<UserClient | undefined> => {
  let userClient = userClientContextService.currentUserClient;

  try {
    const body = JSON.stringify(userClient);
    const newClientTokenResponse = await fetch(endpoints.LOAD_USER_CLIENT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (newClientTokenResponse.ok) {
      const data = await newClientTokenResponse.json().catch(() => userClient ?? {});

      userClientContextService.pushUserAttributions(data.attributions);

      userClientContextService.currentUserClient = data;

      userClient = userClientContextService.currentUserClient;
    } else {
      const nonOkText = await newClientTokenResponse.text();
      const statusCode = newClientTokenResponse.status;

      throw new RefreshClientTokenError(ERROR_MESSAGE.UNAUTHORIZED, nonOkText, statusCode);
    }
  } catch (error) {
    if (error instanceof RefreshClientTokenError) {
      throw error;
    } else {
      console.error(error);

      throw new RefreshClientTokenError(ERROR_MESSAGE.ERROR);
    }
  }

  return userClient;
};

const handleAuthorizationIntercept = async (
  response: Response,
  originalRequestConfig: RequestInit,
): Promise<Response> => {
  if (response.status === 401) {
    await refreshToken();

    const retryResponse = await fetch(response.url, {
      ...originalRequestConfig,
      headers: { ...originalRequestConfig.headers, ...getDefaultHeaders() },
    });

    return retryResponse;
  }

  return response;
};

const get = async (url: string, headers?: any): Promise<Response> => {
  const config = {
    headers: getDefaultHeaders(headers),
  };
  const response = await fetch(url, config);

  return handleAuthorizationIntercept(response, config);
};

const post = async (url: string, data: any, headers: any = {}): Promise<Response> => {
  const config = {
    method: 'POST',
    headers: getDefaultHeaders(headers),
    body: JSON.stringify(data),
  };

  const response = await fetch(url, config);

  return handleAuthorizationIntercept(response, config);
};

export { getDefaultHeaders, post, get, refreshToken, handleAuthorizationIntercept };
