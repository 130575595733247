import React, { useState } from 'react';

import { IMask, IMaskInput } from 'react-imask';
import InputMask from 'react-input-mask';

import { TextMaskTypes } from '../../types/enums/FormFieldTypes';

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
  name: string;
  alwaysShowMask: boolean;
  maskChar: string;
  maskType: TextMaskTypes;
  type: string;
  component?: any;
}

const MaskedTextInput = React.forwardRef<HTMLElement, CustomProps>(function MaskedInput(props, ref) {
  const [pastedValue, setPastedValue] = useState('');

  const { component: inputMask, alwaysShowMask, maskChar, maskType, type, onChange, ...other } = props;
  let mask = '';
  let maskOption = {};
  let applySpacing = false;
  let definitions = {};
  let placeholder = '';

  switch (maskType) {
    case TextMaskTypes.PHONE:
      mask = '(#__) ___-____';
      applySpacing = true;
      placeholder = '(000) 000-0000';
      maskOption = { numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ['(', ') ', '-'] };
      definitions = { '#': /[1-9]/, _: /[0-9]/ };
      break;
    case TextMaskTypes.ZIPCODE:
      mask = '_____';
      applySpacing = true;
      placeholder = '00000';
      maskOption = { numericOnly: true, blocks: [5] };
      definitions = { _: /[0-9]/ };
      break;
    case TextMaskTypes.PERCENT:
      mask = '100%';
      maskOption = { numericOnly: true, blocks: [3], delimiters: ['%'] };
      break;
    case TextMaskTypes.TAX_ID:
      mask = '9_-_______';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maskOption = { numericOnly: true, blocks: [2, 7], delimiter: '-' };
      break;
    case TextMaskTypes.SSN:
      mask = '___-__-____';
      applySpacing = true;
      placeholder = '000-00-0000';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maskOption = { numericOnly: true, blocks: [4, 7], delimiters: ['-'] };
      definitions = { _: /[0-9]/ };
      break;
    default:
      break;
  }

  const applyMask = (value: any) => {
    const maskOptions = {
      mask,
      placeholder,
      maskOption,
      definitions,
    };

    const masked = IMask.createMask(maskOptions);

    masked.resolve(value);

    return masked.value;
  };

  const mode =
    maskType === TextMaskTypes.ZIPCODE || maskType === TextMaskTypes.PERCENT
      ? 'numeric'
      : maskType === TextMaskTypes.EMAIL
        ? 'email'
        : maskType === TextMaskTypes.PHONE
          ? 'tel'
          : 'text';

  const handleChange = (value: string) => {
    if (maskType === TextMaskTypes.PHONE && pastedValue.length > 0) {
      value = pastedValue;
      setPastedValue('');
    }

    onChange({ target: { value } });
  };

  const handlePaste = (value: string) => {
    if (maskType === TextMaskTypes.PHONE && value?.includes('+')) {
      setPastedValue(applyMask(value.split('+')[1]));
    }
  };

  return applySpacing ? (
    <IMaskInput
      {...other}
      mask={mask}
      placeholder={placeholder}
      inputMode={mode as any}
      type={mode}
      definitions={definitions}
      inputRef={ref as any}
      onAccept={(value: any) => handleChange(value)}
      onInputCapture={(e) => handlePaste((e.target as any)?.value)}
      onPaste={(e) => handlePaste(e.clipboardData.getData('text'))}
      overwrite
    />
  ) : (
    <InputMask
      {...other}
      type={mode}
      inputRef={ref as any}
      inputMode={mode as any}
      onChange={(e) => handleChange(e.target.value)}
      onInputCapture={(e) => handlePaste((e.target as any)?.value)}
      onPaste={(e) => handlePaste(e.clipboardData.getData('text'))}
      mask={mask}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
    />
  );
});

export default MaskedTextInput;
