import { apiFunctions } from '..';
import { ReferenceDataTypes } from '../../types/enums/ReferenceDataTypes';
import { JsonDocumentResponse } from '../../types/form';

export async function getReferenceData(dataType: ReferenceDataTypes) {
  return await apiFunctions.apiPost('/ReferenceData/GetReferenceData', { ReferenceDataType: dataType });
}

export async function getJsonReferenceData(data: string): Promise<JsonDocumentResponse> {
  return await apiFunctions.apiGet(`/ReferenceData/GetJsonReferenceData/${data}`);
}
