import { detectIncognito } from 'detectincognitojs';

import { DisabledTrackingModel } from '../../types/tracking';

export interface ITrackingDetectionUtility {
  checkTrackingDisabled: () => Promise<DisabledTrackingModel>;
  userIsIncognito: () => Promise<boolean>;
  userIsBlockingGtm: () => Promise<boolean>;
}

class TrackingDetectionUtility implements ITrackingDetectionUtility {
  readonly incognito: string = 'Browser incognito mode.';
  readonly blockerGlobals: string = 'Extension blocking GA/GTM Global Objects.';
  readonly blockerGaData: string = 'Extension blocking GA/GTM Data Collection.';
  readonly blockerScriptEndpoint: string = 'Extension blocking GA/GTM Script Endpoint.';
  readonly scriptDisabled: string = `Script not injected in ${import.meta.env.VITE_ENV} environment.`;
  readonly gtmScriptEndpoint = 'https://www.googletagmanager.com/gtm.js?id=GTM-TV6XJB9'; //GTM account Id must match script in env

  message?: string;

  public userIsBlockingGtm = async (): Promise<boolean> => {
    let isBlocked = false;
    let w = window as any;

    //Check for injected GA Objects from GTM script
    if (w.GoogleAnalyticsObject && w.google_tag_manager) {
      //Check if user has opted out of GA tracking via Extension
      if (Object.keys(w.gaData).length === 0) {
        isBlocked = true;
        this.message = this.blockerGaData;
      } else {
        //Check if endpoint is blocked by extension
        try {
          await fetch(this.gtmScriptEndpoint);
        } catch (e: any) {
          //If this fails then Client is being blocked from GA/GTM traffic
          isBlocked = true;
          this.message = this.blockerScriptEndpoint;
        }
      }

      return false;
    } else {
      //Global objects missing from DOM, so GA/GTM is being blocked globally
      isBlocked = true;
      this.message = this.blockerGlobals;
    }

    return isBlocked;
  };

  public userIsIncognito = async (): Promise<boolean> => {
    let result = false;
    try {
      result = (await detectIncognito()).isPrivate;
    } catch (e: any) {
      //If this fails then depend on direct GTM check as a fallback
    }
    return result;
  };

  public checkTrackingDisabled = async (): Promise<DisabledTrackingModel> => {
    let model: DisabledTrackingModel = {
      allowsTracking: false,
      disabledTrackingMessage: undefined,
    };

    if (!import.meta.env.VITE_GTM || import.meta.env.VITE_GTM === '') {
      this.message = this.scriptDisabled;
    } else if (await this.userIsIncognito()) {
      this.message = this.incognito;
    } else {
      await this.userIsBlockingGtm();
    }

    model.allowsTracking = this.message == null;
    model.disabledTrackingMessage = this.message;

    return model;
  };
}

export const trackingDetectionUtility: ITrackingDetectionUtility = new TrackingDetectionUtility();
