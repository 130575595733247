import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';

import { Information } from '../..';
import { HeartlandErrorText, HeartlandRadioLabel } from '../../../../../themes/overrides';
import { FieldValue, FormFieldOption, QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  label?: string;
  value: FieldValue;
  options: Array<FormFieldOption>;
}

const KVPRadio: React.FC<Props> = ({
  fieldDefinitionJson,
  helperText,
  label,
  onChange,
  options,
  value,
  infoHtml,
  fieldType,
  fieldSubType,
  hideLabel = false,
}) => {
  const handleClick = (event: any) => {
    if (value.isRequired) {
      onChange(value.questionId, event.target.value, true, '');
    } else {
      if (event.target.value === value.value) {
        onChange(value.questionId, '', true, '');
      } else {
        onChange(value.questionId, event.target.value, true, '');
      }
    }
  };
  return (
    <FormControl fullWidth>
      {label && !hideLabel && (
        <HeartlandRadioLabel
          shrink
          htmlFor={fieldDefinitionJson?.Properties[0].PropertyKey}
          required={value.isRequired}>
          {label} {infoHtml && <Information infoHtml={infoHtml} />}
        </HeartlandRadioLabel>
      )}
      <RadioGroup value={value.value} id={fieldDefinitionJson?.Properties[0].PropertyKey}>
        {options
          .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
          .map((x) => {
            return (
              <FormControlLabel
                classes={{ label: 'radio-label' }}
                color="text.secondary"
                key={x.id}
                id={fieldDefinitionJson?.Properties[0].PropertyKey + '_' + x.value}
                value={x.value}
                label={
                  <>
                    {x.label}
                    {x.infoHtml && <Information infoHtml={x.infoHtml} />}
                  </>
                }
                control={
                  <Radio
                    color="primary"
                    onClick={handleClick}
                    inputProps={{
                      ...formHelperService.getCustomInputProps(
                        fieldType,
                        fieldDefinitionJson?.Properties[0].PropertyKey,
                        x.label,
                        fieldSubType,
                      ),
                    }}
                  />
                }
                sx={{
                  width: 'fit-content',
                }}
              />
            );
          })}
      </RadioGroup>
      {value.validationErrorText && <HeartlandErrorText>{value.validationErrorText}</HeartlandErrorText>}
      {!isEmpty(helperText) && <FormHelperText dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>}
    </FormControl>
  );
};

export { KVPRadio };
