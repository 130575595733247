import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';

import { OneXThemePalette } from '../../types/enums/theme';
import { CaptchaType } from '../../types/phase';
import useCaptcha, { CaptchaVerify } from '../../utility/captcha';
import { getIcon } from '../../utility/icon';
import { getOnDemandCustomElementProps } from '../../utility/screenUtils';

import styles from './index.module.css';

interface Props {
  children?: React.ReactNode;
  captchaType: CaptchaType | undefined;
  isLoadingContinue: boolean;
  isLoadingBack: boolean;
  isLoadingLink?: boolean;
  isDisabledContinue?: boolean;
  isDisabledBack?: boolean;
  isDisabledLink?: boolean;
  showBack: boolean;
  showContinue: boolean;
  showLink?: boolean;
  backButtonText: string;
  continueButtonText: string;
  continueTestId?: string;
  linkButtonText?: string;
  linkButtonTestId?: string;
  backTestId?: string;
  width?: string;
  onBack: () => void;
  onContinue: () => void;
  onLinkClick?: () => void;
}

enum ThrottlingState {
  FREE = 'Free',
  CONTINUE = 'Continue',
  BACK = 'Back',
  LINK = 'Link',
}

const ButtonFooter: React.FC<Props> = ({
  children,
  captchaType = CaptchaType.SILENT,
  isLoadingContinue,
  isLoadingBack,
  isLoadingLink,
  isDisabledContinue,
  isDisabledBack,
  isDisabledLink = false,
  showBack,
  showContinue,
  showLink,
  backButtonText,
  continueButtonText,
  continueTestId,
  linkButtonText,
  linkButtonTestId,
  backTestId,
  width = '75%',
  onBack,
  onContinue,
  onLinkClick,
}) => {
  const theme = useTheme<OneXThemePalette>();
  const { error, handleContinue } = useCaptcha(captchaType, onContinue);
  const { error: linkError, handleContinue: handleLink } = useCaptcha(
    captchaType,
    onLinkClick ? onLinkClick : onContinue,
  );
  const [throttling, setThrottling] = useState<ThrottlingState>(ThrottlingState.FREE);

  const continueSx = {
    visibility: showContinue ? 'visible' : 'hidden',
  };
  const backSx = {
    visibility: showBack ? 'visible' : 'hidden',
    order: showBack ? 2 : 3,
  };

  const isLoadingSomething = isLoadingContinue || isLoadingBack || isLoadingLink || throttling !== ThrottlingState.FREE;

  const handleContinueClick = debounce(
    async () => {
      if (showContinue && !isDisabledContinue && !isLoadingSomething) {
        setThrottling(ThrottlingState.CONTINUE);
        await handleContinue();
        setThrottling(ThrottlingState.FREE);
      }
    },
    1000,
    { leading: true, trailing: false },
  );

  const handleBackClick = debounce(
    () => {
      if (showBack && !isDisabledBack && !isLoadingSomething) {
        setThrottling(ThrottlingState.BACK);
        onBack();
        setThrottling(ThrottlingState.FREE);
      }
    },
    1000,
    { leading: true, trailing: false },
  );

  const handleLinkClick = debounce(
    async () => {
      if (showLink && !isDisabledLink && !isLoadingSomething) {
        setThrottling(ThrottlingState.LINK);
        await handleLink();
        setThrottling(ThrottlingState.FREE);
      }
    },
    1000,
    { leading: true, trailing: false },
  );

  return (
    <>
      <CaptchaVerify captchaType={captchaType} errorMessage={error || linkError} />
      <Box className={styles.buttonFooter} sx={{ width: width }} data-testid={'buttonFooter'}>
        <Box className={styles.continueButtons}>
          {showLink && (
            <Link
              id="btnLink"
              data-testid={linkButtonTestId ?? 'btnLink'}
              onClick={handleLinkClick}
              sx={{ marginRight: '2rem' }}>
              {`${linkButtonText} `}
              {isLoadingLink || throttling === ThrottlingState.LINK ? (
                <CircularProgress
                  data-testid={`${linkButtonTestId ?? 'btnLink'}Loading`}
                  sx={{ alignSelf: 'center' }}
                  size={20}
                  color="primary"
                />
              ) : (
                '>'
              )}
            </Link>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            id="btnContinue"
            data-testid={continueTestId ?? 'btnContinue'}
            sx={
              isLoadingContinue || throttling === ThrottlingState.CONTINUE
                ? {
                    ...continueSx,
                    backgroundColor: theme.palette.primary.inputBorderDisabled + ' !important',
                  }
                : continueSx
            }
            endIcon={<FontAwesomeIcon className="button-icon" icon={getIcon('faChevronRight')} />}
            disabled={isDisabledContinue || isLoadingSomething}
            loading={isLoadingContinue || throttling === ThrottlingState.CONTINUE}
            onClick={handleContinueClick}
            {...getOnDemandCustomElementProps('btnContinue', continueButtonText)}>
            {!(isLoadingContinue || throttling === ThrottlingState.CONTINUE) && continueButtonText}
          </LoadingButton>
        </Box>
        {children}
        <LoadingButton
          variant="outlined"
          color="primary"
          id="btnBack"
          data-testid={backTestId ?? 'btnBack'}
          disabled={isDisabledBack || isLoadingSomething}
          loading={isLoadingBack || throttling === ThrottlingState.BACK}
          onClick={handleBackClick}
          sx={backSx}
          startIcon={<FontAwesomeIcon className="button-icon" icon={getIcon('faChevronLeft')} />}
          {...getOnDemandCustomElementProps('btnBack', backButtonText)}>
          {!(isLoadingBack || throttling === ThrottlingState.BACK) && backButtonText}
        </LoadingButton>
      </Box>
    </>
  );
};

export default ButtonFooter;
