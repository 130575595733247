import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { VerifyMessages } from '../../types/enums/verifyMessages';
import { Session } from '../../types/session';
import { parseResumeSessionScope } from '../../utility';
import ResumeVerificationCodeForm from '../resumeSession/form';

import './index.css';

type ReturnProps = {
  requestResumeSession: RequestResumeSession;
  onReturn: Function;
  showStartNewApplication?: boolean;
  onErrorHappened?: (error: VerifyMessages) => void;
  onStartNewApplication?: () => void;
};

export interface RequestResumeSession {
  Scope: string;
  OnDemandConfigId?: number;
  AAID?: string;
  TargetSessionUid?: string;
  Email?: string;
}

const Return = ({
  requestResumeSession,
  onReturn,
  showStartNewApplication,
  onErrorHappened,
  onStartNewApplication,
}: ReturnProps) => {
  const [submitTimeout, setSubmitTimeout] = useState<boolean>(false);
  const [timeoutCounter, setTimeoutCounter] = useState(+(import.meta.env.VITE_VERIFICATION_REQUEST_DELAY || 0));
  const [attempts, setAttempts] = useState<number>(0);
  const [preventAttempt, setPreventAttempt] = useState<boolean>(false);

  useEffect(() => {
    if (attempts === +(import.meta.env.VITE_MAX_VERIFICATION_CODE_ATTEMPT || 0)) {
      setPreventAttempt(true);
    }
  }, [attempts]);

  useEffect(() => {
    if (submitTimeout) {
      let timeout = setTimeout(() => {
        setTimeoutCounter(timeoutCounter - 1);
        if (timeoutCounter === 0) {
          setSubmitTimeout(false);
        }
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setTimeoutCounter(+(import.meta.env.VITE_VERIFICATION_REQUEST_DELAY || 0));
    }
  }, [submitTimeout, timeoutCounter]);

  let resumeScope = parseResumeSessionScope(requestResumeSession.Scope);
  let sessionModel = {
    sessionUid: requestResumeSession.TargetSessionUid,
    onDemandConfig: { id: requestResumeSession.OnDemandConfigId, configName: '' },
    sessionInfo: {
      aaid: requestResumeSession.AAID,
      email: requestResumeSession.Email,
    },
  };

  return (
    <Box style={{ height: '60vh' }}>
      <Box className="return-verification-wrapper" color="text.primary">
        <Box className="return-verification-static-modal" bgcolor="primary.bgPrimary">
          <ResumeVerificationCodeForm
            resumeScope={resumeScope}
            userSession={sessionModel as Session}
            setSubmitTimeout={setSubmitTimeout}
            submitTimeout={submitTimeout}
            timeoutCounter={timeoutCounter}
            setAttempts={setAttempts}
            attempts={attempts}
            preventAttempt={preventAttempt}
            onSuccess={(response) => onReturn(response)}
            showStartNewApplication={showStartNewApplication}
            onErrorHappened={onErrorHappened}
            onStartNewApplication={onStartNewApplication}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Return;
