import { authorizationService } from '../features/authorization/services';
import { PostResponse, PostResponseBlob } from '../types';
import { SESSION_ERROR_MESSAGE } from '../types/enums';
import { ERROR_MESSAGE } from '../types/enums/appState';
import { PhaseEvent } from '../types/phase';
import { trackEvent } from '../utility/eventTracking';

const baseUrl = import.meta.env.VITE_API_URL ?? '';
const FATAL_STATUS_ERROR = -10000;

interface IApiService {
  post<TRequest, TResponse>(
    url: string,
    body?: TRequest,
    headers?: Record<string, string>,
  ): Promise<PostResponse<TResponse>>;
  postBlob<TRequest>(
    url: string,
    body?: TRequest,
    headers?: Record<string, string>,
  ): Promise<PostResponse<PostResponseBlob>>;
}

class ApiService implements IApiService {
  async post<TRequest, TResponse>(
    url: string,
    body?: TRequest,
    headers: Record<string, string> = {},
  ): Promise<PostResponse<TResponse>> {
    try {
      const response = await authorizationService.post(`${baseUrl}/${url}`, body, headers);

      const result = await this.getResult<TResponse>(response);

      return result;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  async postBlob<T>(
    url: string,
    body?: T,
    headers: Record<string, string> = {},
  ): Promise<PostResponse<PostResponseBlob>> {
    try {
      const response = await authorizationService.post(`${baseUrl}/${url}`, body, headers);

      const result = await this.getResult<PostResponseBlob>(response, true);

      return result;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  async get<TResponse>(url: string, headers: Record<string, string> = {}): Promise<PostResponse<TResponse>> {
    try {
      const response = await authorizationService.get(`${baseUrl}/${url}`, headers);

      const result = await this.getResult<TResponse>(response);

      return result;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  private async getResult<R>(response: Response, fromBlob?: boolean): Promise<PostResponse<R>> {
    const result: PostResponse<R> = { status: 0 };

    result.status = response.status;

    switch (result.status) {
      case 200:
        if (fromBlob) {
          result.data = {
            fileName: this.getFileName(response.headers.get('content-disposition') ?? ''),
            blob: await response.blob(),
          } as R;
        } else {
          result.data = (await response.json()) as R;
        }
        break;
      case 401:
        result.error = ERROR_MESSAGE.UNAUTHORIZED;
        break;
      case 204:
        break;
      default:
        const responseText = await response.text();

        result.errorText = responseText;

        if (responseText.includes(ERROR_MESSAGE.CAPTCHA_FAILED)) {
          result.error = ERROR_MESSAGE.CAPTCHA_FAILED;

          trackEvent(null, null, PhaseEvent.CAPTCHA_ERROR, { error: result.error });
        } else {
          result.error = ERROR_MESSAGE.ERROR;

          trackEvent(null, null, PhaseEvent.ERROR, {
            error: result.errorText,
            apiUrl: response.url,
          });
        }

        break;
    }

    return result;
  }

  private getFileName(contentDispositionHeader: string) {
    if (contentDispositionHeader && contentDispositionHeader.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);

      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }

    return '';
  }
}

const apiService = new ApiService();

export type { PostResponse, PostResponseBlob };
export { ApiService, baseUrl, apiService, FATAL_STATUS_ERROR };
