import { apiService, FATAL_STATUS_ERROR, PostResponse } from './ApiService';
import { formHelperService } from '../features/form/services';
import { NextPhaseType, SubmitPhaseRequest, UserProperties, UserPropertiesFormEntry } from '../types';
import { SESSION_ERROR_MESSAGE } from '../types/enums';
import { FieldValue } from '../types/form';
import { PhaseResponse, PhaseType } from '../types/phase';
import { currentSessionContextService } from '../utility/sessionContext';

interface IPhaseService {
  submit(
    newValues: Array<FieldValue>,
    type: PhaseType,
    captchaToken: string,
  ): Promise<PostResponse<PhaseResponse> | undefined>;
  rewind(): Promise<PostResponse<PhaseResponse> | undefined>;
  getNextPhaseType(): Promise<PostResponse<NextPhaseType> | undefined>;
}

class PhaseService implements IPhaseService {
  private pathGetNextPhaseType = 'Phase/GetNextPhaseType';
  private pathRewindPhase = 'Phase/RewindPhase';
  private pathSubmitPhase = 'Phase/SubmitPhase';

  async submit(
    newValues: Array<FieldValue> | UserProperties,
    type: PhaseType,
    captchaToken: string,
  ): Promise<PostResponse<PhaseResponse> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      let userProperties = { ...newValues } as UserProperties;

      if (type === PhaseType.FORM) {
        userProperties = formHelperService.createPropertiesPayload(
          newValues as Array<FieldValue>,
          PhaseType.FORM,
        ) as UserPropertiesFormEntry;
      }

      const body = {
        captchaToken,
        userProperties,
        sessionToken,
      } as SubmitPhaseRequest;

      const response = await apiService.post<SubmitPhaseRequest, PhaseResponse>(this.pathSubmitPhase, body);

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  async rewind(): Promise<PostResponse<PhaseResponse> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      const body = {
        sessionToken,
      } as SubmitPhaseRequest;

      const response = await apiService.post<SubmitPhaseRequest, PhaseResponse>(this.pathRewindPhase, body);

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }

  async getNextPhaseType(): Promise<PostResponse<NextPhaseType> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      const body = {
        sessionToken,
      } as SubmitPhaseRequest;

      const response = await apiService.post<SubmitPhaseRequest, NextPhaseType>(this.pathGetNextPhaseType, body);

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }
}

const phaseService = new PhaseService();

export type { IPhaseService };
export { PhaseService, phaseService };
