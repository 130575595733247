import { Box, Checkbox, Typography } from '@mui/material';

import './index.css';
import { Information } from '..';
import { FormQuestion } from '../';
import { FormFieldTypes } from '../../../../types/enums/FormFieldTypes';
import { FieldValue, Question } from '../../../../types/form';

interface Props {
  id: number;
  fieldValues: Array<FieldValue>;
  onFieldChange: () => void;
  formQuestions: Array<Question>;
  headerHtml: string;
  allowLocalCache: boolean;
  cachedGroup: boolean;
  onSensitiveUnlock: (questionId: number) => void;
  setCachedGroups: Function;
  infoHtml: string | null;
}

const FormQuestionGroup: React.FC<Props> = ({
  id,
  fieldValues,
  formQuestions,
  onFieldChange,
  headerHtml,
  allowLocalCache,
  cachedGroup,
  setCachedGroups,
  onSensitiveUnlock,
  infoHtml,
}) => {
  return (
    <>
      {headerHtml && (
        <>
          <Box className="group-header">
            <Typography
              color="textPrimary"
              width="100%"
              className="group-header-text"
              dangerouslySetInnerHTML={{ __html: headerHtml }}
            />

            {infoHtml && <Information infoHtml={infoHtml} />}
          </Box>
        </>
      )}

      {formQuestions.map((x) => {
        let formQuestionClass = 'form-question';
        if (x.fieldType === FormFieldTypes.HIDDEN) {
          return null;
        }

        const value = fieldValues.find((v) => v.questionId === x.id);

        if (value)
          return (
            <Box className={formQuestionClass} key={x.id}>
              <FormQuestion
                isLocked={value.locked}
                onSensitiveUnlock={onSensitiveUnlock}
                key={x.id}
                label={x.label}
                icon={x.fontAwesomeIcon}
                placeholder={x.placeholder}
                helperText={x.helperText}
                fieldType={x.fieldType}
                referenceData={value.referenceData}
                value={value}
                options={x.options}
                onFieldChange={onFieldChange}
                fieldDefinition={x.fieldDefinition}
                infoHtml={x.infoHtml}
              />
            </Box>
          );
        else return null;
      })}
      {allowLocalCache && (
        <Box className="save-checkbox" data-testid="cached-group">
          <Checkbox
            color="primary"
            checked={cachedGroup}
            onChange={() => {
              if (cachedGroup)
                setCachedGroups((prev: Array<number>) => {
                  return prev.filter((x: number) => x !== id);
                });
              else {
                setCachedGroups((prev: Array<number>) => {
                  return [...prev, id];
                });
              }
            }}
          />

          <Box className="save-checkbox-text" color="text.primary">
            Save this section for future submissions.
          </Box>
        </Box>
      )}
    </>
  );
};

export { FormQuestionGroup };
