import { useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

import DefaultFooter from './defaultFooter';
import DefaultHeader from './defaultHeader';
import NotificationOverlay from './notificationOverlay';
import ProgressTracker from './progressTracker';
import { useSession } from '../session/hooks/useSession';
import Resume from '../session/resumeSession/button';
import { VegaMuiThemeTypes } from '../themes/theme';
import { Business } from '../types/enums/appState';
import { PhaseEvent } from '../types/phase';
import { TrackerType } from '../types/phase';
import { getQueryParams } from '../utility';
import { trackEvent } from '../utility/eventTracking';
import { useOnDemandTheme } from '../utility/hooks';
import { getIcon } from '../utility/icon';
import { screenUtils } from '../utility/screenUtils';

import headerStyles from './defaultHeader/index.module.css';

import './index.css';

function Layout({ children, onSessionChange }) {
  const { isFetchingSession, session } = useSession();

  const { theme: onDemandTheme, forcedTheme, handleThemeChange } = useOnDemandTheme();

  const activeTheme = forcedTheme ?? onDemandTheme;

  const [isFrame] = screenUtils.useIsFrame();

  const { onDemandQualtricsScript, onDemandMaintenanceModeText } = useFlags();

  const theme = useTheme();
  const params = getQueryParams();
  const htmlOverlayMessage = session?.payload?.notificationMessageHtml;

  const headerSource = session?.payload?.customHeaderUrl ?? session?.onDemandConfig?.customHeaderUrl ?? '';
  const partnerLogo = session?.sessionInfo?.partnerLogo ?? '';
  const footerSource = session?.payload?.customFooterUrl ?? session?.onDemandConfig?.customFooterUrl ?? '';
  const bodySource = session?.payload?.customBodyUrl ?? session?.onDemandConfig?.customBodyUrl;

  const HeaderTag = session?.payload?.customHeaderTag ?? session?.onDemandConfig?.customHeaderTag ?? 'nav-bar';
  const FooterTag = session?.payload?.customFooterTag ?? session?.onDemandConfig?.customFooterTag ?? 'heartland-footer';
  const BusinessType = session?.onDemandConfig?.gpBusiness ?? Business.HEARTLAND;

  useEffect(() => {
    const showWhenScrolled = () => {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        document.getElementById('maintenanceBanner')?.classList.add('maintenanceBannerScroll');
      } else {
        document.getElementById('maintenanceBanner')?.classList.remove('maintenanceBannerScroll');
      }
    };
    window.addEventListener('scroll', showWhenScrolled);

    return () => {
      window.removeEventListener('scroll', showWhenScrolled);
    };
  });

  useEffect(() => {
    if (!isFetchingSession) {
      const headerScript = document.createElement('script');
      headerScript.type = 'module';
      headerScript.src = headerSource;
      headerScript.id = 'headerScript';

      const footerScript = document.createElement('script');
      footerScript.type = 'module';
      footerScript.src = footerSource;
      footerScript.id = 'footerScript';

      if (headerSource) {
        document.body.appendChild(headerScript);
      }

      document.body.appendChild(footerScript);

      return () => {
        if (headerSource) {
          document.body.removeChild(headerScript);
        }
        document.body.removeChild(footerScript);
      };
    }
  }, [isFetchingSession, headerSource, footerSource]);

  const [showFeedback, setShowFeedback] = useState(false);

  //Enforce that custom headers and custom partner logos are only used in light mode
  useEffect(() => {
    if (
      (!isEmpty(headerSource) || !isEmpty(partnerLogo) || !isEmpty(footerSource)) &&
      activeTheme === VegaMuiThemeTypes.Dark
    ) {
      handleThemeChange();
    }
  }, [theme, activeTheme, handleThemeChange, isFrame, headerSource, partnerLogo, footerSource]);

  useEffect(() => {
    const qualtricsUrl = import.meta.env.VITE_QUALTRICS_URL;
    if (qualtricsUrl && onDemandQualtricsScript) {
      fetch(qualtricsUrl)
        .then(() => {
          setShowFeedback(true);
        })
        .catch(() => {
          setShowFeedback(false);
        });
    }
  }, [onDemandQualtricsScript]);

  useMemo(() => {
    if (onDemandMaintenanceModeText) {
      trackEvent(onDemandMaintenanceModeText, 'MaintenancePage', PhaseEvent.MAINTENANCE_MODE);
    }
  }, [onDemandMaintenanceModeText]);

  const baseHeaderStyle = {
    backgroundColor: theme.palette.primary.light,
    flexDirection: 'row',
    borderBottom: '1px solid ' + theme.palette.dark?.customBorder,
  };

  const customFooter = !isEmpty(footerSource) && footerSource !== 'Default' && footerSource !== 'default';

  if (isFrame)
    return (
      <Box id="main" className="iframe-main">
        <ProgressTracker
          showTracker={session?.payload?.showTracker}
          onlyShow={TrackerType.BUBBLE}
          phaseType={session?.payload?.phaseType}
          type={session?.payload?.trackerType}
        />
        {children}
      </Box>
    );

  return (
    <Box
      sx={
        customFooter
          ? { backgroundColor: theme.palette.primary.bgPage, height: window.innerHeight }
          : { height: window.innerHeight, display: 'flex', flexDirection: 'column' }
      }>
      {!isEmpty(headerSource) ? (
        <AppBar
          position="fixed"
          className={headerStyles.header + ' fade-in'}
          sx={{
            ...baseHeaderStyle,
            padding: 0,
            minHeight: '80px',
            backgroundColor: theme.palette.primary.bgPrimary,
            borderBottom: 'none',
            flexDirection: 'column',
          }}>
          <HeaderTag data-testid="custom-header" env={import.meta.env.VITE_ENV ?? 'prd'} />
        </AppBar>
      ) : (
        !isFetchingSession && (
          <DefaultHeader
            session={session}
            onSessionChange={onSessionChange}
            changeTheme={handleThemeChange}
            activeTheme={activeTheme}
            phase={session?.payload}
          />
        )
      )}

      <main>
        {!isEmpty(onDemandMaintenanceModeText) && !isFetchingSession && (
          <>
            {/* This buffer is to push the card down the same height as the header banner 
            so warning doesn't overlap top of the card. */}
            <Box className="maintenanceBannerBuffer" data-testid="maintenanceBannerBuffer"></Box>
            <Box
              id="maintenanceBanner"
              className={
                !isEmpty(headerSource)
                  ? 'maintenanceBannerCustom maintenanceBanner fade-in'
                  : 'maintenanceBanner fade-in'
              }
              data-testid="maintenanceBanner"
              sx={{ zIndex: '10' }}>
              <FontAwesomeIcon
                icon={getIcon('faTriangleExclamation')}
                size="lg"
                data-testid="warning-icon"
                className="maintenanceBanner-icon"
              />
              {onDemandMaintenanceModeText}
            </Box>
          </>
        )}
        <Box className="main" id="main" bgcolor="primary.bgPage" style={{ zIndex: 0 }}>
          {!isEmpty(bodySource) && (
            <Box
              bgcolor="primary.bgPage"
              className="background-image fade-in"
              sx={{
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                paddingTop: '8rem',
                zIndex: -1,
              }}>
              <img
                style={{ width: '100%', maxWidth: '1950px' }}
                data-testid="custom-body"
                alt="background"
                src={bodySource}
              />
            </Box>
          )}
          <ProgressTracker
            showTracker={session?.payload?.showTracker}
            onlyShow={TrackerType.BUBBLE}
            phaseType={session?.payload?.phaseType}
            type={session?.payload?.trackerType}
          />
          {children}
        </Box>
        {htmlOverlayMessage && <NotificationOverlay htmlOverlayMessage={htmlOverlayMessage}></NotificationOverlay>}
      </main>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.bgPage,
          position: 'absolute',
          width: '100%',
          marginTop: '-.1rem',
        }}>
        {showFeedback && onDemandQualtricsScript && (
          <Box className={'feedback-container'} bgcolor="primary.bgPage">
            <Box id="feedback-link" />
          </Box>
        )}
        {!isFetchingSession && customFooter && (
          <FooterTag data-testid="custom-footer" captcha theme="gray" env={import.meta.env.VITE_ENV ?? 'prd'} />
        )}
      </Box>
      {!isFetchingSession && !customFooter && <DefaultFooter business={BusinessType} />}
      {params?.resume != null && !isEmpty(headerSource) && (
        <Resume resumeParam={params?.resume} userSession={session} onSuccess={onSessionChange} />
      )}
    </Box>
  );
}

export default Layout;
