export * from './NextPhaseType';
export * from './PostResponse';
export * from './SubmitPhaseRequest';

export {};

export const bgPageLight = '#f0f3f7';
//export const bgPageDark = '#f0f3f7';
export const bgPageDark = '#181830';
export const scrollBgDark = '#282828';
export const scrollBgLight = '#fff';
export const shadowBgDark1 = '0px 0px 0px 3px #c8c8c83d';
export const shadowBgDark2 = '0px 0px 0px 5px #c8c8c840';
export const shadowBgLight1 = '0px 0px 0px 3px #c2d5f5ad';
export const shadowBgLight2 = '0px 0px 0px 5px #c2d5f5a9';

export enum DeviceTypes {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}
