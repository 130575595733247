export enum SessionQueries {
  SESSION_DATA = 'session',
  START_SESSION = 'startSession',
  NAVHASH = 'navhash',
  USER_CLIENT = 'userClient',
  GET_USER_CLIENT_SESSIONS = 'getSessions',
}

export enum PhaseQueries {
  SUBMIT_PHASE = 'submitPhase',
  EMAIL_VALIDATION = 'emailValidation',
  ROUTING_NUMBER_VALIDATION = 'routingNumberValidation',
}

export enum ReferenceDataQueries {
  MCC = 'MCC',
}

export enum EquipmentQueries {
  PACKAGE = 'PACKAGE',
  PACKAGES = 'PACKAGES',
  CART = 'CART',
  CONFIGURATION_TOTAL = 'CONFIGURATION_TOTAL',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  MATRIX = 'MATRIX',
  SHIPPING_TYPES = 'SHIPPING_TYPES',
  SHIPPING_INFO = 'SHIPPING_INFO',
}

export enum EquipmentMutations {
  QUANTITY_CHANGE = 'QUANTITY_CHANGE',
  MODIFY_CART = 'MODIFY_CART',
}
