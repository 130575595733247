import { useCallback, useEffect, useRef, useState } from 'react';

import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { isEmpty } from 'lodash';

import { getLocations } from '../../api/location';
import { formHelperService } from '../../features/form/services';
import { FormFieldSubTypes, FormFieldTypes } from '../../types/enums/FormFieldTypes';
import { AddressSearchParam, AddressSearchResult } from '../../types/locationSearch';

interface Props {
  value: any;
  id: string;
  label: string;
  required: boolean;
  error: string | null;
  handleSelectedLocation: Function;
  icon: string;
  handleInputChanged: Function;
  validateOnBlur: (value: string) => void;
  fullWidth?: boolean;
  onBlurSelect?: (value: string) => void;
  onFocus?: Function;
  minLength?: number;
  maxLength?: number;
  fieldType: FormFieldTypes;
  fieldSubType?: FormFieldSubTypes;
  propertyKey: string;
}

const LocationSearch: React.FC<Props> = ({
  value,
  id,
  label,
  required,
  error,
  handleSelectedLocation,
  icon,
  handleInputChanged,
  validateOnBlur,
  fullWidth,
  onBlurSelect,
  onFocus,
  minLength = null,
  maxLength = null,
  fieldType,
  propertyKey,
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [inputVal, setInputVal] = useState<string>(value ?? '');
  const [locationModels, setLocationModels] = useState<Array<AddressSearchResult>>([]);
  const [selectedVal, setSelectedVal] = useState<any>('');
  const [hasTyped, setHasTyped] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [newOptions, setNewOptions] = useState<{ result: any[]; filter: string } | null>(null);

  let timerRef = useRef<NodeJS.Timeout | null>(null);

  const searchLocations = useCallback(async (inputValue: string) => {
    let addressParam: AddressSearchParam = {
      textValue: inputValue,
    };

    await getLocations(addressParam)
      .then((res) => {
        if (isEmpty(res?.err)) {
          const models = res as Array<AddressSearchResult>;
          setLocationModels((prev) => [...prev, ...models]);
          setNewOptions({
            result: models.map((result) => {
              return { label: result.addressSummary, value: result.addressSummary };
            }),
            filter: inputValue,
          });
        }
      })
      .catch((exc) => {
        throw new Error(exc);
      });
  }, []);

  useEffect(() => {
    if (newOptions?.filter === inputVal) {
      if (newOptions.result.length !== 0) {
        let newResults = newOptions.result;

        //The following case handles if there is 1 result that has equality to the current input value of City State Zip and Address
        if (newResults.length === 1) {
          try {
            const city: any = document.getElementById('City');
            const state: any = document.getElementById('State');
            const zip: any = document.getElementById('Zip');
            const possibleTarget = locationModels.find((x) => x.streetAddress === inputVal.trim());

            if (city != null && state != null && zip != null && possibleTarget != null) {
              if (
                city.value === possibleTarget.city &&
                state.innerText === possibleTarget.stateName &&
                zip.value === possibleTarget.zipCode
              ) {
                newResults = [];
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
        setOptions(newResults);
        setOpen(newResults.length > 0);
      } else setOpen(false);
    }
  }, [newOptions, inputVal, locationModels]);

  useEffect(() => {
    if (timerRef.current != null) {
      clearTimeout(timerRef.current);
    }

    if (hasTyped && !isEmpty(inputVal.trim())) {
      timerRef.current = setTimeout(async () => {
        setHasTyped(false);
        setInputVal(inputVal.trimStart());
        await searchLocations(inputVal.trim());
      }, 200);
    }
  }, [hasTyped, inputVal, searchLocations]);

  const handleInputChange = (value: any) => {
    setInputVal(value);
    handleInputChanged(value);
    setHasTyped(true);
  };

  const handleSelection = (val: any) => {
    setSelectedVal(val);
    setOpen(false);
    if (val) {
      const target = locationModels.find((item) => item.addressSummary === val.value);
      if (target) {
        setInputVal(target?.streetAddress!);
        handleSelectedLocation(target);
      } else {
        setInputVal(val);
      }
    } else {
      setInputVal('');
      handleSelectedLocation(null);
    }
  };

  return (
    <Autocomplete
      id={id}
      options={options}
      autoSelect
      autoHighlight
      blurOnSelect={false}
      handleHomeEndKeys
      clearOnEscape
      value={selectedVal}
      popupIcon={null}
      open={open}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          required={required}
          label={label}
          variant="filled"
          {...params}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            'data-testid': 'location-search',
            minLength,
            maxLength,
            ...formHelperService.getCustomInputProps(fieldType, propertyKey, label),
          }}
          onBlur={(e: any) => {
            validateOnBlur(e.target.value);
          }}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: params.InputProps.endAdornment,
          }}
          error={Boolean(error)}
          placeholder={`Street Address`}
          helperText={error ? error : ''}
        />
      )}
      freeSolo
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => {
        return option.label ?? '';
      }}
      onFocus={() => {
        if (onFocus) onFocus();
      }}
      onBlur={(e: any) => {}}
      onChange={(event: any, newValue: any, reason: string) => {
        if (reason !== 'blur' || onBlurSelect) {
          handleSelection(newValue);
        } else {
          setOpen(false);
        }
      }}
      inputValue={inputVal}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'reset') {
          handleInputChange(newInputValue);
        }
      }}
      filterOptions={(x) => x}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option?.label ?? '', inputValue);
        const parts = parse(option?.label ?? '', matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}>
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};

export default LocationSearch;
