import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
  Popper,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';

export const themeOverride = {
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        zIndex: 1,
      },
    },
  },
};

export const HeartlandErrorText = styled(FormHelperText)(({ theme }) => ({
  color: `${theme.palette.warning.error}`,
  fontSize: '.8rem',
  marginLeft: '.8rem',
  marginTop: '7px',
}));

export const HeartlandErrorTextCenter = styled(FormHelperText)(({ theme }) => ({
  color: `${theme.palette.warning.main}`,
  fontSize: '.8rem',
  marginLeft: '.8rem',
  marginTop: '7px',
  textAlign: 'center',
}));

export const RadioIcon = styled('span')(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.bgPrimary,
  border: '1px solid' + theme.palette.primary.inputBorder,
}));

export const AccessoryTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    color: `${theme.palette.dark.main}`,
    border: `${theme.palette.dark.contrastText}`,
    fontSize: '.7rem',
    maxWidth: '30rem',
    borderRadius: '16px',
    fontWeight: '400',
    backgroundColor: 'transparent',
    marginLeft: '-.4rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-85px',
      marginLeft: '1rem',
    },
  },
}));

export const HeartlandSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(7),
  },
  borderRadius: 12,
  select: {
    position: 'relative',
    backgroundColor: `${theme.palette.primary.light}`,
    color: theme.palette.text.primary,
    fontSize: '.9rem',
    fontWeight: '500',
    height: '1.8rem',
    lineHeight: '1.8rem',
    padding: '10px 12px',
    borderRadius: 12,
    textIndent: '.3rem',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: ['Inter', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.33)} 0 0 0 0.2rem`,
      borderColor: theme.palette.dark.main,
      borderRadius: 12,
    },
    '&&:after': {
      borderBottom: 'none',
    },
    '&::placeholder': {
      color: `${theme.palette.text.primary}`,
      opacity: '1',
      fontWeight: 500,
      fontSize: 14,
    },
  },
  icon: {
    color: `${theme.palette.text.primary}`,
  },
}));

export const HeartlandSelectAdornment = styled(InputAdornment)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: '93%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  zIndex: '1',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '94%',
  },
}));

export const HeartlandNumberSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(7),
  },
  borderRadius: 3,
  paddingTop: '3px',
  paddingBottom: '3px',
}));

export const HeartlandRadioLabel = styled(InputLabel)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: `${theme.palette.text.primary}`,
  fontSize: '1rem',
  fontWeight: '400',
  whiteSpace: 'pre-wrap',
  position: 'relative',
  textIndent: '0',
  lineHeight: '1.6',
  maxWidth: '97%',
  marginBottom: '9px',
  asterisk: {
    color: `${theme.palette.warning.main}`,
  },
}));

export const HeartlandCheckboxLabel = styled(InputLabel)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: `${theme.palette.text.primary}`,
  fontSize: '1rem',
  fontWeight: '400',
  whiteSpace: 'pre-wrap',
  position: 'relative',
  textIndent: '0',
  lineHeight: '1.6',
  maxWidth: '97%',
  marginBottom: '9px',
  asterisk: {
    color: `${theme.palette.warning.main}`,
  },
}));

export const HeartlandQuestionHelperText = styled(FormHelperText)(({ theme }) => ({
  root: {
    fontStyle: 'italic',
    color: `${theme.palette.secondary.black}`,
    fontSize: '.8rem',
    textIndent: '0',
    marginTop: '.7rem',
    marginBottom: theme.spacing(0.5),
  },
  error: {
    color: `${theme.palette.warning.main} !important`,
  },
}));

export const ConfigForm = styled(FormControl)(({ theme }) => ({
  fontSize: '1rem',
}));

export const ConfigLabel = styled(Box)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  fontWeight: '500',
  marginBottom: '0.1rem',
}));

export const ConfigSubLabel = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  fontSize: '.8rem',
  fontWeight: '500',
  marginBottom: '0.1rem',
}));

export const ConfigDescription = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  fontSize: '.9rem',
  marginLeft: '.4rem',
  marginTop: '.3rem',
  marginBottom: '.3rem',
}));

export const ConfigOptions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: '100%',
}));

export const ConfigOptionsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '.4rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const ConfigButton = styled(Button)(({ theme }) => ({
  width: '9rem',
  minHeight: '3.5rem',
  lineHeight: '1rem',
  fontWeight: '500',
  backgroundColor: `${theme.palette.primary.light}`,
  color: `${theme.palette.text.primary}`,
  letterSpacing: 'normal',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important',
    margin: '.25rem 0',
  },
}));

export const ConfigAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  justifyContent: 'center',
  marginTop: '0',
  marginBottom: '1rem',
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    opacity: 0.5,
  },
  '&.Mui-expanded': {
    cursor: 'default',
    backgroundColor: 'transparent',
    minHeight: '0',
    marginTop: '3px',
  },
  '&:before': {
    backgroundColor: 'transparent',
  },
}));

export const StyledItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.bgPrimary,
  '&:hover': {
    backgroundColor: theme.palette.primary.bgSecondary,
  },
}));

export const ConfigAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  '& div': {
    display: 'block',
  },
  content: {
    '&.Mui-expanded': {
      marginTop: '0',
      cursor: 'default',
      marginLeft: '.4rem',
      marginBottom: '0px !important',
    },
  },
  '&.Mui-expanded': {
    maxHeight: '34px',
    transition: 'none',
    minHeight: '0',
  },
}));

export const ConfigAccordionCollapse = styled(Box)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  textDecoration: 'underline',
  fontSize: '.8rem',
  fontWeight: '500',
  marginTop: '0.8rem',
  textAlign: 'bottom',
  cursor: 'pointer',
  '&:hover': {
    opacity: '.8',
  },
}));

export const ClosedStyleAccordion = (theme) => ({
  borderRadius: '16px',
  backgroundColor: `${theme.palette.primary.bgPage}`,
  paddingLeft: '1.7rem',
  paddingRight: '1.7rem',
  paddingTop: '.5rem',
  paddingBottom: '.5rem',
  fontSize: '1rem',
});

export const HeartlandTooltip = styled(Tooltip)(({ theme }) => ({
  fontSize: '.7rem',
  maxWidth: '30rem',
  fontWeight: '400',
  backgroundColor: 'transparent',
  borderRadius: '16px',
  marginLeft: '-.8rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '-5rem',
    marginLeft: '-.0rem',
  },
}));

export const OrderSummaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main}`,
  fontSize: '1rem',
  marginBottom: '0.1rem',
  width: '100%',
}));

export const RadioOptionButton = styled(Button)(({ theme }) => ({
  width: '45%',
  minHeight: '4rem',
  lineHeight: '1rem',
  fontWeight: '400',
  padding: '0',
  border: `${theme.palette.dark.contrastText}`,
  borderRadius: '14px',
  backgroundColor: `${theme.palette.primary.light}`,
  color: `${theme.palette.text.primary}`,
  letterSpacing: 'normal',
  [theme.breakpoints.down('sm')]: {
    marginTop: '.5rem',
    width: '100%',
  },
  label: {
    justifyContent: 'space-between',
  },
}));

export const HeartlandContactLabel = styled(InputLabel)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  fontSize: '1.2rem',
  fontWeight: 'bold',
  // focused: {
  //   color: `${theme.palette.primary.main} !important`,
  // },
  error: {
    color: `${theme.palette.warning.main}`,
  },
  asterisk: {
    color: `${theme.palette.warning.main}`,
  },
}));

export const ThemeSwitch = styled(Switch)(() => ({
  padding: 6,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: `.7 !important`,
    backgroundColor: '#121227 !important',
    border: `1px solid var(--border)`,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 20,
      height: 20,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="yellow" d="M12.01 12c0-3.57 2.2-6.62 5.31-7.87.89-.36.75-1.69-.19-1.9-1.1-.24-2.27-.3-3.48-.14-4.51.6-8.12 4.31-8.59 8.83C4.44 16.93 9.13 22 15.01 22c.73 0 1.43-.08 2.12-.23.95-.21 1.1-1.53.2-1.9-3.22-1.29-5.33-4.41-5.32-7.87z"/></svg>')`,
      left: 8,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="yellow" d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41l-1.06-1.06zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z" /></svg>')`,
      right: 8,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    color: '#FCFCFC',
    width: 20,

    height: 20,
  },
}));

export const HeartlandMultiLineInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.1),
  },
  border: `1px solid ${theme.palette.primary.inputBorder}`,
  borderRadius: 12,
  input: {
    position: 'relative',
    backgroundColor: `${theme.palette.primary.light}`,
    color: theme.palette.text.primary,
    fontSize: '.9rem',
    borderRadius: 12,
    fontWeight: 500,
    minHeight: '1.8rem',
    padding: '10px 12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: ['Inter', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    '&:focus': {
      boxShadow: 'none',
      borderColor: theme.palette.primary.inputFocus,
    },
    '&:hover': {
      borderColor: theme.palette.primary.inputHover,
    },
    '&::placeholder': {
      opacity: '.4',
      fontWeight: 400,
      fontSize: 14,
    },
  },
  multiline: {
    padding: 0,
  },
  error: {
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: 12,
  },
}));

export const FormDivider = styled(Divider)(({ theme }) => ({
  marginTop: '2.5rem',
}));

export const HeartlandCodeInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.1),
  },
  border: 'none',
  borderBottom: '1px solid' + theme.palette.secondary.light,
  input: {
    position: 'relative',
    backgroundColor: `${theme.palette.primary.light}`,
    color: theme.palette.text.primary,
    fontSize: '3rem',
    letterSpacing: '.3rem',
    borderRadius: 0,
    fontWeight: 500,
    minHeight: '1.8rem',
    wordSpacing: '-1px',
    padding: '10px 12px',
    fontFamily: 'Arial',
    '&::placeholder': {
      color: `${theme.palette.text.primary}`,
      opacity: '.2',
      fontWeight: 500,
      letterSpacing: '.3rem',
      wordSpacing: '-1px',
      fontSize: '3rem',
    },
  },
  multiline: {
    padding: 0,
  },
  error: {
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: 12,
  },
}));

export const HeartlandCodeErrorText = styled(FormHelperText)(({ theme }) => ({
  fontStyle: 'italic',
  color: `${theme.palette.warning.main}`,
  fontSize: '.9rem',
  marginLeft: 0,
  marginTop: '0',
}));

export const StyledDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  paddingLeft: '1.5rem',
  padding: theme.spacing(1.2),
  color: `${theme.palette.primary.main}`,
  fontSize: '1.4rem',
  fontWeight: 500,
  minHeight: '1rem',
  //borderBottom: `1px ${theme.palette.secondary.light} solid`,
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: `${theme.palette.text.primary}`,
  },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  // You can replace with `PopperUnstyled` for lower bundle size.
  zIndex: 1,
  //maxWidth: '375px',
  //width: 100%',
  padding: '.5rem',
  '&[data-popper-placement*="bottom"] .information-pane .arrow': {
    top: 0,
    left: 0,
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.primary.paperHover} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .information-pane .arrow': {
    bottom: 0,
    left: 0,
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${theme.palette.primary.paperHover} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .information-pane .arrow': {
    left: 0,
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${theme.palette.primary.paperHover} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .information-pane .arrow': {
    right: 0,
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${theme.palette.primary.paperHover}`,
    },
  },
}));
