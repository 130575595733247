import { ReactNode, useEffect, useState } from 'react';

import mousetrap from 'mousetrap';

import { reloadPhaseModel } from '../../api/phase';
import { useSession } from '../../session/hooks/useSession';
import { StatusPhase } from '../../types/phase';

interface ContentInjectorDebuggerProps {
  children: ReactNode;
}

// s t = show tokens
const keyCombination: string = 'up left up left s t';

export const ContentInjectorDebugger: React.FC<ContentInjectorDebuggerProps> = ({ children }) => {
  const [currentPhase, setCurrentPhase] = useState<string | undefined>();
  const [bypassContentInjector, setBypassContentInjector] = useState<boolean>(false);
  const [shouldUpdateContent, setShouldUpdateContent] = useState<boolean>(false);
  const { session, setLoadedPhase } = useSession();

  const loadedPhase = session?.payload;

  useEffect(() => {
    mousetrap.bind(keyCombination, () => {
      setBypassContentInjector((prev) => !prev);
      setShouldUpdateContent(true);
    });

    return () => {
      mousetrap.unbind(keyCombination);
    };
  }, []);

  useEffect(() => {
    if (typeof loadedPhase === 'undefined') return;

    if (shouldUpdateContent) {
      setShouldUpdateContent(false);
      reloadPhaseModel(bypassContentInjector, loadedPhase.phaseType, (loadedPhase as StatusPhase)?.statusPageType).then(
        (response) => {
          setLoadedPhase({
            ...response.payload,
            contentReloadedTimestamp: (loadedPhase?.contentReloadedTimestamp ?? 0) + 1,
          });
        },
      );
    } else if (
      typeof currentPhase === 'undefined' ||
      (loadedPhase.id?.toString() !== currentPhase && (loadedPhase as StatusPhase).statusPageType !== currentPhase)
    ) {
      setCurrentPhase(loadedPhase.id?.toString() || (loadedPhase as StatusPhase).statusPageType);
      setBypassContentInjector(false);
    }
  }, [
    loadedPhase,
    currentPhase,
    bypassContentInjector,
    shouldUpdateContent,
    setLoadedPhase,
    setCurrentPhase,
    setBypassContentInjector,
  ]);

  return <>{children}</>;
};
