export class CommonStringValidations {
  //#region Server Side Validations

  /**
   * Alphanumeric text. At least one character long, no other characters allowed
   */
  public static readonly Alphanumeric: RegExp = /^[a-zA-Z0-9]+$/;

  /**
   * Programmatic Date and DateTime formats, such as "2024-05-30", "2018-08-18T07:22:16.0000000Z", and "2024-01-15 08:24:09.4828967"
   */
  public static readonly DateTime: RegExp =
    /^\d{4}-\d{2}-\d{2}([T ]\d{2}:\d{2}:\d{2}(\.\d{1,7})?(Z|[+-]\d{2}:\d{2})?)?$/;

  /**
   * Valid Email Addresses
   */
  public static readonly EmailAddress: RegExp =
    /^([A-Za-z0-9](\.|_|-|\+){0,1})*[A-Za-z0-9_-]@([A-Za-z0-9]|\b[-]\b)+((\.|_|-){0,1}[A-Za-z0-9])*\.[a-zA-Z]{2,63}$/i;

  /**
   * URLs with HTTP or HTTPS protocols
   */
  public static readonly HttpUrl: RegExp = /^(https?:\/\/)?\w([.\w-]*\w)?(\.[a-z]{2,63})(\/[\da-z.-]+)*\/?$/i;

  /**
   * Numeric text, digits only. At least one character long, no other characters allowed
   */
  public static readonly Numeric: RegExp = /^[0-9]+$/;

  /**
   * US Phone numbers (with no country code) with format like "(123) 456-7890", or "123-456-7890", "1234567890"
   */
  public static readonly UsPhoneNumber: RegExp = /^\D*(\d\D*){10}$/;

  /**
   * US Zip Code. 5 digits with an optional +4 either with a hyphen or space between them
   */
  public static readonly UsZipCode: RegExp = /^(\d){5}$/;

  //#endregion Server Side Validations

  //#region Client Side Validations

  /**
   * Valid name format. Does not start or end with special characters or digits.
   */
  public static readonly NameFormat: RegExp =
    /^(?![\s`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\d])[A-Za-z\d ,.'-]+(?<![\s`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\d])$/;

  /**
   * Valid address format. Matches variations of "Post Office Box".
   */
  // eslint-disable-next-line no-useless-escape
  public static readonly AddressFormat: RegExp = /\bP(ost|ostal|0)?([ \.]*O(ffice)?)?([ \.]*Box)?\b/i;

  /**
   * Valid image file extensions. Matches common image file formats.
   */
  public static readonly ImageFileExtensions = /\.(gif|jpe?g|tiff?|png|webp|bmp|svg|ashx)$/i;

  /**
   * Mobile user agent detection. Matches various mobile device user agents.
   */
  public static readonly MobileUserAgent =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

  /**
   * Mobile user agent detection. Matches additional mobile device user agents.
   */
  public static readonly MobileUserAgentPart2 =
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

  //#endregion Client Side Validations
}
