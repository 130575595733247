import React, { useEffect, useState, useTransition } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { isEmpty } from '..';
import { OneXThemePalette } from '../../types/enums/theme';
import NumberInput from '../numberInput';

import styles from './index.module.css';
import { formHelperService } from '../../features/form/services';
import { FormFieldSubTypes, FormFieldTypes } from '../../types/enums/FormFieldTypes';
import { getOnDemandCustomElementProps } from '../screenUtils';

interface Props {
  onChange: (value: number) => void;
  value: any;
  min: number;
  max: number;
  label: string;
  disabled?: boolean;
  validateMin?: boolean;
  fieldType: FormFieldTypes;
  fieldSubType?: FormFieldSubTypes;
  propertyKey: string;
}

const NumberStepper: React.FC<Props> = ({
  value,
  onChange,
  min,
  max,
  label,
  disabled = false,
  validateMin = false,
  fieldType,
  propertyKey,
}) => {
  const [, startTransition] = useTransition();
  const [localValue, setLocalValue] = useState<number>(value);
  const theme = useTheme<OneXThemePalette>();

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const inputProps = {
    max: max,
    maxLength: max,
    min: min,
    decimalPlaces: 0,
    prefix: '',
    suffix: '',
    thousandSeparator: true,
    allowLeadingZeros: false,
    allowOverflow: false,
    validateMin: validateMin,
    ...formHelperService.getCustomInputProps(fieldType, propertyKey, label),
  };

  const reduceValue = () => {
    if (localValue > 0) {
      const newValue = localValue - 1;
      setLocalValue(newValue);
      startTransition(() => onChange(newValue));
    }
  };

  const increaseValue = () => {
    if (localValue < max) {
      const newValue = localValue + 1;
      setLocalValue(newValue);
      startTransition(() => onChange(newValue));
    }
  };

  const setValue = (newValue: any) => {
    if (newValue < min) newValue = min;
    if (!newValue) newValue = min;
    if (isNaN(Number(newValue)) === false) {
      setLocalValue(Number(newValue));
      startTransition(() => onChange(Number(newValue)));
    }
  };

  const decrementDisabled = localValue <= min || disabled;
  const incrementDisabled = localValue >= max || disabled;

  return (
    <Box className={styles.numberStepper}>
      <IconButton
        aria-label={localValue > 1 ? label + 'subtract' : label + 'remove'}
        data-testid={label + 'Decrease'}
        disabled={decrementDisabled}
        onClick={reduceValue}
        {...getOnDemandCustomElementProps('btnDecrease', label)}>
        <RemoveCircleOutlineIcon
          sx={{
            opacity: decrementDisabled ? 0.4 : 1,
            color: decrementDisabled ? theme.palette.text.secondary : theme.palette.primary.main,
          }}
        />
      </IconButton>
      <TextField
        className={styles.numberStepperInput}
        data-testid={label + 'Quantity'}
        value={localValue}
        disabled={disabled}
        InputProps={{
          type: 'text',
          inputComponent: NumberInput as any,
          inputProps: {
            ...inputProps,
            'aria-label': `${label}Quantity`,
            style: {
              textAlign: 'center',
              height: '40px',
              width: '50px',
              textIndent: 0,
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        }}
        sx={{ div: { height: '2.5rem', textAlign: 'center' }, backgroundColor: theme.palette.primary.bgPrimary }}
        onChange={(e) => {
          if (
            e.target.value.length <= 2 &&
            (parseInt(e.target.value) || isEmpty(e.target.value) || e.target.value === '0')
          )
            setValue(e.target.value);
        }}
        onBlur={(e) => {
          setValue(e.target.value);
        }}
      />
      <IconButton
        aria-label={label + 'add'}
        data-testid={label + 'Increase'}
        disabled={incrementDisabled}
        onClick={increaseValue}
        {...getOnDemandCustomElementProps('btnIncrease', label)}>
        <AddCircleIcon
          sx={{
            opacity: incrementDisabled ? 0.4 : 1,
            color: incrementDisabled ? theme.palette.text.secondary : theme.palette.primary.main,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default NumberStepper;
