import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Icon, TextField, Typography } from '@mui/material';

import { requestResumeSessionCode, resumeSessionWithCode } from '../../../api/session';
import { HeartlandCodeErrorText, HeartlandCodeInput } from '../../../themes/overrides';
import { FormFieldTypes, TextMaskTypes } from '../../../types/enums/FormFieldTypes';
import { VerifyMessages } from '../../../types/enums/verifyMessages';
import { FieldValue, ValidationResult } from '../../../types/form';
import { PhaseResponse } from '../../../types/phase';
import { ResumeSessionScope, Session } from '../../../types/session';
import { currentSessionContextService } from '../../../utility/sessionContext';
import { validateFieldValue } from '../../../utility/validation';

import '../../../phases/emailVerification/form/index.css';

interface Props {
  userSession: Session;
  resumeScope: ResumeSessionScope;
  setSubmitTimeout: Function;
  submitTimeout: boolean;
  timeoutCounter: number;
  setAttempts: Function;
  attempts: number;
  preventAttempt: boolean;
  showStartNewApplication?: boolean;
  onSuccess: (response: PhaseResponse) => void;
  onErrorHappened?: (error: VerifyMessages) => void;
  onStartNewApplication?: () => void;
}

const ResumeVerificationCodeForm: React.FC<Props> = ({
  userSession,
  resumeScope,
  setSubmitTimeout,
  submitTimeout,
  timeoutCounter,
  setAttempts,
  attempts,
  preventAttempt,
  showStartNewApplication,
  onSuccess,
  onErrorHappened,
  onStartNewApplication,
}) => {
  const [email, setEmail] = useState<string>(currentSessionContextService.currentEmail ?? '');
  const [emailErrorText, setEmailErrorText] = useState<VerifyMessages>();
  const [codeErrorText, setCodeErrorText] = useState<VerifyMessages>();
  const [code, setCode] = useState<string>('');
  const [remainingAttempts, setRemainingAttempts] = useState<number>(
    +(import.meta.env.VITE_MAX_VERIFICATION_CODE_ATTEMPT || 0),
  );

  const [responseData, setResponseData] = useState<PhaseResponse | undefined>(undefined);
  const [verifyCodeView, setVerifyCodeView] = useState<boolean>(false);
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const [postingCode, setPostingCode] = useState<boolean>(false);
  const [successDelay, setSuccessDelay] = useState<boolean>(false);

  //This effect triggers when we are sending out the email with the code
  useEffect(() => {
    let subscribed = true;
    async function SendEmail() {
      return await requestResumeSessionCode(
        userSession.onDemandConfig.id,
        resumeScope,
        userSession.sessionInfo.aaid ?? '',
        email,
        userSession.sessionToken,
        userSession.sessionUid ? userSession.sessionUid : undefined,
      );
    }
    if (sendingEmail && subscribed) {
      SendEmail().then((response: any) => {
        if (response?.status === 401) {
          throw new Error(VerifyMessages.Unauthorized);
        } else if (response?.status === 500) {
          throw new Error(VerifyMessages.ServerException);
        } else if (response?.status === 429) {
          setEmailErrorText(VerifyMessages.TooManyRequests);
        } else if (response?.status === 403) {
          setEmailErrorText(VerifyMessages.Forbidden);
        } else if (response?.status === 404) {
          setEmailErrorText(VerifyMessages.CouldntVerify);
        } else if (response?.status < 200 || response?.status >= 300) {
          setVerifyCodeView(false);
        } else {
          setSubmitTimeout(true);
          setVerifyCodeView(true);
        }

        setSendingEmail(false);
      });
    }
    return () => {
      subscribed = false;
    };
  }, [
    sendingEmail,
    email,
    userSession.onDemandConfig.id,
    userSession.sessionInfo.aaid,
    userSession.sessionToken,
    setSubmitTimeout,
    resumeScope,
    userSession.sessionUid,
  ]);

  //This effect triggers when we are trying to verify the code they typed in
  useEffect(() => {
    let subscribed = true;
    async function VerifyCode() {
      return await resumeSessionWithCode(
        userSession.onDemandConfig.id,
        resumeScope,
        userSession.sessionInfo.aaid ?? '',
        email,
        code,
        userSession.sessionToken,
        userSession.sessionUid ? userSession.sessionUid : undefined,
      );
    }
    if (postingCode && subscribed) {
      VerifyCode()
        .then((response: any) => {
          if (response?.status >= 200 && response?.status < 300) {
            setAttempts(0);
            response.json().then((data: PhaseResponse) => {
              setResponseData(data);
              setSuccessDelay(true);
            });
          } else {
            if (code.length === 7) {
              setCodeErrorText(VerifyMessages.CouldntVerify);
            } else {
              setCodeErrorText(VerifyMessages.InvalidCode);
            }
          }
        })
        .catch((error) => {
          console.error(error);

          throw new Error(VerifyMessages.ServerException);
        })
        .finally(() => {
          setPostingCode(false);
        });
    }
    return () => {
      subscribed = false;
    };
  }, [
    postingCode,
    code,
    email,
    userSession.onDemandConfig.id,
    userSession.sessionInfo.aaid,
    userSession.sessionToken,
    preventAttempt,
    setAttempts,
    resumeScope,
    userSession.sessionUid,
  ]);

  useEffect(() => {
    if (successDelay) {
      let timeout = setTimeout(() => {
        if (responseData != null) onSuccess(responseData);
        setSuccessDelay(false);
      }, 800);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [successDelay, onSuccess, responseData]);

  //This effect will focus certain elements when the view changes
  useEffect(() => {
    if (!verifyCodeView) {
      document.getElementById('EmailInput')?.focus();
    } else {
      document.getElementById('EmailCodeInput')?.focus();
    }
  }, [verifyCodeView]);

  //This effect will update remaining attempts for UI
  useEffect(() => {
    setRemainingAttempts(+(import.meta.env.VITE_MAX_VERIFICATION_CODE_ATTEMPT || 0) - attempts);
  }, [attempts]);

  useEffect(() => {
    if (onErrorHappened) {
      if (remainingAttempts === 0 && !postingCode) {
        onErrorHappened(VerifyMessages.Exceeded);
      } else if (emailErrorText) {
        onErrorHappened(emailErrorText);
      } else if (codeErrorText) {
        onErrorHappened(codeErrorText);
      }
    }
  }, [onErrorHappened, remainingAttempts, postingCode, emailErrorText, codeErrorText]);

  const handleSendEmail = () => {
    if (!sendingEmail) {
      const fieldValue: FieldValue = {
        autoComplete: 'false',
        fieldDefinition: JSON.stringify({ MaskType: TextMaskTypes.EMAIL, ValidationTitle: 'E-mail' }),
        fieldType: FormFieldTypes.TEXT,
        isRequired: true,
        options: [],
        validationError: false,
        validationErrorText: '',
        questionId: 1,
        ordinal: 1,
        value: email,
        questionGroupId: 1,
        questionGroupOrdinal: 1,
        label: '',
      };
      const result: ValidationResult = validateFieldValue(fieldValue);
      setEmailErrorText(result.validationErrorText as VerifyMessages);
      if (!result.validationError) {
        setSendingEmail(true);
      }
    }
  };

  const handleVerifyCode = () => {
    if (!postingCode && !preventAttempt) {
      let noSpace = code.replaceAll(' ', '');
      if (!isNaN(noSpace as any)) {
        setCodeErrorText(undefined);
        setAttempts(attempts + 1);
        setPostingCode(true);
      } else {
        setCodeErrorText(VerifyMessages.InvalidCode);
      }
    } else if (preventAttempt) {
      setPostingCode(false);
    }
  };

  return (
    <>
      <Box className="email-verify-form">
        <h1>Resume Session</h1>
        {!verifyCodeView && (
          <>
            <Typography color="textPrimary">
              Enter your email address below and we'll send you a verification code to pick up where you left off.
            </Typography>
            <Box className="email-row">
              <TextField
                fullWidth
                style={{ marginRight: '.4rem' }}
                id={'EmailInput'}
                variant="filled"
                type={'email'}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendEmail();
                  }
                }}
                value={email}
              />
              {Boolean(emailErrorText) && <HeartlandCodeErrorText>{emailErrorText}</HeartlandCodeErrorText>}
              {submitTimeout ? (
                <HeartlandCodeErrorText>{`You can request a new code in ${timeoutCounter} seconds.`}</HeartlandCodeErrorText>
              ) : (
                ''
              )}
            </Box>
            <Button
              variant="contained"
              style={{ width: '60%', marginTop: '10px' }}
              color="primary"
              disabled={submitTimeout}
              onClick={() => handleSendEmail()}>
              {sendingEmail ? (
                <>
                  <Box className="email-button-loader" />
                </>
              ) : (
                'Send Code'
              )}
            </Button>
          </>
        )}

        {verifyCodeView && (
          <>
            <Typography className="email-code-caption" color="textPrimary">
              We've sent you a verification code.
              <br />
              Enter it below.
            </Typography>
            <HeartlandCodeInput
              className="email-code-input"
              id={'EmailCodeInput'}
              value={code}
              placeholder="000 000"
              inputProps={{ inputMode: 'numeric' }}
              onChange={(e) => {
                if (!postingCode) {
                  let noSpaces = e.target.value.replaceAll(' ', '');
                  if (
                    !isNaN(noSpaces as any) &&
                    noSpaces.indexOf('.') === -1 &&
                    e.target.value.trimStart().length < 8
                  ) {
                    if (noSpaces.length === 6) {
                      setCode(noSpaces.substring(0, 3) + ' ' + noSpaces.substring(3, 6));
                      handleVerifyCode();
                    } else setCode(e.target.value.trimStart());
                  }
                }
              }}></HeartlandCodeInput>
            {Boolean(codeErrorText) && <HeartlandCodeErrorText>{codeErrorText}</HeartlandCodeErrorText>}
            {remainingAttempts > 0 && attempts > 0 && !postingCode ? (
              <HeartlandCodeErrorText>{`You have ${remainingAttempts} attempt${
                remainingAttempts > 1 ? 's' : ''
              } left.`}</HeartlandCodeErrorText>
            ) : (
              ''
            )}
            {remainingAttempts === 0 && !postingCode ? (
              <HeartlandCodeErrorText>{VerifyMessages.Exceeded}</HeartlandCodeErrorText>
            ) : (
              ''
            )}
            <Button
              variant="contained"
              disabled={remainingAttempts === 0}
              style={{ width: '60%' }}
              color="primary"
              onClick={() => handleVerifyCode()}>
              {postingCode || successDelay ? (
                <>
                  {postingCode ? (
                    <Box className="email-button-loader" />
                  ) : (
                    <Icon style={{ color: '#fff' }} component={CheckIcon} />
                  )}
                </>
              ) : (
                'Continue'
              )}
            </Button>
            <Box
              className="email-didnt-send"
              color="primary.main"
              onClick={() => {
                setVerifyCodeView(false);
                setCode('');
                setCodeErrorText(undefined);
              }}>
              Didn't get an email?
            </Box>
          </>
        )}

        {showStartNewApplication && onStartNewApplication && (
          <Box
            className="email-didnt-send"
            color="primary.main"
            onClick={() => {
              onStartNewApplication();
            }}>
            Start a new application
          </Box>
        )}
      </Box>
    </>
  );
};

export default ResumeVerificationCodeForm;
