export enum VerifyMessages {
  TooManyRequests = 'You cannot request a new Verification Code at this time. Please try again later.',
  Forbidden = 'You cannot request a new Verification Code at this time.',
  CouldntVerify = "Couldn't verify code.",
  InvalidCode = 'Please enter a 6 digit code.',
  Exceeded = 'You have exceeded the maximum allowed attempts.',
  Unauthorized = 'Unauthorized Error',
  ServerException = 'Server Exception Error',
  TypedIncorrect = 'Check your email is typed correctly and try again.',
}
