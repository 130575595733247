import { Box } from '@mui/material';

import { ValidationError } from '../../types/ValidationResponse';

import styles from './index.module.css';

type Props = {
  errorMessage: Array<ValidationError>;
};

const ValidationErrorList = ({ errorMessage }: Props) => {
  if (errorMessage.length === 0) return null;

  return (
    <Box className={styles.errorInfoContainer}>
      <Box className={styles.errorInfo}>
        <Box className={styles.errorInfoItem}>
          Validation issues
          {errorMessage.map((error, index) => (
            <p key={index} data-testid="validation-error">
              {error.issue}
            </p>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export { ValidationErrorList };
