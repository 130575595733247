import { apiFunctions } from '..';

const apiUrl = '/Document';

export async function downloadSessionDocument(documentType: string, locationUid?: string) {
  return await apiFunctions.apiPost(apiUrl + '/DownloadSessionDocument',
    {
      locationUid,
      documentType
    }, null, true);
}
