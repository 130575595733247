import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '../../../../utility/modal';
import { getOnDemandCustomElementProps, screenUtils } from '../../../../utility/screenUtils';

import styles from './index.module.css';

interface Props {
  isOpen: boolean;
  onContinue: () => void;
  onClose: () => void;
}

export function ConfirmModal({ isOpen, onContinue, onClose }: Props) {
  const isMobile = screenUtils.DeviceIsMobile();

  return (
    <Modal show={isOpen} fullScreen={isMobile} size="md" closeModal={onClose} hideTitle onlyCloseButton title="">
      <Box className={styles.container}>
        <h1>We noticed that you have a partner application open</h1>
        <Box className={styles.subTitle} color="text.secondary">
          Before you continue, please keep in mind that starting a new application may cause issues with your existing
          partner integrations.
        </Box>
        <Box className={styles.buttonContainer}>
          <Button
            variant="contained"
            onClick={onClose}
            {...getOnDemandCustomElementProps('btnContinueOnThisTab', 'Take me back')}>
            Take me back
          </Button>
          <Button
            variant="outlined"
            onClick={onContinue}
            {...getOnDemandCustomElementProps('btnStartNewApplication', 'Start a new application')}>
            Start a new application
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
