import { startTransition, useCallback, useEffect, useState } from 'react';

import SuccessIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CircleCheckIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, FormControl, TextField, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { inputValidation } from '../../../../../api/inputValidation';
import { queryClient } from '../../../../../App';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { QuestionProps } from '../../../../../types/form';
import { PhaseQueries } from '../../../../../types/queries';
import { InputValidationResult, InputValidationStatus, InputValidationType } from '../../../../../types/verification';
import NumberInput from '../../../../../utility/numberInput';
import { screenUtils } from '../../../../../utility/screenUtils';
import { formHelperService } from '../../../services';

const BankRoutingValidator = ({
  label,
  value,
  onChange,
  fieldDefinitionJson,
  fieldType,
  hideLabel = false,
}: QuestionProps) => {
  const theme = useTheme<OneXThemePalette>();
  const isMobile = screenUtils.DeviceIsMobile();

  const [localValue, setLocalValue] = useState<string | null>(value?.value);
  const [hasRoutingChanged, setHasRoutingChanged] = useState(false);

  const { data, refetch, isFetching } = useQuery<InputValidationResult>(
    PhaseQueries.ROUTING_NUMBER_VALIDATION,
    async () =>
      await inputValidation({
        inputValue: localValue ?? '',
        inputValidationType: InputValidationType.BANK_ACCOUNT_ROUTING_NUMBER,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      enabled: false,
      keepPreviousData: false,
      onSuccess: () => {
        onChange(value.questionId, localValue, true);
      },
    },
  );
  const isRisky = data?.validationStatus === InputValidationStatus.WARNING;

  const getIconDisplay = useCallback(() => {
    if (isFetching) return <CircularProgress size={15} color="primary" />;

    if (data?.inputValue === localValue) {
      if (data?.validationStatus === InputValidationStatus.VALID)
        return <SuccessIcon data-testid="valid-icon" fontSize="medium" color="success" />;
      if (data?.validationStatus === InputValidationStatus.INVALID)
        return <DangerousIcon data-testid="invalid-icon" fontSize="medium" color="error" />;
      if (isRisky)
        return (
          <WarningIcon
            data-testid="warning-icon"
            fontSize="medium"
            color="warning"
            sx={{ color: theme.palette.primary.accentPrimary10 }}
          />
        );
    }

    return null;
  }, [
    data?.inputValue,
    data?.validationStatus,
    isFetching,
    isRisky,
    localValue,
    theme.palette.primary.accentPrimary10,
  ]);

  useEffect(() => {
    let timer: any = null;
    if (Boolean(localValue) && localValue !== data?.inputValue && localValue?.length === 9 && isEmpty(data?.err)) {
      timer = setTimeout(() => {
        refetch();
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [data?.err, data?.inputValue, localValue, onChange, refetch, value, value.questionId]);

  return (
    <Box width="100%">
      <FormControl fullWidth>
        <TextField
          variant="filled"
          id={fieldDefinitionJson?.Properties[0].PropertyKey}
          label={hideLabel ? null : label}
          fullWidth
          required={value.isRequired}
          error={!isEmpty(value.validationErrorText)}
          helperText={value.validationErrorText}
          inputProps={{
            ...routingNumberInputProps,
            ...formHelperService.getCustomInputProps(fieldType, fieldDefinitionJson.Properties[0].PropertyKey, label),
          }}
          data-testid="routing-number"
          InputLabelProps={{
            shrink: true,
            style: (label ?? '').length > 43 && isMobile ? { whiteSpace: 'normal', fontSize: '.8rem' } : {},
          }}
          InputProps={{
            endAdornment: getIconDisplay(),
            inputComponent: NumberInput as any,
          }}
          onChange={(e) => {
            setLocalValue(e.target.value);
            queryClient.removeQueries(PhaseQueries.ROUTING_NUMBER_VALIDATION);
            startTransition(() => onChange(value.questionId, e.target.value, false));
            setHasRoutingChanged(true);
          }}
          onBlur={() => {
            onChange(value.questionId, localValue, hasRoutingChanged);
          }}
          value={localValue}
          autoComplete={'on'}
          sx={{
            '& .MuiFormHelperText-root.Mui-error': { color: isRisky ? theme.palette.warning.text : '' },
            '& .MuiFilledInput-root.Mui-error': {
              border: isRisky ? `1px solid ${theme.palette.warning.border}` : '',
            },
          }}
        />

        <Box
          pt={2}
          className={'fade-in'}
          sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          display={Boolean(data?.validationProperties?.BankName) && Boolean(localValue) ? 'flex' : 'none'}>
          <CircleCheckIcon />
          <Box data-testid="bank-name" pl={1}>
            {data?.validationProperties?.BankName ?? 'not found'}
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
};

const routingNumberInputProps = {
  maxLength: 9,
  max: 999999999,
  min: 10000000,
  minLength: 9,
  decimalPlaces: 0,
  allowLeadingZeros: true,
  thousandSeparator: false,
  allowOverflow: true,
};

export { BankRoutingValidator };
