import CaretRightIcon from '@mui/icons-material/ChevronRight';
import { alpha, Box, Card, CardContent, Grid, useTheme } from '@mui/material';

import { UserClientSession } from '../..';
import { OneXThemePalette } from '../../../../types/enums/theme';
import { convertStringToDate } from '../../../../utility';
import { UserSessionCardItem, UserSessionCardStatus } from '../../components';

import style from './index.module.css';

type Props = {
  userClientSession: UserClientSession;
};

export function UserSessionCard({ userClientSession }: Props) {
  const theme = useTheme<OneXThemePalette>();

  return (
    <Card
      className={style.container}
      sx={{
        border: 2,
        borderColor: theme.palette.primary.bgInfo,
        '&:hover': {
          borderColor: alpha(theme.palette.primary.bgSidebarIconHover, 0.4),
        },
      }}
      color="primary">
      <CardContent className={style.cardContent}>
        <Grid container spacing={1} className={style.gridContainer}>
          <Grid item xs={6} className={style.gridItem}>
            <UserSessionCardItem
              label="Last activity"
              value={convertStringToDate(`${userClientSession.lastUpdatedUTC}Z`, "M/d/yyyy 'at' h:mmaaa")}
            />
            <UserSessionCardItem
              label="Status"
              value={<UserSessionCardStatus status={userClientSession.statusDescription} />}
            />
          </Grid>
          <Grid item xs={6} className={style.gridItem}>
            {userClientSession.productsOfInterest && (
              <UserSessionCardItem label="Product of interest" value={userClientSession.productsOfInterest} />
            )}
            {userClientSession.companyName && (
              <UserSessionCardItem label="DBA Name" value={userClientSession.companyName} />
            )}
            {userClientSession.workingWithName && (
              <UserSessionCardItem label="Working with" value={userClientSession.workingWithName} />
            )}
            {userClientSession.partnerName && (
              <UserSessionCardItem label="Partner" value={userClientSession.partnerName} />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Box
        className={style.caretIconContainer}
        sx={{
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        }}>
        <CaretRightIcon color="primary" />
      </Box>
    </Card>
  );
}
