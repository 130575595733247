import { apiService, FATAL_STATUS_ERROR } from '../../../services';
import { PostResponse, UserProperties } from '../../../types';
import { SESSION_ERROR_MESSAGE } from '../../../types/enums';
import { currentSessionContextService } from '../../../utility/sessionContext';
import { CalculateQuestionVisibilitiesRequest, CalculateQuestionVisibilitiesResponse } from '../types';

interface IQuestionVisibilityService {
  calculateQuestionVisibilities: (
    userProperties: UserProperties,
  ) => Promise<PostResponse<CalculateQuestionVisibilitiesResponse> | undefined>;
}

class QuestionVisibilityService implements IQuestionVisibilityService {
  private pathCalculateQuestionVisibilities = 'QuestionVisibility/CalculateQuestionVisibilities';

  async calculateQuestionVisibilities(
    userProperties: UserProperties,
  ): Promise<PostResponse<CalculateQuestionVisibilitiesResponse> | undefined> {
    try {
      const sessionToken = currentSessionContextService.currentSessionToken;

      const body = {
        sessionToken,
        userProperties,
      } as CalculateQuestionVisibilitiesRequest;

      const response = await apiService.post<
        CalculateQuestionVisibilitiesRequest,
        CalculateQuestionVisibilitiesResponse
      >(this.pathCalculateQuestionVisibilities, body);

      return response;
    } catch (error) {
      console.error(error);

      return { status: FATAL_STATUS_ERROR, error: SESSION_ERROR_MESSAGE.ERROR, errorText: (error as any)?.message };
    }
  }
}

const questionVisibilityService = new QuestionVisibilityService();

export type { IQuestionVisibilityService };
export { QuestionVisibilityService, questionVisibilityService };
