import { get, post } from './auth';
import { StartupSessionError } from './errors';
import { queryClient } from '../App';
import { GLOBAL_ERROR_MESSAGE, SESSION_ERROR_MESSAGE } from '../types/enums';
import { PhaseEvent } from '../types/phase';
import { Session, SessionCreationStackModel, StartSessionResponse } from '../types/session';
import { trackEvent } from '../utility/eventTracking';
import { currentSessionContextService } from '../utility/sessionContext';
import { trackingDetectionUtility } from '../utility/trackingDetector';
import { userClientContextService } from '../utility/userClientContext';

const apiUrl = import.meta.env.VITE_API_URL ?? '';

export const currentClientRoutingModel = currentSessionContextService.getStartupClientRoutingModel();

export const endpoints = {
  START_SESSION: `${apiUrl}/OnDemandSession/StartSession`,
  CONTINUE_SESSION: `${apiUrl}/OnDemandSession/ContinueSession`,
  GET_USER_CLIENT_SESSIONS: `${apiUrl}/UserClient/GetUserClientSessions`,
  RESUME_USER_CLIENT_SESSION: `${apiUrl}/OnDemandSession/ResumeUserClientSession`,
  RESUME_SESSION_WITH_CODE: `${apiUrl}/OnDemandSession/ResumeSessionWithCode`,
  RESUME_SESSION_CODE: `${apiUrl}/VerificationCode/RequestResumeSessionCode`,
  LOAD_USER_CLIENT: `${apiUrl}/UserClient/LoadUserClient`,
};

export const createSessionWithParams = async (queryStringParameters: any) => {
  try {
    const currentAttributions = userClientContextService.currentUserAttributions;
    const tracking = await trackingDetectionUtility.checkTrackingDisabled();
    const body = {
      queryStringParameters,
      attributions: currentAttributions?.attributions || [],
      allowsTracking: tracking.allowsTracking,
      disabledTrackingMessage: tracking.disabledTrackingMessage,
    };

    const response = await post(endpoints.START_SESSION, body);

    if (response.ok) {
      const startSessionResponse = (await response.json()) as StartSessionResponse;

      const isReferral = startSessionResponse.onDemandConfig?.sessionsCreatedByPartner;
      const creationModel = {
        queryStringParams: startSessionResponse.sessionInfo.creationParams ?? window.location.search,
        isReferral: isReferral,
      } as SessionCreationStackModel;

      userClientContextService.pushSessionCreationContext(creationModel);

      if (startSessionResponse) {
        userClientContextService.pushUserAttributions(startSessionResponse.attributions);

        currentSessionContextService.currentSession = startSessionResponse;

        //Clear URL if continuation code or session is not created by a partner
        if (!isReferral) {
          window.history.replaceState({}, document.title, window.location.origin);
        }
      }

      return startSessionResponse;
    } else {
      const nonOkText = await response.text();
      const statusCode = response.status;

      throw new StartupSessionError(GLOBAL_ERROR_MESSAGE.START_SESSION_FAILURE, nonOkText, statusCode);
    }
  } catch (error) {
    if (error instanceof StartupSessionError) {
      throw error;
    } else {
      console.error(error);

      throw new StartupSessionError(GLOBAL_ERROR_MESSAGE.START_SESSION_FAILURE);
    }
  }
};

export const continueSessionWithCode = async (continuationCode: string) => {
  try {
    const tracking = await trackingDetectionUtility.checkTrackingDisabled();
    const response = await post(endpoints.CONTINUE_SESSION, {
      continuationCode,
      allowsTracking: tracking.allowsTracking,
      disabledTrackingMessage: tracking.disabledTrackingMessage,
    });

    if (response.ok) {
      const result = (await response.json()) as StartSessionResponse;

      if (result) {
        userClientContextService.pushUserAttributions(result.attributions);

        currentSessionContextService.currentSession = result;

        //Clear URL if continuation code
        window.history.replaceState({}, document.title, window.location.origin);
      }

      return result;
    } else {
      const nonOkText = await response.text();
      const statusCode = response.status;

      throw new StartupSessionError(GLOBAL_ERROR_MESSAGE.CONTINUE_SESSION_FAILURE, nonOkText, statusCode);
    }
  } catch (error) {
    if (error instanceof StartupSessionError) {
      throw error;
    } else {
      console.error(error);

      throw new StartupSessionError(GLOBAL_ERROR_MESSAGE.CONTINUE_SESSION_FAILURE);
    }
  }
};

export const resumeClientSessionBySessionUid = async (sessionUid?: string | undefined) => {
  try {
    const tracking = await trackingDetectionUtility.checkTrackingDisabled();
    const previousSession = userClientContextService.getLastSessionContext();

    const response = await post(endpoints.RESUME_USER_CLIENT_SESSION, {
      targetSessionUid: sessionUid ?? previousSession?.sessionUid,
      allowsTracking: tracking.allowsTracking,
      disabledTrackingMessage: tracking.disabledTrackingMessage,
    });

    if (response.ok) {
      const result = (await response.json()) as StartSessionResponse;

      if (result) {
        updateApplicationContexts(result);
      }

      return result;
    } else {
      const nonOkText = await response.text();
      const statusCode = response.status;

      throw new StartupSessionError(GLOBAL_ERROR_MESSAGE.RESUME_CLIENT_SESSION_FAILURE, nonOkText, statusCode);
    }
  } catch (error) {
    if (error instanceof StartupSessionError) {
      throw error;
    } else {
      console.error(error);

      throw new StartupSessionError(GLOBAL_ERROR_MESSAGE.RESUME_CLIENT_SESSION_FAILURE);
    }
  }
};

export const updateApplicationContexts = (result: StartSessionResponse) => {
  const isReferral = result.onDemandConfig?.sessionsCreatedByPartner;

  userClientContextService.pushUserAttributions(result.attributions);

  currentSessionContextService.currentSession = result;

  //Clear URL if continuation code or session is not created by a partner
  if (isReferral !== true) {
    window.history.replaceState({}, document.title, window.location.origin);
  }
};

function getFileName(contentDispositionHeader: string) {
  if (contentDispositionHeader && contentDispositionHeader.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(contentDispositionHeader);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
}

export async function apiPost(url: string, body?: any, headers?: any, fromBlob?: boolean) {
  let data: any = { err: null };
  let sessionData: Session | undefined = queryClient.getQueryData(currentClientRoutingModel.clientRoutingAction);
  const sessionToken = currentSessionContextService.currentSessionToken;

  const postBody = Object.assign(body, {
    sessionToken: sessionToken ?? sessionData?.sessionToken,
  });

  await post(apiUrl + url, postBody, headers).then(async function (response: any) {
    if (response.status === 204) {
      //do nothing for 204.
    } else {
      if (response.status === 400) {
        const txt = await response.text();
        if (txt.includes(SESSION_ERROR_MESSAGE.CAPTCHA_FAILED)) {
          data.err = SESSION_ERROR_MESSAGE.CAPTCHA_FAILED;
        } else {
          //log general 400 errors to GTM
          trackEvent(sessionData?.payload?.phaseType, sessionData?.payload, PhaseEvent.ERROR, {
            error: txt,
            apiUrl: apiUrl + url,
          });
          data.err = SESSION_ERROR_MESSAGE.ERROR;
        }
      } else if (response.status === 401) {
        data.err = SESSION_ERROR_MESSAGE.UNAUTHORIZED;
      } else if (response.status !== 200) {
        data.err = SESSION_ERROR_MESSAGE.ERROR;
      } else {
        data = !fromBlob
          ? await response.json()
          : { fileName: getFileName(response.headers.get('content-disposition')), blob: await response.blob() };
      }
    }
  });
  return data;
}

//this post returns the entire response, and does no error handling.
export async function apiPostDangerously(url: string, body: any, header?: any) {
  let data;
  let sessionData: Session | undefined = queryClient.getQueryData(currentClientRoutingModel.clientRoutingAction);

  const postBody = Object.assign(body, {
    sessionToken: sessionData?.sessionToken,
  });

  await post(apiUrl + url, postBody, header)
    .then(async function (response) {
      data = response;
    })
    .catch(function (error) {
      console.log('post error occurred', error);
      throw new Error('post error occurred');
    });
  return data;
}

export async function apiGet(url: string, header?: any): Promise<any> {
  let data: any;

  await get(apiUrl + url, header)
    .then(async function (response) {
      if (response.status !== 200) {
        if (response.status === 400) {
          const txt = await response.text();
          let sessionData: Session | undefined = queryClient.getQueryData(
            currentClientRoutingModel.clientRoutingAction,
          );
          //log general 400 errors to GTM
          trackEvent(sessionData?.payload?.phaseType, sessionData?.payload, PhaseEvent.ERROR, {
            error: txt,
            apiUrl: apiUrl + url,
          });
          throw new Error(GLOBAL_ERROR_MESSAGE.ERROR);
        } else if (response.status === 401) throw new Error(GLOBAL_ERROR_MESSAGE.UNAUTHORIZED);
        else throw new Error('API GENERIC ERROR'); //THROW ERROR;
      } else {
        data = await response.json();
      }
    })
    .catch(function (error) {
      console.log('get error occurred', error);
      throw new Error('get error occurred');
    });

  return data;
}

export const apiFunctions = {
  apiPost,
  apiGet,
  post,
  get,
  apiPostDangerously,
};
