import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { alpha, createTheme, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

import { getQueryParams } from '..';
import { vegaMuiTheme, VegaMuiThemeTypes } from '../../themes/theme';
import {
  bgPageDark,
  bgPageLight,
  scrollBgDark,
  scrollBgLight,
  shadowBgDark1,
  shadowBgDark2,
  shadowBgLight1,
  shadowBgLight2,
} from '../../types';
import { useIsFrame } from '../screenUtils';
import { currentSessionContextService } from '../sessionContext';

interface OnDemandThemeContextProps {
  defaultTheme?: VegaMuiThemeTypes;
  children: ReactNode;
}

interface OnDemandThemeContextValue {
  theme: VegaMuiThemeTypes;
  forcedTheme: VegaMuiThemeTypes;
  isFrame: boolean;
  handleThemeChange: (newTheme: VegaMuiThemeTypes) => void;
}

const OnDemandThemeContext = createContext<OnDemandThemeContextValue | undefined>(undefined);

const license = import.meta.env.VITE_MUI_DATAGRID_PRO_KEY ? import.meta.env.VITE_MUI_DATAGRID_PRO_KEY.toString() : '';
LicenseInfo.setLicenseKey(license);

export const OnDemandThemeProvider: React.FC<OnDemandThemeContextProps> = ({
  defaultTheme = VegaMuiThemeTypes.Light,
  children,
}) => {
  const [theme, setTheme] = useState<VegaMuiThemeTypes>(defaultTheme);

  const [enableForcedTheme] = useState<boolean>(true);
  const [isFrame] = useIsFrame();

  const queryString: any = getQueryParams();

  const forcedTheme = useMemo(() => {
    return (queryString?.theme === VegaMuiThemeTypes.Dark || queryString?.theme === VegaMuiThemeTypes.Light) &&
      enableForcedTheme
      ? queryString?.theme
      : undefined;
  }, [queryString, enableForcedTheme]);

  const setRootProperties = (theme: VegaMuiThemeTypes) => {
    var root = document.documentElement;

    root.style.setProperty('--bgPage', bgPageLight);
    root.style.setProperty('--scrollbg', scrollBgLight);
    root.style.setProperty('--outlineShadow1', shadowBgLight1);
    root.style.setProperty('--outlineShadow2', shadowBgLight2);

    if (theme === VegaMuiThemeTypes.Dark) {
      root.style.setProperty('--bgPage', bgPageDark);
      root.style.setProperty('--scrollbg', scrollBgDark);
      root.style.setProperty('--outlineShadow1', shadowBgDark1);
      root.style.setProperty('--outlineShadow2', shadowBgDark2);
    }
  };

  const handleThemeChange = (newTheme: VegaMuiThemeTypes) => {
    let targetTheme: VegaMuiThemeTypes =
      theme === VegaMuiThemeTypes.Light ? VegaMuiThemeTypes.Dark : VegaMuiThemeTypes.Light;
    if (newTheme) {
      targetTheme = newTheme === VegaMuiThemeTypes.Dark ? VegaMuiThemeTypes.Dark : VegaMuiThemeTypes.Light;
    }

    setTheme(targetTheme);
    currentSessionContextService.theme = targetTheme;
    setRootProperties(targetTheme);
  };

  useEffect(() => {
    const newTheme =
      currentSessionContextService.theme !== VegaMuiThemeTypes.Dark ? VegaMuiThemeTypes.Light : VegaMuiThemeTypes.Dark;

    setTheme(newTheme);

    let isLight = currentSessionContextService.theme !== VegaMuiThemeTypes.Dark;
    setRootProperties(isLight ? VegaMuiThemeTypes.Light : VegaMuiThemeTypes.Dark);
  }, []);

  const contextValue: OnDemandThemeContextValue = {
    theme,
    forcedTheme,
    isFrame,
    handleThemeChange,
  };

  return (
    <OnDemandThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={createTheme(vegaMuiTheme(forcedTheme ?? theme, alpha, {}))}> {children}</ThemeProvider>
    </OnDemandThemeContext.Provider>
  );
};

export const useOnDemandTheme = (): OnDemandThemeContextValue => {
  const context = useContext(OnDemandThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an OnDemandThemeProvider');
  }

  return context;
};
