import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { Information } from '../..';
import { HeartlandCheckboxLabel, HeartlandErrorText } from '../../../../../themes/overrides';
import { FieldValue, FormFieldOption, QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  label: string;
  value: FieldValue;
  options: Array<FormFieldOption>;
}

const KVPCheckboxToggle: React.FC<Props> = ({
  fieldDefinitionJson,
  helperText,
  label,
  onChange,
  options,
  value,
  infoHtml,
  fieldType,
  fieldSubType,
  hideLabel = false,
}) => {
  const optionOne = options.find((_) => _.ordinal === 1);
  const optionTwo = options.find((_) => _.ordinal === 2);

  const optionSelected = optionOne?.value === value?.value ? optionOne : optionTwo;
  const isChecked = value?.value === optionOne?.value ? true : false;

  const handleChanged = (event: any) => {
    onChange(value.questionId, event?.target?.checked ? optionOne?.value : optionTwo?.value, true, '');
  };

  const id = fieldDefinitionJson?.Properties[0].PropertyKey;

  return (
    <FormControl fullWidth>
      {!hideLabel && (
        <HeartlandCheckboxLabel
          shrink
          htmlFor={fieldDefinitionJson?.Properties[0].PropertyKey}
          required={value.isRequired}>
          {label} {infoHtml && <Information infoHtml={infoHtml} />}
        </HeartlandCheckboxLabel>
      )}

      <FormControlLabel
        classes={{ label: 'checkbox-label' }}
        color="text.secondary"
        id={id}
        data-testid={id}
        value={optionSelected?.value}
        label={optionOne?.label}
        role="checkbox"
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleChanged}
            inputProps={
              {
                'data-testid': `${id}-checkbox`,
                ...formHelperService.getCustomInputProps(
                  fieldType,
                  fieldDefinitionJson?.Properties[0].PropertyKey,
                  label,
                  fieldSubType,
                ),
              } as any
            }
          />
        }
      />

      {value.validationErrorText && <HeartlandErrorText>{value.validationErrorText}</HeartlandErrorText>}
      {!isEmpty(helperText) && <FormHelperText dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>}
    </FormControl>
  );
};

export { KVPCheckboxToggle };
