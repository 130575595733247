import { apiFunctions, endpoints } from '..';
import { UserClientSession } from '../../session/swapSession';
import { PhaseNavigationAction, PhaseResponse } from '../../types/phase';
import { ConvertSessionResponse, ResumeSessionScope, StartSessionResponse } from '../../types/session';
import { trackingDetectionUtility } from '../../utility/trackingDetector';
import { StartupSessionError } from '../errors';

const sessionApiUrl = '/OnDemandSession';

export async function requestResumeSessionCode(
  onDemandConfigId: number,
  scope: ResumeSessionScope,
  aaid: string,
  email: string,
  sessionToken?: string,
  targetSessionUid?: string,
) {
  return await apiFunctions.post(endpoints.RESUME_SESSION_CODE, {
    onDemandConfigId: onDemandConfigId,
    scope: scope,
    aaid: aaid,
    email: email,
    sessionToken: sessionToken,
    targetSessionUid: targetSessionUid,
  });
}

export async function resumeSessionWithCode(
  onDemandConfigId: number,
  scope: ResumeSessionScope,
  aaid: string,
  email: string,
  code: string,
  sessionToken?: string,
  targetSessionUid?: string,
) {
  const tracking = await trackingDetectionUtility.checkTrackingDisabled();
  return await apiFunctions.post(endpoints.RESUME_SESSION_WITH_CODE, {
    onDemandConfigId: onDemandConfigId,
    scope: scope,
    aaid: aaid,
    email: email,
    code: code,
    sessionToken: sessionToken,
    targetSessionUid: targetSessionUid,
    allowsTracking: tracking.allowsTracking,
    disabledTrackingMessage: tracking.disabledTrackingMessage,
  });
}

export async function getSessionAddress(sessionToken?: string) {
  return await apiFunctions.apiPostDangerously(sessionApiUrl + '/GetSessionAddress', {
    sessionToken: sessionToken,
  });
}

export const voluntaryOptOut = async (sessionToken: string): Promise<PhaseResponse> => {
  const data = await apiFunctions.apiPost(sessionApiUrl + '/VoluntaryOptOut', {
    sessionToken: sessionToken,
  });

  if (data.err) {
    return data;
  }

  return {
    navigationAction: PhaseNavigationAction.NEXT_PAGE,
    //The following three properties will not come back because only a static phase result is returned,
    //but we need to return a full phase response result so the code doesn't error
    onDemandConfig: data?.onDemandConfig,
    sessionInfo: data?.sessionInfo,
    sessionUid: data?.sessionUid,
    ///The data payload is a static phase here
    payload: data,
    sessionToken: sessionToken,
  } as PhaseResponse;
};

export const expireSession = async () => {
  await apiFunctions.apiPost(sessionApiUrl + '/ExpireSession', {});
};

export const convertSession = async (targetConfigId: number): Promise<ConvertSessionResponse> => {
  return await apiFunctions.apiPost(sessionApiUrl + '/ConvertSession', { targetConfigId });
};

export const resumeUserClientSession = async (targetSessionUid: string): Promise<StartSessionResponse> => {
  const tracking = await trackingDetectionUtility.checkTrackingDisabled();
  const response = await apiFunctions.post(endpoints.RESUME_USER_CLIENT_SESSION, {
    targetSessionUid,
    allowsTracking: tracking.allowsTracking,
    disabledTrackingMessage: tracking.disabledTrackingMessage,
  });

  if (response.ok) {
    const result = (await response.json()) as StartSessionResponse;

    return result;
  } else {
    const nonOkText = await response.text();

    throw new StartupSessionError(nonOkText);
  }
};

export const getUserClientSessions = async (): Promise<UserClientSession[]> => {
  const response = await apiFunctions.post(endpoints.GET_USER_CLIENT_SESSIONS, {});

  if (response.ok) {
    const result = (await response.json()) as UserClientSession[];

    return result;
  }

  return [];
};
