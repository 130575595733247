import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { ApiResponseError } from '../../../api/errors';
import { authorizationService } from '../../../features/authorization/services/AuthorizationService';
import { RefreshClientTokenError, UserClient } from '../../../features/authorization/types';
import { SessionQueries } from '../../../types/queries';
import { userClientContextService } from '../../userClientContext';

interface AuthUserClientContextProps {
  children: ReactNode;
}

interface AuthUserClientContextValue {
  isTabActive: boolean;
  isAuthenticated: boolean;
  token?: string;
  isError: Boolean;
  isFetching: boolean;
  hasClientSessions?: boolean;
  refreshClientTokenError?: RefreshClientTokenError;
}

const AuthUserClientContext = createContext<AuthUserClientContextValue | undefined>(undefined);

const staleTime = 10 * 60 * 1000; // 10 minutes;
const maximumRetries = 3;

export const AuthUserClientProvider: React.FC<AuthUserClientContextProps> = ({ children }) => {
  const [refreshClientTokenError, setRefreshClientTokenError] = useState<RefreshClientTokenError>();

  const {
    data: userClientData,
    isSuccess,
    isStale,
    isError,
    isFetching,
    refetch,
  } = useQuery<UserClient | undefined>(SessionQueries.USER_CLIENT, () => authorizationService.refreshToken(), {
    retry: maximumRetries,
    refetchOnWindowFocus: false,
    staleTime,
    onError(err) {
      if (err instanceof RefreshClientTokenError) {
        setRefreshClientTokenError(err);
      }
    },
    onSuccess: () => {
      setRefreshClientTokenError(undefined);
    },
    // initialData: () => {
    //   const previousClient = userClientContextService.currentUserClient;
    //   if (previousClient?.clientToken) return previousClient;
    //   return undefined;
    // },
    // initialDataUpdatedAt: () => userClientContextService.currentUserClientUpdatedAt,
  });

  const isAuthenticated = useMemo(
    () =>
      isSuccess &&
      Boolean(userClientData?.clientToken) &&
      Boolean(userClientContextService.currentUserClient?.clientToken),

    [userClientData?.clientToken, isSuccess],
  );

  const [isTabActive, setIsTabActive] = useState(true);

  useEffect(() => {
    const onFocus = () => {
      setIsTabActive(true);
    };

    const onBlur = () => {
      setIsTabActive(false);
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [refetch]);

  useEffect(() => {
    if (!isError && !isFetching && isStale && isTabActive) {
      refetch();
    }
  }, [isError, isFetching, isStale, isTabActive, refetch]);

  useEffect(() => {
    if (
      isError &&
      (refreshClientTokenError?.cause === ApiResponseError.Unauthorized ||
        refreshClientTokenError?.cause === ApiResponseError.BadRequest)
    ) {
      userClientContextService.currentUserClient = undefined;

      refetch();
    }
  }, [isError, refetch, refreshClientTokenError?.cause]);

  const contextValue: AuthUserClientContextValue = {
    isTabActive,
    isAuthenticated,
    token: userClientData?.clientToken,
    isError,
    isFetching,
    hasClientSessions: userClientData?.clientSessionCount ? userClientData?.clientSessionCount > 0 : false,
    refreshClientTokenError,
  };

  return (
    <AuthUserClientContext.Provider value={contextValue}>
      {(isAuthenticated || (isError && !isFetching)) && children}
    </AuthUserClientContext.Provider>
  );
};

export const useAuthUserClient = (): AuthUserClientContextValue => {
  const context = useContext(AuthUserClientContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
