import { InputLabelProps } from '@mui/material';

import { screenUtils } from './screenUtils';
import { DeviceTypes } from '../types';

interface Props {
  children: any;
  showOn: DeviceTypes;
}

const ShowOnDevice: React.FC<Props> = ({ children, showOn }) => {
  const isMobile = screenUtils.DeviceIsMobile();

  if (showOn === DeviceTypes.Mobile && isMobile) return <>{children}</>;
  if (showOn === DeviceTypes.Desktop && !isMobile) return <>{children}</>;
  else return null;
};

export default ShowOnDevice;

export const GetMobileInputLabelProps = (labelContent: string): InputLabelProps => {
  return {
    shrink: true,
    style:
      (labelContent ?? '').length > 43
        ? { whiteSpace: 'normal', fontSize: '.8rem', marginTop: (labelContent ?? '').length > 75 ? -10 : 0 }
        : {},
  };
};
