import { useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { FormFieldOption, PartnerGpiSoftware, QuestionProps } from '../../../../../types/form';
import { KVPAutocomplete } from '../KVP/KVPAutocomplete';

import styles from './index.module.css';

const PartnerGpiSoftwareSelection: React.FC<QuestionProps> = ({
  label,
  fieldType,
  fieldDefinitionJson,
  onChange,
  value,
  referenceData,
  helperText,
}) => {
  let [revertedOption, setRevertedOption] = useState<string>('');

  let softwareVersionData = (referenceData ?? []) as Array<PartnerGpiSoftware>;
  let listOptions: Array<FormFieldOption> = softwareVersionData.map((x: PartnerGpiSoftware, i) => {
    return {
      label: x.Label || '',
      formFieldQuestionId: value.questionId,
      value: x.PartnerProductVersionId,
      id: i,
      isDefaultOption: false,
      ordinal: i,
      questions: [],
    };
  });

  let partnerRecommendedOption = softwareVersionData.find((x) => x.RecommendedOption?.toLocaleLowerCase() === 'true');
  let hasPartnerRecommendedOption = typeof partnerRecommendedOption !== 'undefined';

  let selectedOption = value?.value;
  let selectedPpvId = selectedOption?.value || '';

  let showRevertOption =
    revertedOption !== '' || // When checking the revert checkbox, the previous option is saved, allowing it to be recovered within the same phase by unchecking the same checkbox
    (hasPartnerRecommendedOption && partnerRecommendedOption?.PartnerProductVersionId !== selectedPpvId); // If there is a recommended option different than the one selected

  const handleChange = (questionId: number, value: any, shouldValidate: boolean) => {
    if (revertedOption !== '') setRevertedOption('');

    onChange(questionId, value, shouldValidate);
  };

  const handleRevertToggle = () => {
    if (revertedOption === '') {
      let recommendedValue = listOptions.find((x) => x.value === partnerRecommendedOption?.PartnerProductVersionId);

      setRevertedOption(selectedPpvId);
      onChange(value.questionId, recommendedValue, true);
    } else {
      let previousValue = listOptions.find((x) => x.value === revertedOption);

      setRevertedOption('');
      onChange(value.questionId, previousValue, false);
    }
  };

  return (
    <Box className={styles.MainContent}>
      <KVPAutocomplete
        label={label ?? ''}
        onChange={handleChange}
        value={value}
        fieldDefinitionJson={fieldDefinitionJson}
        options={listOptions}
        helperText={helperText}
        infoHtml={hasPartnerRecommendedOption ? fieldDefinitionJson.PrePopulatedOptionTooltip : ''}
        fieldType={fieldType}
      />
      {showRevertOption && (
        <FormControl>
          <FormControlLabel
            label={fieldDefinitionJson.RevertOptionVerbiage}
            control={<Checkbox color="primary" onChange={handleRevertToggle} checked={revertedOption !== ''} />}
          />
        </FormControl>
      )}
    </Box>
  );
};

export { PartnerGpiSoftwareSelection };
