import './index.css';

import { useState } from 'react';

import { FormControl, FormHelperText, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

import { Information } from '../../';
import { HeartlandErrorText } from '../../../../../themes/overrides';
import { FieldValue, QuestionProps } from '../../../../../types/form';
import { convertStringToDate, isEmpty } from '../../../../../utility';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  value: FieldValue;
}

const DateInput: React.FC<Props> = ({
  label,
  onChange,
  value,
  fieldDefinitionJson,
  helperText,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const [localValue, setLocalValue] = useState(value.value ?? '');
  const [hasChanged, setHasChanged] = useState(false);
  const relativeStartDate = fieldDefinitionJson?.RelativeStartDate;
  const relativeEndDate = fieldDefinitionJson?.RelativeEndDate;
  let minDate = convertStringToDate(fieldDefinitionJson?.Min);
  let maxDate = convertStringToDate(fieldDefinitionJson?.Max);

  //set min and max dates to the relatives dates over hard stamped dates
  if (relativeStartDate !== undefined && relativeStartDate) {
    minDate = dateFnsTz.format(dateFns.addDays(new Date(), relativeStartDate), 'yyyy-MM-dd', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  if (relativeEndDate) {
    maxDate = dateFnsTz.format(dateFns.addDays(new Date(), relativeEndDate), 'yyyy-MM-dd', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  // if we have min and max dates, makes sure there are no conflicts.
  if (minDate && maxDate) {
    const tempMinDate = dateFnsTz.utcToZonedTime(
      dateFns.parseISO(minDate),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    const tempMaxDate = dateFnsTz.utcToZonedTime(
      dateFns.parseISO(maxDate),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    //In case the config sets the maxdate on or before what the future start day would be, we ignore the max date.
    if (dateFns.isBefore(tempMaxDate, tempMinDate) || dateFns.isEqual(tempMaxDate, tempMinDate)) {
      maxDate = '';
    }
  }

  const getDefaultValue = () => {
    let defaultValue = fieldDefinitionJson?.DefaultValue;
    const relativeDefaultDate = fieldDefinitionJson?.RelativeDefaultDate;

    if (relativeDefaultDate !== undefined && relativeDefaultDate != null) {
      defaultValue = dateFnsTz.format(dateFns.addDays(new Date(), relativeDefaultDate), 'yyyy-MM-dd', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }

    if (defaultValue && minDate) {
      const tempMinDate = dateFnsTz.utcToZonedTime(
        dateFns.parseISO(minDate),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );

      const tempDefaultDate = dateFnsTz.utcToZonedTime(
        dateFns.parseISO(defaultValue),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );

      //set default date to min date if specified start date is before the min date
      if (dateFns.isBefore(tempDefaultDate, tempMinDate)) {
        defaultValue = minDate;
      }
    }

    if (defaultValue) {
      const defaultLocalValue = dateFnsTz
        .utcToZonedTime(dateFns.parseISO(defaultValue), Intl.DateTimeFormat().resolvedOptions().timeZone)
        .toISOString();

      setLocalValue(defaultLocalValue);
      onChange(value.questionId, defaultLocalValue, false);
    }

    return '';
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={
            hideLabel ? null : (
              <>
                {label}
                {infoHtml && <Information infoHtml={infoHtml} />}
              </>
            )
          }
          value={localValue ? localValue : getDefaultValue()}
          minDate={
            minDate
              ? dateFnsTz.utcToZonedTime(dateFns.parseISO(minDate), Intl.DateTimeFormat().resolvedOptions().timeZone)
              : undefined
          }
          maxDate={
            maxDate
              ? dateFnsTz.utcToZonedTime(dateFns.parseISO(maxDate), Intl.DateTimeFormat().resolvedOptions().timeZone)
              : undefined
          }
          inputFormat={'MM/dd/yyyy'}
          views={['year', 'month', 'day']}
          onChange={(newValue: Date | null) => {
            let finalValue = newValue && dateFns.isValid(newValue) ? newValue.toISOString() : newValue;

            onChange(value.questionId, finalValue ?? '', false);
            setLocalValue(finalValue ?? '');
            setHasChanged(true);
          }}
          onAccept={(newValue: Date | null) => {
            //this is done via onChange, but this forces validation when they select an option.
            onChange(value.questionId, newValue?.toISOString() ?? '', true);
            setLocalValue(newValue?.toISOString() ?? '');
            setHasChanged(true);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              id={fieldDefinitionJson?.Properties[0].PropertyKey}
              data-testid={fieldDefinitionJson?.Properties[0].PropertyKey}
              required={value.isRequired}
              error={value.validationError}
              onBlur={() => {
                onChange(value.questionId, localValue, hasChanged);
              }}
              autoComplete={value.autoComplete}
              inputProps={{
                ...params.inputProps,
                ...formHelperService.getCustomInputProps(
                  fieldType,
                  fieldDefinitionJson.Properties[0].PropertyKey,
                  label,
                ),
              }}
            />
          )}
        />

        {value.validationErrorText && <HeartlandErrorText>{value.validationErrorText}</HeartlandErrorText>}

        {!isEmpty(helperText) && (
          <FormHelperText
            style={{ marginLeft: '12px' }}
            dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>
        )}
      </LocalizationProvider>
    </FormControl>
  );
};

export { DateInput };
