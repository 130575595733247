import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import {
  continueSessionWithCode,
  createSessionWithParams,
  currentClientRoutingModel,
  resumeClientSessionBySessionUid,
  updateApplicationContexts,
} from '../../api';
import { StartupSessionError } from '../../api/errors';
import { getUserClientSessions } from '../../api/session';
import { queryClient } from '../../App';
import { GLOBAL_ERROR_MESSAGE } from '../../types/enums';
import { PhaseEvent, PhaseNavigationAction, PhaseResponse, PhaseType, PhaseTypes } from '../../types/phase';
import { SessionQueries } from '../../types/queries';
import { ClientRoutingAction, Session, StartSessionResponse } from '../../types/session';
import { getQueryParams } from '../../utility';
import tracker, { initializeUserTracking } from '../../utility/eventTracking';
import { useAuthUserClient } from '../../utility/hooks';
import useChannel from '../../utility/hooks/useChannel';
import { currentSessionContextService } from '../../utility/sessionContext';
import { userClientContextService } from '../../utility/userClientContext';
import Return from '../return';
import SwapSession, { UserClientSession } from '../swapSession';
import { ApplicationOpenedModal } from '../swapSession/components/applicationOpenedModal';

interface SessionContextProps {
  children: ReactNode;
}

interface SessionContextValue {
  session: Session | undefined;
  showApplicationOpenAnotherTab: boolean;
  phaseHistory: Array<PhaseType>;
  isFetchingSession: boolean;
  isFetchingUserClientSessions: boolean;
  startupSessionError?: StartupSessionError;
  setShowApplicationOpenAnotherTab: (showApplicationOpenAnotherTab: boolean) => void;
  setLoadedPhase: (loadedPhase: PhaseTypes | undefined) => void;
  handleSessionAndPhase: (res: PhaseResponse, phaseHistoryLength: number) => void;
  setPhaseHistory: (phaseHistory: Array<PhaseType>) => void;
  handleRetry: () => Promise<void>;
}

const SessionContext = createContext<SessionContextValue | undefined>(undefined);

const maximumRetries = 3;

export const SessionProvider: React.FC<SessionContextProps> = ({ children }) => {
  const { isAuthenticated, isTabActive, hasClientSessions, isError } = useAuthUserClient();

  const [isFetchingFromUserAction, setIsFetchingFromUserAction] = useState(false);

  const [session, setSession] = useState<Session | undefined>();
  const [phaseHistory, setPhaseHistory] = useState<Array<PhaseType>>([]);
  const [isTrackingInitialized, setIsTrackingInitialized] = useState<boolean>(false);

  const [showApplicationOpenAnotherTab, setShowApplicationOpenAnotherTab] = useState(false);

  const [startupSessionError, setStartupSessionError] = useState<StartupSessionError>();

  const isRoutingByLoginByParameter =
    currentClientRoutingModel.clientRoutingAction === ClientRoutingAction.LoginByParameter;
  const [showLoginByParameter, setShowLoginByParameter] = useState(isRoutingByLoginByParameter);
  const requestResumeSession = isRoutingByLoginByParameter
    ? currentClientRoutingModel.queryStringParamObject
    : currentSessionContextService.getRequestResumeSession();

  const [showSwapSession, setShowSwapSession] = useState(
    hasClientSessions &&
      currentClientRoutingModel.clientRoutingAction === ClientRoutingAction.SessionSelection &&
      !showApplicationOpenAnotherTab,
  );

  //#region Startup

  const handleRouteByCurrentRoutingModel = () => {
    switch (currentClientRoutingModel.clientRoutingAction) {
      case ClientRoutingAction.CreateSession:
        return createSessionWithParams(currentClientRoutingModel.queryStringParamObject);
      case ClientRoutingAction.ContinueSession:
        return continueSessionWithCode(currentClientRoutingModel.queryStringParamObject?.continue);
      case ClientRoutingAction.SessionSelection:
        handleShowSwapSessions(true);
        return undefined;
      case ClientRoutingAction.ResumeClientSession:
        return resumeClientSessionBySessionUid(currentClientRoutingModel.targetSessionUid);
      case ClientRoutingAction.Unknown:
        return Promise.reject(new StartupSessionError(GLOBAL_ERROR_MESSAGE.START_SESSION_FAILURE));
      default:
        return undefined;
    }
  };

  const handleRouteByCurrentRoutingModelSuccess = (data: any) => {
    if (data) {
      setSession(data);

      if (data?.payload != null) {
        if (data.navigationAction === PhaseNavigationAction.PAGE_REDIRECT) {
          //the result of this type will be a string
          window.location.href = data.payload as unknown as string;
        }
      }

      if (!isTrackingInitialized) {
        initializeUserTracking(data?.sessionUid, data?.sessionInfo?.affiliateId);
        setIsTrackingInitialized(true);
      }

      setStartupSessionError(undefined);
    }
  };

  const handleRouteByCurrentRoutingModelError = (err: any) => {
    if (err instanceof StartupSessionError) {
      setStartupSessionError(err);
    }

    if (
      currentSessionContextService.currentEmail &&
      userClientContextService.isLastSessionCreationContextReferral() === false
    ) {
      setShowLoginByParameter(true);
    }
  };

  // Key used when needed to retrieve data from react-query pipeline
  const queryKey = currentClientRoutingModel.clientRoutingAction;

  const { isFetching: isFetchingSession, refetch: refetchSession } = useQuery<Session | undefined>(
    queryKey,
    handleRouteByCurrentRoutingModel,
    {
      enabled: isAuthenticated && !showSwapSession && !showLoginByParameter,
      staleTime: Infinity,
      retry: maximumRetries,
      onSuccess: handleRouteByCurrentRoutingModelSuccess,
      onError: handleRouteByCurrentRoutingModelError,
    },
  );

  //#endregion

  const {
    data: userClientSessions = [],
    isFetching: isFetchingUserClientSessions,
    refetch: refetchUserClientSessions,
  } = useQuery<UserClientSession[]>(SessionQueries.GET_USER_CLIENT_SESSIONS, getUserClientSessions, {
    enabled:
      isAuthenticated &&
      hasClientSessions &&
      showSwapSession &&
      !showApplicationOpenAnotherTab &&
      !showLoginByParameter,
    staleTime: Infinity,
  });

  const updateSession = (res: PhaseResponse) => {
    setSession((currentSession) => {
      if (currentSession != null) {
        if (res.sessionInfo != null) {
          currentSession = {
            ...currentSession,
            sessionToken: res.sessionToken,
            sessionInfo: res.sessionInfo,
            payload: res.payload,
          };
        } else {
          currentSession = { ...currentSession, sessionToken: res.sessionToken, payload: res.payload };
        }
      } else if (
        res.sessionInfo != null &&
        res.sessionToken != null &&
        res.sessionUid != null &&
        res.onDemandConfig != null
      ) {
        currentSession = {
          navigationAction: res.navigationAction,
          sessionUid: res.sessionUid,
          sessionToken: res.sessionToken,
          payload: res.payload,
          sessionInfo: res.sessionInfo,
          onDemandConfig: res.onDemandConfig,
        };
      }

      currentSessionContextService.currentSession = currentSession;

      queryClient.setQueriesData(queryKey, currentSession);

      return currentSession;
    });
  };

  const setLoadedPhase = useCallback(
    (payload: PhaseTypes | undefined) => {
      if (payload && session) {
        setSession({ ...session, payload });
      }
    },
    [session],
  );

  //#region Handlers

  const handleShowSwapSessions = async (attemptCreationIfNoSessions?: boolean | undefined) => {
    await refetchUserClientSessions();

    if (userClientSessions.length > 0 || !attemptCreationIfNoSessions) {
      //if we have sessions and don't want to create one as a fallback, show the screen
      setShowSwapSession(true);
      tracker.trackEvent(null, currentSessionContextService.currentSession?.payload, PhaseEvent.SESSION_LIST_SHOWN);
    } else {
      //make sure the screen is hidden, and create a new session
      setShowSwapSession(false);
      handleCreateNewApplication();
    }
  };

  const handleHideSwapSessions = () => {
    setShowSwapSession(false);
  };

  const handleSessionAndPhase = (res: PhaseResponse, phaseHistoryLength: number) => {
    updateSession(res);

    if (res.navigationAction === PhaseNavigationAction.PAGE_REDIRECT) {
      //the result of this type will be a string
      window.location.href = res.payload as unknown as string;
    } else {
      if (res.payload.phaseType === PhaseType.STATUS) {
        //when phase history is greater than 0, the user hasn't finished the session, so submit a final event here
        if (phaseHistoryLength > 0) {
          tracker.trackEvent(PhaseType.STATUS, res.payload, PhaseEvent.FINISH, {});
        }
      }
      setLoadedPhase(res.payload);
    }
  };

  const handleContinueCurrentTab = async () => {
    const shouldRefetch = showLoginByParameter && !showApplicationOpenAnotherTab;

    if (shouldRefetch) {
      setShowLoginByParameter(false);
    }

    if (showApplicationOpenAnotherTab) {
      setShowApplicationOpenAnotherTab(false);
    }

    if (shouldRefetch || showApplicationOpenAnotherTab) {
      //don't refresh if already showing swap sessions
      if (!showSwapSession) {
        //updating the currentClientRoutingModel will perform the routing
        currentClientRoutingModel.clientRoutingAction = ClientRoutingAction.ResumeClientSession;
        currentClientRoutingModel.targetSessionUid = currentSessionContextService.currentSession?.sessionUid;
      } else {
        refetchUserClientSessions();
      }
    }
  };

  const handleCreateNewApplication = async (queryStringParamObjectCustom?: Object) => {
    setIsFetchingFromUserAction(true);

    //updating the currentClientRoutingModel will perform the routing, so don't create a duplicate session
    currentClientRoutingModel.clientRoutingAction = ClientRoutingAction.CreateSession;
    currentClientRoutingModel.queryStringParamObject =
      queryStringParamObjectCustom ?? currentSessionContextService.getNewSessionCreationParams();

    handleHideSwapSessions();
    setIsFetchingFromUserAction(false);
    setShowLoginByParameter(false);

    tracker.trackEvent(
      null,
      currentSessionContextService.currentSession?.payload,
      PhaseEvent.SESSION_LIST_SESSION_CREATED,
    );
  };

  const handleClose = async () => {
    if (!hasClientSessions) {
      handleContinueCurrentTab();
    } else {
      setShowApplicationOpenAnotherTab(false);
    }
  };

  const handleViewAllApplications = () => {
    setShowApplicationOpenAnotherTab(false);
    handleShowSwapSessions();
  };

  const handleUserClientSessionSelected = async (sessionUid: string) => {
    setIsFetchingFromUserAction(true);

    //updating the currentClientRoutingModel will perform the routing
    currentClientRoutingModel.clientRoutingAction = ClientRoutingAction.ResumeClientSession;
    currentClientRoutingModel.targetSessionUid = sessionUid;

    refetchSession();
    handleHideSwapSessions();
    setIsFetchingFromUserAction(false);
    setShowLoginByParameter(false);

    tracker.trackEvent(
      null,
      currentSessionContextService.currentSession?.payload,
      PhaseEvent.SESSION_LIST_SESSION_RESUMED,
    );
  };

  const handleUserClientSessionResume = async (result: StartSessionResponse) => {
    updateSession(result);
    updateApplicationContexts(result);

    setShowLoginByParameter(false);
    setStartupSessionError(undefined);
  };

  const handleLoginStartNewApplication = () => {
    if (startupSessionError?.message) {
      handleRetry();
    } else {
      handleCreateNewApplication();
    }
  };

  const handleRetry = async () => {
    const redirectToPreviousState = [
      GLOBAL_ERROR_MESSAGE.RESUME_CLIENT_SESSION_FAILURE,
      GLOBAL_ERROR_MESSAGE.START_SESSION_FAILURE,
    ].some((message) => message === startupSessionError?.message);

    if (redirectToPreviousState) {
      setStartupSessionError(undefined);

      if (
        currentClientRoutingModel.queryStringParamObject ||
        userClientContextService.isLastSessionCreationContextReferral()
      ) {
        const lastCreationContext = userClientContextService.getLastSessionCreationContext();

        await handleCreateNewApplication(getQueryParams(lastCreationContext?.queryStringParams));

        refetchSession();
      } else {
        window.location.reload();
      }
    }
  };

  //#endregion

  const { broadcast, broadcastSenderId } = useChannel<any>({
    channelName: 'OnDemand-ActiveTab',
    messageHandler: (message: PhaseResponse) => {
      if (!isError && broadcastSenderId !== message.broadcastSenderId) {
        setShowApplicationOpenAnotherTab(true);
      }
    },
  });

  useEffect(() => {
    if (!isError && isTabActive && !showApplicationOpenAnotherTab) {
      broadcast({ broadcastSenderId });
    }
  }, [broadcast, broadcastSenderId, isError, isTabActive, showApplicationOpenAnotherTab]);

  const contextValue: SessionContextValue = {
    session,
    showApplicationOpenAnotherTab,
    phaseHistory,
    isFetchingSession: isFetchingFromUserAction || isFetchingSession,
    isFetchingUserClientSessions,
    startupSessionError,
    setShowApplicationOpenAnotherTab,
    setLoadedPhase,
    handleSessionAndPhase,
    setPhaseHistory,
    handleRetry,
  };

  return (
    <SessionContext.Provider value={contextValue}>
      {showApplicationOpenAnotherTab && (
        <ApplicationOpenedModal
          isFetching={isFetchingSession}
          isOpen={showApplicationOpenAnotherTab}
          showViewAllApplications={hasClientSessions}
          onContinue={handleContinueCurrentTab}
          onClose={handleClose}
          onViewAllApplications={handleViewAllApplications}
        />
      )}

      {showSwapSession && userClientSessions.length > 0 ? (
        <SwapSession
          userClientSessions={userClientSessions}
          onNewApplicationContinue={handleCreateNewApplication}
          onUserClientSessionSelected={handleUserClientSessionSelected}
        />
      ) : showLoginByParameter ? (
        <Return
          requestResumeSession={requestResumeSession}
          onReturn={handleUserClientSessionResume}
          showStartNewApplication={userClientContextService.hasSessionCreationContext()}
          onStartNewApplication={handleLoginStartNewApplication}
        />
      ) : (
        children
      )}
    </SessionContext.Provider>
  );
};

export const useSession = (): SessionContextValue => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used within an SessionProvider');
  }

  return context;
};
