import React from 'react';

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { Information } from '../..';
import CustomDivider from '../../../../../themes/customDivider';
import { HeartlandErrorText } from '../../../../../themes/overrides';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { FieldValue, FormFieldOption, QuestionProps } from '../../../../../types/form';
import './index.css';
import { formHelperService } from '../../../services';

interface Props extends QuestionProps {
  label: string;
  options: Array<FormFieldOption>;
  value: FieldValue;
}

const KVPButton: React.FC<Props> = ({
  fieldDefinitionJson,
  label,
  onChange,
  options,
  value,
  infoHtml,
  fieldType,
  fieldSubType,
  hideLabel = false,
}) => {
  const multiSelect = fieldDefinitionJson?.MultiSelect;
  const fieldId = fieldDefinitionJson?.Properties[0]?.PropertyKey;
  const theme = useTheme<OneXThemePalette>();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const gridColCount = !isMobile ? (options.length > 4 ? 2 : 1) : 1;

  const handleButtonClick = (option: FormFieldOption) => {
    if (multiSelect) {
      let existingValues: string[] = value.value;
      if (isEmpty(existingValues[0])) existingValues = [];

      let newSelections: string[] = [...existingValues, option.value];

      if (value.value.find((o: string) => o === option.value)) {
        newSelections = newSelections.filter((selection: string) => selection !== option.value);
      }

      if (fieldDefinitionJson?.Link && newSelections.includes(option.value)) {
        const linkValue = fieldDefinitionJson?.Link.Value;
        if (option.value === linkValue) {
          newSelections = [...newSelections, ...fieldDefinitionJson?.Link.Values];
        }
      }

      onChange(value.questionId, newSelections, true, '');
    }

    if (!multiSelect) onChange(value.questionId, option.value, true, '');
  };

  const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const option = options.find((o: FormFieldOption) => o.value === value);

    if (option != null) handleButtonClick(option);
  };

  const getRadioGroup = () => {
    return (
      <RadioGroup name="kvp-radio-group" sx={{ width: '100%' }} value={value.value} onChange={handleRadioGroupChange}>
        <Grid container columns={gridColCount} data-testid={'radioGridContainer_' + gridColCount}>
          {options
            .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
            .map((option: FormFieldOption, i) => {
              let selected = option.value === value.value;
              let optionLabel = option.label.length > 75 ? option.label.substring(0, 75) + '...' : option.label;
              return (
                <Grid item key={option.value + i} xs={1} data-testid="radioGridItem">
                  <Box className="kvpInputContainer">
                    <FormControlLabel
                      sx={{
                        '&.MuiFormControlLabel-root': {
                          marginRight: 1,
                        },
                        '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                          color: selected ? theme.palette.primary.main : theme.palette.text.primary,
                          fontWeight: 500,
                          marginLeft: 0,
                          fontSize: option.label.length > 32 ? 14 : 16,
                        },
                      }}
                      className="kvpInputLabel"
                      value={option.value}
                      labelPlacement="start"
                      control={
                        <Radio
                          name={option.label}
                          inputProps={{
                            ...formHelperService.getCustomInputProps(
                              fieldType,
                              fieldDefinitionJson?.Properties[0].PropertyKey,
                              option.label,
                              fieldSubType,
                            ),
                          }}
                        />
                      }
                      label={
                        hideLabel ? null : (
                          <>
                            {optionLabel}
                            {option.infoHtml && <Information infoHtml={option.infoHtml} />}
                          </>
                        )
                      }
                    />
                    {((i !== options.length - 1 && gridColCount === 1) ||
                      (gridColCount > 1 && i < options.length - 2)) && (
                      <CustomDivider
                        sx={{ margin: gridColCount > 1 ? '0 0.3rem' : '0', width: gridColCount > 1 ? 'auto' : '100%' }}
                      />
                    )}
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </RadioGroup>
    );
  };

  const getCheckboxGroup = () => {
    return (
      <FormGroup>
        <Grid container columns={gridColCount} data-testid={'checkboxGridContainer_' + gridColCount}>
          {options
            .sort((x, y) => (x.ordinal < y.ordinal ? -1 : x.ordinal > y.ordinal ? 1 : 0))
            .map((option: FormFieldOption, i) => {
              let checked = value.value.includes(option.value);
              let optionLabel = option.label.length > 75 ? option.label.substring(0, 75) + '...' : option.label;
              return (
                <Grid item key={option.value + i} xs={1} data-testid="checkboxGridItem">
                  <Box className="kvpInputContainer">
                    <FormControlLabel
                      sx={{
                        '&.MuiFormControlLabel-root': {
                          marginRight: 1,
                        },
                        '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                          color: checked ? theme.palette.primary.main : theme.palette.text.primary,
                          fontWeight: 500,
                          fontSize: option.label.length > 32 ? 14 : 16,
                        },
                      }}
                      className="kvpInputLabel"
                      control={
                        <Checkbox
                          name={option.label}
                          onChange={() => handleButtonClick(option)}
                          checked={checked}
                          inputProps={{
                            ...formHelperService.getCustomInputProps(
                              fieldType,
                              fieldDefinitionJson?.Properties[0].PropertyKey,
                              option.label,
                              fieldSubType,
                            ),
                          }}></Checkbox>
                      }
                      label={
                        <>
                          {optionLabel}
                          {option.infoHtml && <Information infoHtml={option.infoHtml} />}
                        </>
                      }
                      labelPlacement="start"
                    />
                    {((i !== options.length - 1 && gridColCount === 1) ||
                      (gridColCount > 1 && i < options.length - 2)) && (
                      <CustomDivider
                        sx={{ margin: gridColCount > 1 ? '0 0.3rem' : '0', width: gridColCount > 1 ? 'auto' : '100%' }}
                      />
                    )}
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </FormGroup>
    );
  };

  return (
    <Box className="KvpContainer" id={fieldId}>
      {!isEmpty(label) && !hideLabel && (
        <>
          <Box data-testid="kvp-label" className="kvpButtonLabel">
            {label}
            {infoHtml && <Information infoHtml={infoHtml} />}
          </Box>
          <CustomDivider sx={{ width: '100%' }} />
        </>
      )}
      <Box className={gridColCount > 1 ? 'kvpButtonListMulti' : 'kvpButtonList'}>
        {multiSelect ? getCheckboxGroup() : getRadioGroup()}
        {value.validationErrorText && <HeartlandErrorText>{value.validationErrorText}</HeartlandErrorText>}
      </Box>
    </Box>
  );
};

export { KVPButton };
