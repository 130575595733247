import { Box, Link, Typography } from '@mui/material';

import { UserClientSessionStatus } from '../..';

import style from './index.module.css';

type BaseHeaderProps = {
  onNewApplicationContinue: () => void;
};

type HeaderProps = {
  userClientSessionsStatuses: UserClientSessionStatus[];
} & BaseHeaderProps;

export function Header({ userClientSessionsStatuses, onNewApplicationContinue }: HeaderProps) {
  if (userClientSessionsStatuses?.length > 0) {
    if (userClientSessionsStatuses?.every((status) => status === UserClientSessionStatus.COMPLETED) === true)
      return <AllSessionsCompletedHeader onNewApplicationContinue={onNewApplicationContinue} />;
    else if (userClientSessionsStatuses?.every((status) => status !== UserClientSessionStatus.COMPLETED) === true)
      return <NoCompletedSessionHeader onNewApplicationContinue={onNewApplicationContinue} />;

    return <MixedSessionsHeader onNewApplicationContinue={onNewApplicationContinue} />;
  }

  return <Box>No existing applications found.</Box>;
}

function AllSessionsCompletedHeader({ onNewApplicationContinue }: BaseHeaderProps) {
  return (
    <Box className={style.header}>
      <Typography variant="h5" fontWeight="bold">
        You have completed applications on file
      </Typography>
      <Typography variant="body2" component="p">
        Select one of your completed sessions below to review your application details.
      </Typography>
      <Typography variant="body2" component="p" data-testid="ready-to-start-verbiage">
        Ready to start another? Feel free to&nbsp;
        <Link onClick={onNewApplicationContinue} color="primary.main">
          open a new application
        </Link>
        &nbsp;instead.
      </Typography>
    </Box>
  );
}

function NoCompletedSessionHeader({ onNewApplicationContinue }: BaseHeaderProps) {
  return (
    <Box className={style.header}>
      <Typography variant="h5" fontWeight="bold">
        Take a look at your existing applications
      </Typography>
      <Typography variant="body2" component="p">
        You can select one of your saved sessions below to pick up where you left off.
      </Typography>
      <Typography variant="body2" component="p" data-testid="ready-to-start-verbiage">
        Want to start fresh? Feel free to&nbsp;
        <Link onClick={onNewApplicationContinue} color="primary.main">
          open a new application
        </Link>
        &nbsp;instead.
      </Typography>
    </Box>
  );
}

function MixedSessionsHeader({ onNewApplicationContinue }: BaseHeaderProps) {
  return (
    <Box className={style.header}>
      <Typography variant="h5" fontWeight="bold">
        Looks like you have existing applications
      </Typography>
      <Typography variant="body2" component="p">
        You can select an active session below to continue one of your open applications or review any of your completed
        sessions.
      </Typography>
      <Typography variant="body2" component="p" data-testid="ready-to-start-verbiage">
        Want to start fresh? Feel free to&nbsp;
        <Link onClick={onNewApplicationContinue} color="primary.main">
          open a new application
        </Link>
        &nbsp;instead.
      </Typography>
    </Box>
  );
}
