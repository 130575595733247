import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';

import { currentSessionContextService } from './sessionContext';

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    let ref = domRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(ref);
  }, []);
  return (
    <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
      {props.children}
    </div>
  );
}
export default FadeInSection;

export function ScrollInSection(props) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    let ref = domRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        } else setVisible(false);
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(ref);
  }, []);

  return (
    <div className={`scroll-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
      {props.children}
    </div>
  );
}

export function useIsInView(ref, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = React.useState(true);

  React.useEffect(() => {
    let refer = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin },
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      try {
        if (refer) observer.unobserve(refer);
      } catch (err) {
        console.log(err);
        console.log('problem unobserving ref');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}

export function isIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function useIsFrame() {
  const [isFrame, setIsFrame] = useState(currentSessionContextService.isFrame);

  useEffect(() => {
    try {
      const isIFrame = window.self !== window.top;
      setIsFrame(isIFrame);

      if (isIFrame !== currentSessionContextService.isFrame) {
        currentSessionContextService.isFrame = isFrame;
      }

      if (isIFrame && !document.getElementById('oneTrustStyle')) {
        let style = document.createElement('style');
        style.setAttribute('id', 'oneTrustStyle');
        style.innerHTML = `
          #onetrust-consent-sdk {
            display: none !important;
          }
          `;
        document.head.appendChild(style);
      }
    } catch (e) {
      return setIsFrame(true);
    }
  }, [isFrame]);
  return [isFrame];
}

export const DeviceIsMobile = () => {
  const isMobile = useMediaQuery('(max-width: 992px)');
  return isMobile;
};

export const getOnDemandCustomElementProps = (key, label) => {
  const prefix = 'data-od';
  const elementProps = {
    [`${prefix}-key`]: key,
  };

  if (label) {
    elementProps[`${prefix}-label`] = label;
  }

  return elementProps;
};

export const screenUtils = {
  useIsFrame,
  DeviceIsMobile,
  useIsInView,
  FadeInSection,
  ScrollInSection,
  isIFrame,
  getOnDemandCustomElementProps,
};
