export enum GLOBAL_ERROR_MESSAGE {
  ERROR = 'ERROR',
  CHANNEL = 'CHANNEL',
  LEAD = 'LEAD',
  UNAUTHORIZED = 'UNAUTHORIZED',
  START_SESSION_FAILURE = 'Start Session Failure',
  CONTINUE_SESSION_FAILURE = 'Continue Session Failure',
  RESUME_CLIENT_SESSION_FAILURE = 'Resume Client Session Failure',
  CLIENT_API_CONNECTION_FAILURE = 'Client Api Connection Failure',
}
