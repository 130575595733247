import { useEffect, useRef, useState } from 'react';

import Check from '@mui/icons-material/Check';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, useMediaQuery } from '@mui/material';

import { getJsonReferenceData } from '../../../../../api/form';
import { FormFieldTypes } from '../../../../../types/enums/FormFieldTypes';
import { MatrixDocument, MatrixRow } from '../../../../../types/form';
import Modal from '../../../../../utility/modal';
import { screenUtils } from '../../../../../utility/screenUtils';

import styles from './index.module.css';

export const checkValue = 'check';
export const matrixScrollCap = 15;
export const dialogClassMobile = '.MuiDialog-paperScrollBody';
export const dialogClassDesktop = '.MuiDialog-container';
export const fixedRow = 'fixedRow';
interface Props {
  fieldDefinitionJson: any;
  label: string;
  fieldType: FormFieldTypes;
  hideLabel?: boolean;
}

const Matrix: React.FC<Props> = ({ fieldDefinitionJson, label, fieldType, hideLabel = false }) => {
  const isMobile = useMediaQuery('(max-width:992px)');
  const [matrix, setMatrix] = useState<MatrixDocument | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let subscribed = true;

    const getJson = async () => {
      await getJsonReferenceData(fieldDefinitionJson.DataSource)
        .then((res) => {
          if (subscribed) setMatrix(JSON.parse(res.document));
        })
        .catch(() => {
          if (subscribed) setError(true);
        });
    };

    if (matrix == null && subscribed) {
      getJson();
    }

    return () => {
      subscribed = false;
    };
  }, [fieldDefinitionJson.DataSource, matrix]);

  const modalContents = error ? (
    <Box className={styles.matrixContainer} sx={{ alignItems: 'center' }}>
      Unable to load.
    </Box>
  ) : matrix == null ? null : isMobile ? (
    <MatrixMobile matrix={matrix} />
  ) : (
    <MatrixDesktop matrix={matrix} />
  );

  return (
    <Box>
      <Box
        color="primary.main"
        className={styles.matrixButton}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setIsModalOpen(true);
        }}
        onClick={() => setIsModalOpen(true)}>
        <Box className={styles.matrixAnimateLink}>
          {label} <KeyboardArrowDown color="primary" />
        </Box>
      </Box>
      <Modal
        fullScreen={isMobile}
        removePadding={isMobile}
        onlyCloseButton
        scroll="body"
        size="lg"
        show={isModalOpen}
        hideTitle
        title=""
        closeModal={() => setIsModalOpen(false)}>
        <Box color="primary.main" className={styles.matrixModalTitle}>
          {label}
        </Box>
        {modalContents}
      </Modal>
    </Box>
  );
};

export const MatrixTable = ({ table }: { table: Array<MatrixRow> }) => {
  return (
    <>
      {table.map((row: MatrixRow, i) => {
        const isSectionTitle = row.Values.length === 0;
        const bgColor = i % 2 === 0 ? 'primary.bgSecondary' : 'transparent';
        return (
          <Box key={row.Title + i} bgcolor={bgColor} className={styles.matrixRow}>
            <Box className={isSectionTitle ? styles.matrixSectionTitle : styles.matrixRowTitle}>{row.Title}</Box>
            <Box className={styles.matrixValues}>
              {row.Values.map((value, j) => {
                return (
                  <Box key={value + j} className={styles.matrixValue}>
                    {value === checkValue ? <Check color="success" className={styles.matrixCheck} /> : value}
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export const MatrixMobile = ({ matrix }: { matrix: MatrixDocument }) => {
  const row = useRef();
  const headerVisible = screenUtils.useIsInView(row, '-55px');

  const handleScrollTop = () => {
    const element = document.querySelector(dialogClassMobile);
    if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <Box className={styles.matrixContainer}>
      <Box className={styles.matrixMobileTitle}>{matrix.Title}</Box>
      <Box className={styles.matrixTable}>
        <Box bgcolor="primary.bgPrimary" className={styles.matrixRow} ref={row} id={fixedRow}>
          <Box className={styles.matrixRowTitle} />
          <Box className={styles.matrixValues}>
            {matrix.Categories.map((category, i) => {
              return (
                <Box key={category + i} className={styles.matrixValue + ' ' + styles.matrixBoldTitle}>
                  {category}
                </Box>
              );
            })}
          </Box>
        </Box>
        {!headerVisible && (
          <Box bgcolor="primary.bgPrimary" className={styles.matrixRowFixed + ' fade-in'} id={fixedRow}>
            <Box className={styles.matrixRowTitle} />
            <Box className={styles.matrixValues}>
              {matrix.Categories.map((category, i) => {
                return (
                  <Box key={category + i} className={styles.matrixValue + ' ' + styles.matrixBoldTitle}>
                    {category}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        <MatrixTable table={matrix.Table} />
        {matrix.Disclaimer && <Box className={styles.matrixDisclaimer}>{matrix.Disclaimer}</Box>}
        {matrix.Table.length > matrixScrollCap && (
          <Button className={styles.matrixScrollButton} variant="outlined" color="primary" onClick={handleScrollTop}>
            Back to top
          </Button>
        )}
      </Box>
    </Box>
  );
};

export const MatrixDesktop = ({ matrix }: { matrix: MatrixDocument }) => {
  const row = useRef();
  const headerVisible = screenUtils.useIsInView(row, '-50px');

  const handleScrollTop = () => {
    const element = document.querySelector(dialogClassDesktop);
    if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <Box className={styles.matrixContainer}>
      <Box bgcolor="primary.bgPrimary" ref={row} className={styles.matrixHeaderRow}>
        <Box className={styles.matrixTitle}>{matrix.Title}</Box>
        <Box className={styles.matrixValues}>
          {matrix.Categories.map((category, i) => {
            return (
              <Box key={category + i} className={styles.matrixHeaderValue + ' ' + styles.matrixBoldTitle}>
                {category}
              </Box>
            );
          })}
        </Box>
      </Box>
      {!headerVisible && (
        <Box bgcolor="primary.bgPrimary" className={styles.matrixRowFixed + ' fade-in'}>
          <Box className={styles.matrixTitle}></Box>
          <Box className={styles.matrixValues}>
            {matrix.Categories.map((category, i) => {
              return (
                <Box key={category + i} className={styles.matrixHeaderValue + ' ' + styles.matrixBoldTitle}>
                  {category}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box className={styles.matrixTable}>
        <MatrixTable table={matrix.Table} />
        {matrix.Disclaimer && <Box className={styles.matrixDisclaimer}>{matrix.Disclaimer}</Box>}
        {matrix.Table.length > matrixScrollCap && (
          <Button className={styles.matrixScrollButton} variant="outlined" color="primary" onClick={handleScrollTop}>
            Back to top
          </Button>
        )}
      </Box>
    </Box>
  );
};

export { Matrix };
