import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Dialog } from '@mui/material';
import { isEmpty } from 'lodash';

import './index.css';

export const navHash = '#pg-bfer';

const BackButtonHandler = ({ location, setLocation = () => {}, unloadWarning = false, allowRewind = false }) => {
  const [showWarning, setShowWarning] = useState(false);
  const onBeforeUnload = useCallback(
    (e) => {
      if (unloadWarning) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [unloadWarning],
  );

  //Browser Warning for Potential Data Loss
  useEffect(() => {
    window.onbeforeunload = (e) => onBeforeUnload(e);
    return () => {
      window.onbeforeunload = function () {};
    };
  }, [onBeforeUnload]);

  const handleShowWarning = () => {
    setShowWarning(true);
    window.onbeforeunload = function () {};
  };

  useEffect(() => {
    function addHash() {
      window.location.assign(navHash);
    }

    window.addEventListener('mousedown', addHash);
    window.addEventListener('touch', addHash);

    return () => {
      window.removeEventListener('mousedown', addHash);
      window.removeEventListener('touch', addHash);
    };
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash !== navHash && isEmpty(window.location.hash)) {
        if (location === 0 && window.history.length >= 2 && !allowRewind) {
          handleShowWarning();
        } else {
          window.location.assign(navHash);

          if (setLocation) {
            setLocation(location - 1 < 0 ? 0 : location - 1);
          }
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [allowRewind, location, setLocation]);

  const handleClose = () => {
    window.onbeforeunload = (e) => onBeforeUnload(e);
    setShowWarning(false);
  };

  if (showWarning)
    return (
      <Dialog open={true} onClose={handleClose}>
        <Box className="exit-container">
          <span>
            <b>
              You are about to leave this Heartland application.
              <br /> If you go back you will lose data.
            </b>
          </span>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Resume Process
          </Button>
        </Box>
      </Dialog>
    );
  else return null;
};

export default BackButtonHandler;
