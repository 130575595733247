import LockIcon from '@mui/icons-material/Lock';
import { Box, FormControl, TextField, useTheme } from '@mui/material';

import { FormFieldTypes } from '../../../../../types/enums/FormFieldTypes';
import { OneXThemePalette } from '../../../../../types/enums/theme';
import { formHelperService } from '../../../services';

interface Props {
  value: { value: string };
  title: string;
  label: string;
  fieldType: FormFieldTypes;
  propertyKey: string;
  hideLabel?: boolean;
}

const DisabledField: React.FC<Props> = ({ value, title, label, fieldType, propertyKey, hideLabel = false }) => {
  const theme = useTheme<OneXThemePalette>();
  return (
    <FormControl fullWidth>
      <TextField
        disabled
        InputLabelProps={{
          shrink: true,
          sx: { color: theme.palette.text.primary + ' !important' },
        }}
        value={value}
        variant="filled"
        label={hideLabel ? null : label}
        title={title}
        InputProps={{
          endAdornment: <LockIcon />,
        }}
        sx={{
          '& .Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.secondary + ' !important',
            cursor: 'not-allowed',
          },
          '& .Mui-disabled:before': {
            borderBottomStyle: 'none !important',
          },
          '& .MuiFilledInput-root:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            border: `1px solid ${theme.palette.primary.inputBorder}`,
          },
        }}
        inputProps={{ ...formHelperService.getCustomInputProps(fieldType, propertyKey, label) }}
      />
    </FormControl>
  );
};

export { DisabledField };
