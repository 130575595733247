import { SessionLocation } from '../../features/locations/types/SessionLocation';
import { Attribution } from '../attribution';
import { Business } from '../enums/appState';
import { LeadType } from '../enums/LeadType';
import { PhaseNavigationAction, PhaseTypes } from '../phase';

export enum ProgressSegmentStatus {
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
}

export type ProgressSegment = {
  name: string;
  status: ProgressSegmentStatus;
};

export interface SegmentProps {
  segment: ProgressSegment;
}

export type Session = {
  sessionUid: string;
  onDemandConfig: OnDemandConfig;
  payload: PhaseTypes;
  sessionToken: string;
  sessionInfo: BasicSessionInfoModel;
  navigationAction: PhaseNavigationAction;
};

export type OnDemandConfig = {
  id: number;
  configName: string;
  variantCodeId: number;
  showPageHeader: boolean;
  headerTitle: string;
  logoUrl: string | null;
  autofillAvailable: boolean;
  allowResume: boolean;
  customHeaderUrl: string | null;
  customBodyUrl: string | null;
  customFooterUrl: string | null;
  customHeaderTag?: string;
  customFooterTag?: string;
  allowManualOptOut?: boolean;
  gpBusiness?: Business;
  sessionsCreatedByPartner?: boolean;
};

export type BasicSessionInfoModel = {
  sessionUid: string;
  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
  sessionLeads: Array<SessionLeadModel>;
  builtWithTechnologies: string | null;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  stateAbbr: string | null;
  zip: string | null;
  partnerLogo: string | null;
  sessionExpirationUTC: Date;
  cardProcessingRate: number;
  cardProcessingPerTransactionCharge: number;
  aaid: string | null;
  affiliateId: string | null;
  partner: string | null;
  leadSource: string | null;
  channel: string | null;
  subChannel: string | null;
  previousChannel: string | null;
  previousSubChannel: string | null;
  isEmailVerified: boolean;
  sessionProgress: Array<ProgressSegment>;
  creationParams: string | null;
  sessionLocations?: Array<SessionLocation> | null;
};

export type SessionLeadModel = {
  leadType: LeadType;
  leadIdentifier: string | null;
  createdUTC: string | null;
};

export type AddressInfo = {
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  stateAbbr: string | null;
  zip: string | null;
  name: string | null;
};
export type SessionAddress = {
  dba: AddressInfo | null;
  legal: AddressInfo | null;
  contact: AddressInfo | null;
};

export type ConvertSessionResponse = {
  continueUrl: string;
};

export type PhaseSubmissionResponse = Session & {};

export type StartSessionResponse = Session & {
  attributions: Array<Attribution>;
};

export type SessionContextStackModel = {
  sessionUid: string;
  queryStringParams: string | null;
};

export type SessionCreationStackModel = {
  queryStringParams: string;
  isReferral: boolean | null;
};

export enum ResumeSessionScope {
  AAID = 'AAID',
  CONFIG = 'Config',
  SOURCE = 'Source',
  SUBCHANNEL = 'SubChannel',
  CHANNEL = 'Channel',
  TARGET_SESSION = 'TargetSession',
}

export const ShippingUserPropertyKeys = {
  ShippingStreetAddress: 'ShippingStreetAddress',
  ShippingCity: 'ShippingCity',
  ShippingState: 'ShippingState',
  ShippingZip: 'ShippingZip',
};

export type ClientRoutingModel = {
  clientRoutingAction: ClientRoutingAction;
  targetSessionUid: string | undefined;
  queryStringParamObject: any | undefined;
};

export type ClientRoutingFlags = {
  showSessions: boolean | undefined;
};

export enum ClientRoutingAction {
  Unknown = 'Unknown',
  LoginByParameter = 'LoginByParameter',
  ContinueSession = 'ContinueSession',
  SessionSelection = 'SessionSelection',
  CreateSession = 'CreateSession',
  ResumeClientSession = 'ResumeClientSession',
}
