import React, { useLayoutEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, useTheme } from '@mui/material';

import { OneXThemePalette } from '../../../types/enums/theme';
import { ProgressSegmentStatus, SegmentProps } from '../../../types/session';

import styles from './index.module.css';

interface BubbleSegmentProps extends SegmentProps {
  number: number;
  max: number;
}

const bubbleSuffix = 'bubble';

const BubbleTrackerSegment: React.FC<BubbleSegmentProps> = ({ segment, number, max }) => {
  const theme = useTheme<OneXThemePalette>();

  const shouldHideStart = number === 1;
  const shouldHideEnd = number === max;

  useLayoutEffect(() => {
    if (segment.status === ProgressSegmentStatus.IN_PROGRESS) {
      let bubble = document.getElementById(segment.name + bubbleSuffix);
      if (bubble) {
        setTimeout(() => {
          if (bubble?.scrollIntoView) bubble.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
        }, 10);
      }
    }
  }, [segment]);

  return (
    <Box className={styles.segment} id={segment.name + bubbleSuffix}>
      {segment.status === ProgressSegmentStatus.COMPLETED && (
        <>
          <BubbleContainer highlightStart highlightEnd hideStart={shouldHideStart} hideEnd={shouldHideEnd}>
            <Box className={styles.checkIconContainer}>
              <CheckIcon
                sx={{ color: theme.palette.primary.main }}
                data-testid="complete-check"
                fontSize="large"
                className={styles.checkIcon}
              />
            </Box>
          </BubbleContainer>
          <Box className={styles.segmentName} color="text.primary">
            {segment.name}
          </Box>
        </>
      )}
      {segment.status === ProgressSegmentStatus.IN_PROGRESS && (
        <>
          <BubbleContainer highlightStart hideStart={shouldHideStart} hideEnd={shouldHideEnd}>
            <Box component="span" color="primary.contrastText" className={styles.bubbleProgress} bgcolor="primary.main">
              {number}
            </Box>
          </BubbleContainer>
          <Box className={styles.segmentName} color="text.primary">
            <b>{segment.name}</b>
          </Box>
        </>
      )}
      {segment.status === ProgressSegmentStatus.NOT_STARTED && (
        <>
          <BubbleContainer hideStart={shouldHideStart} hideEnd={shouldHideEnd}>
            <Box component="span" className={styles.bubble} color="text.primary" bgcolor="primary.bgActionQuarternary">
              {number}
            </Box>
          </BubbleContainer>
          <Box className={styles.segmentName} color="text.secondary">
            {segment.name}
          </Box>
        </>
      )}
    </Box>
  );
};

export default BubbleTrackerSegment;

export const BubbleContainer = ({
  children,
  hideStart,
  hideEnd,
  highlightStart,
  highlightEnd,
}: {
  children: React.ReactNode;
  hideStart?: boolean;
  hideEnd?: boolean;
  highlightStart?: boolean;
  highlightEnd?: boolean;
}) => {
  return (
    <Box className={styles.bubbleContainer}>
      <Box
        className={styles.bubbleLine}
        data-testid="start-line"
        bgcolor={highlightStart ? 'primary.main' : 'primary.bgActionQuarternary'}
        sx={{ opacity: hideStart ? 0 : 1, marginRight: 1 }}
      />
      {children}
      <Box
        className={styles.bubbleLine}
        data-testid="end-line"
        bgcolor={highlightEnd ? 'primary.main' : 'primary.bgActionQuarternary'}
        sx={{ opacity: hideEnd ? 0 : 1, marginLeft: 1 }}
      />
    </Box>
  );
};
