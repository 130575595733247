import React from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isEmpty } from 'lodash';

import './index.css';
import { formHelperService } from '../../features/form/services';
import { FormFieldSubTypes, FormFieldTypes } from '../../types/enums/FormFieldTypes';
interface Props {
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
  showError: boolean;
  value: any;
  id: string;
  label: string;
  required: boolean;
  fullWidth: boolean;
  helperText?: string;
  customErrorText?: string;
  overrideClass?: string;
  blurChange?: boolean;
  disabled?: boolean;
  showDefaultPlaceholder?: boolean;
  options: Array<{ label: string; value: any }>;
  fieldType: FormFieldTypes;
  fieldSubType?: FormFieldSubTypes;
  propertyKey: string;
}

const NativeDropDown: React.FC<Props> = ({
  id,
  label,
  options,
  value,
  required,
  showError,
  fullWidth,
  helperText,
  customErrorText,
  overrideClass,
  disabled = false,
  showDefaultPlaceholder,
  blurChange = true,
  fieldType,
  propertyKey,
  onChange,
  onBlur,
}) => {
  let labelString = isEmpty(label) ? `Select an option` : `Select ${label}`;
  if (required && label) labelString = labelString + ' *';

  return (
    <FormControl fullWidth={fullWidth}>
      <TextField
        data-testid="nativeDropDown"
        select
        SelectProps={{
          native: true,
          IconComponent: ExpandMore,
        }}
        disabled={disabled}
        label={showDefaultPlaceholder ? '' : label}
        variant="filled"
        error={showError}
        helperText={showError ? (customErrorText ? customErrorText : required ? `${label} is required.` : '') : ''}
        required={required}
        InputLabelProps={{ shrink: true }}
        className={overrideClass ?? ''}
        sx={{
          '& .MuiFilledInput-root.Mui-disabled:before': {
            borderBottom: 'none',
          },
        }}
        inputProps={{
          name: id,
          id: 'uncontrolled-native',
          required: required,
          ...formHelperService.getCustomInputProps(fieldType, propertyKey, label),
        }}
        value={!value ? '' : value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          if (blurChange) onChange(e.target.value);
          if (onBlur) onBlur(e.target.value);
        }}>
        {(!isEmpty(label) || showDefaultPlaceholder) && (
          <option value="" disabled hidden>
            {showDefaultPlaceholder ? `Select an option` : labelString}
          </option>
        )}
        {options.map((item: any, i) => {
          return (
            <option
              key={item.value + i}
              value={item.value}
              {...(formHelperService.getCustomInputProps(fieldType, propertyKey, item.label) as any)}>
              {item.label}
            </option>
          );
        })}
      </TextField>
      {!isEmpty(helperText) && <FormHelperText dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>}
    </FormControl>
  );
};

export default NativeDropDown;
