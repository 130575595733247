export type AddressSearchParam = {
  textValue: string;
};

export type AddressSearchResult = {
  addressSummary: string;
  streetAddress: string;
  city: string;
  stateAbbr: string;
  stateName: string;
  zipCode: string;
};

export type StateOption = {
  label: string;
  value: string;
  questions: Array<any>;
};

export const stateOptions: Array<StateOption> = [
  {
    label: 'Alabama',
    value: 'AL',
    questions: [],
  },
  {
    label: 'Alaska',
    value: 'AK',
    questions: [],
  },
  {
    label: 'Arizona',
    value: 'AZ',
    questions: [],
  },
  {
    label: 'Arkansas',
    value: 'AR',
    questions: [],
  },
  {
    label: 'California',
    value: 'CA',
    questions: [],
  },
  {
    label: 'Colorado',
    value: 'CO',
    questions: [],
  },
  {
    label: 'Connecticut',
    value: 'CT',
    questions: [],
  },
  {
    label: 'Delaware',
    value: 'DE',
    questions: [],
  },
  {
    label: 'District of Columbia',
    value: 'DC',
    questions: [],
  },
  {
    label: 'Florida',
    value: 'FL',
    questions: [],
  },
  {
    label: 'Georgia',
    value: 'GA',
    questions: [],
  },
  {
    label: 'Hawaii',
    value: 'HI',
    questions: [],
  },
  {
    label: 'Idaho',
    value: 'ID',
    questions: [],
  },
  {
    label: 'Illinois',
    value: 'IL',
    questions: [],
  },
  {
    label: 'Indiana',
    value: 'IN',
    questions: [],
  },
  {
    label: 'Iowa',
    value: 'IA',
    questions: [],
  },
  {
    label: 'Kansas',
    value: 'KS',
    questions: [],
  },
  {
    label: 'Kentucky',
    value: 'KY',
    questions: [],
  },
  {
    label: 'Louisiana',
    value: 'LA',
    questions: [],
  },
  {
    label: 'Maine',
    value: 'ME',
    questions: [],
  },
  {
    label: 'Maryland',
    value: 'MD',
    questions: [],
  },
  {
    label: 'Massachusetts',
    value: 'MA',
    questions: [],
  },
  {
    label: 'Michigan',
    value: 'MI',
    questions: [],
  },
  {
    label: 'Minnesota',
    value: 'MN',
    questions: [],
  },
  {
    label: 'Mississippi',
    value: 'MS',
    questions: [],
  },
  {
    label: 'Missouri',
    value: 'MO',
    questions: [],
  },
  {
    label: 'Montana',
    value: 'MT',
    questions: [],
  },
  {
    label: 'Nebraska',
    value: 'NE',
    questions: [],
  },
  {
    label: 'Nevada',
    value: 'NV',
    questions: [],
  },
  {
    label: 'New Hampshire',
    value: 'NH',
    questions: [],
  },
  {
    label: 'New Jersey',
    value: 'NJ',
    questions: [],
  },
  {
    label: 'New Mexico',
    value: 'NM',
    questions: [],
  },
  {
    label: 'New York',
    value: 'NY',
    questions: [],
  },
  {
    label: 'North Carolina',
    value: 'NC',
    questions: [],
  },
  {
    label: 'North Dakota',
    value: 'ND',
    questions: [],
  },
  {
    label: 'Ohio',
    value: 'OH',
    questions: [],
  },
  {
    label: 'Oklahoma',
    value: 'OK',
    questions: [],
  },
  {
    label: 'Oregon',
    value: 'OR',
    questions: [],
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
    questions: [],
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    questions: [],
  },
  {
    label: 'Rhode Island',
    value: 'RI',
    questions: [],
  },
  {
    label: 'South Carolina',
    value: 'SC',
    questions: [],
  },
  {
    label: 'South Dakota',
    value: 'SD',
    questions: [],
  },
  {
    label: 'Tennessee',
    value: 'TN',
    questions: [],
  },
  {
    label: 'Texas',
    value: 'TX',
    questions: [],
  },
  {
    label: 'Utah',
    value: 'UT',
    questions: [],
  },
  {
    label: 'Vermont',
    value: 'VT',
    questions: [],
  },
  {
    label: 'Virginia',
    value: 'VA',
    questions: [],
  },
  {
    label: 'Washington',
    value: 'WA',
    questions: [],
  },
  {
    label: 'West Virginia',
    value: 'WV',
    questions: [],
  },
  {
    label: 'Wisconsin',
    value: 'WI',
    questions: [],
  },
  {
    label: 'Wyoming',
    value: 'WY',
    questions: [],
  },
];