import { Box } from '@mui/material';

import { ResumeSessionScope, Session } from '../../../types/session';
import Modal from '../../../utility/modal';
import { screenUtils } from '../../../utility/screenUtils';
import ResumeVerificationCodeForm from '../../resumeSession/form';

import './index.css';

type VerificationCodeModalProps = {
  resumeScope: ResumeSessionScope;
  closeModal: Function;
  onSuccess: Function;
  show: boolean;
  userSession: Session;
  setSubmitTimeout: Function;
  submitTimeout: boolean;
  timeoutCounter: number;
  setAttempts: Function;
  attempts: number;
  preventAttempt: boolean;
};

const VerificationCodeModal = ({
  closeModal,
  onSuccess,
  show,
  userSession,
  resumeScope,
  setSubmitTimeout,
  submitTimeout,
  timeoutCounter,
  setAttempts,
  attempts,
  preventAttempt,
}: VerificationCodeModalProps) => {
  const isMobile = screenUtils.DeviceIsMobile();
  return (
    <Modal
      show={show}
      title=""
      hideTitle
      onlyCloseButton
      fullScreen={isMobile}
      size="sm"
      closeModal={() => closeModal()}>
      <Box className="verification-code-wrapper" color="text.primary">
        <Box className="verification-code-static-modal">
          {show && (
            <ResumeVerificationCodeForm
              userSession={userSession}
              resumeScope={resumeScope}
              setSubmitTimeout={setSubmitTimeout}
              submitTimeout={submitTimeout}
              timeoutCounter={timeoutCounter}
              setAttempts={setAttempts}
              attempts={attempts}
              preventAttempt={preventAttempt}
              onSuccess={(response) => onSuccess(response)}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default VerificationCodeModal;
