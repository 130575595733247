import { Box, Typography } from '@mui/material';

import style from './index.module.css';

type Props = {
  label: string;
  value: React.ReactNode;
};

export function UserSessionCardItem({ label, value }: Props) {
  return (
    <Box className={style.container}>
      <Typography color="text.secondary">{label}</Typography>
      <Box>
        {typeof value === 'string' ? (
          <Typography color="text.primary" fontWeight="600">
            {value}
          </Typography>
        ) : (
          value
        )}
      </Box>
    </Box>
  );
}
