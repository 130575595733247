import { ReactNode } from 'react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { OnDemandQueryClient } from './OnDemandQueryClient';

type Props = {
  children: ReactNode;
};

const OnDemandQueryClientProvider = ({ children }: Props) => {
  return (
    <QueryClientProvider client={OnDemandQueryClient}>
      {children}

      <ReactQueryDevtools position="bottom-left" initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export { OnDemandQueryClientProvider };
