import React, { useState } from 'react';

import { Box } from '@mui/material';

import { OTHER_MCC } from '../../../../../types/enums/FormFieldTypes';
import { MCC, QuestionProps, ReferenceData } from '../../../../../types/form';

import { MCCDropdown, Other } from '.';

import './index.css';

interface Props extends QuestionProps {
  onChange: (value: any, shouldValidate: boolean) => void;
  validationError: boolean;
  errorText: string;
  PropertyKey: string;
  value: MCC;
  data: Array<ReferenceData>;
}

const MCCSelector: React.FC<Props> = ({
  errorText,
  label,
  onChange,
  PropertyKey,
  validationError,
  value,
  helperText,
  data,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const options: Array<MCC> = data as Array<MCC>;
  const otherOption: MCC = { Code: '0', Name: OTHER_MCC };
  const [otherValue, setOtherValue] = useState<string>(value.Name !== OTHER_MCC ? value.Name : '');

  const updateAnswer = (e: any, shouldValidate = true) => {
    if (e.Code || e.Code === otherOption.Code) {
      setOtherValue('');
      onChange(e, shouldValidate);
    } else {
      onChange({ Code: '0', Name: e }, shouldValidate);
      setOtherValue(e);
    }
  };

  return (
    <Box className="split-row">
      <MCCDropdown
        id={PropertyKey}
        label={label ?? ''}
        icon="faBriefcase"
        helperText={helperText != null ? helperText : ''}
        list={options}
        value={value?.Code === otherOption.Code ? otherOption : value}
        setSelection={(val) => {
          updateAnswer(val);
        }}
        error={value?.Code === otherOption.Code ? false : validationError}
        infoHtml={infoHtml}
        propertyKey={PropertyKey}
        fieldType={fieldType}
        hideLabel={hideLabel}
      />
      {value?.Code === otherOption.Code && (
        <Other
          autoComplete={'on'}
          errorText={errorText}
          validationError={validationError}
          updateAnswer={(value: string, shouldValidate: boolean) => {
            updateAnswer(value, shouldValidate);
          }}
          value={otherValue}
        />
      )}
    </Box>
  );
};

export { MCCSelector };
export * from './MCCDropdown';
export * from './Other';
